import { ErrorEvent } from '@sentry/core'

import { deleteContextPageTitle, getContextPageTitle } from './context'
import { deleteIssueTitleExtra, hasIssueTitleExtra } from './extra'

export function groupWithCustomNamedIssue(event: ErrorEvent): void {
  const { extra } = event

  if (hasIssueTitleExtra(extra)) {
    const pageTitle = extra.issueTitle.options?.withContextPageTitle
      ? ' ' + getContextPageTitle(event.contexts)
      : ''
    const issueCustomName = extra.issueTitle.value + pageTitle

    deleteIssueTitleExtra(extra)

    // create "fake" exception as issue custom name
    event.exception = event.exception || { values: [] }
    event.exception.values?.push({ type: issueCustomName })

    // group issues
    event.fingerprint = [issueCustomName]
  }

  // clean context helping for grouping issues
  deleteContextPageTitle(event.contexts)
}
