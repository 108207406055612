import * as R from 'ramda'
import { computed, Ref, ref, watch } from 'vue'

import { TeamSide } from '@collector/shared-ui-domain'
import { Incident } from '@collector/sportsapi-client'
import {
  EventStatusId,
  IncidentId,
  SportIncident,
} from '@collector/sportsapi-client-legacy'
import {
  useEvent,
  useIncidents,
  useParticipants,
  useSportMaps,
} from '@desktop/views/Relation/composables'

import {
  getIncidentIdToIdsMap,
  getIncidentToSend,
  getTeamSidePossession,
  hasDeletedIncidentId,
} from './utils'

type UseIncidentToSend = {
  incidentToSend: Ref<SportIncident | null>
  getTeamSidePossession(
    incidentId: Incident['incident_id'],
    participantTeamId: Incident['participant_team_id'] | undefined,
    kickOffIncidentId: IncidentId,
    secondHalfIncidentId: IncidentId,
  ): TeamSide | null
}

export function useIncidentToSend(
  eventStatusToIncidentIdMap: Map<EventStatusId, IncidentId[]>,
): UseIncidentToSend {
  // composables
  const { incidents, incidentsUpdate, getIncident } = useIncidents()
  const { homeParticipant } = useParticipants()
  const { event } = useEvent()
  const { incidentIdToSportIncidentMap } = useSportMaps()

  // data
  const supportedIncidentIds = new Set(
    R.flatten([...eventStatusToIncidentIdMap.values()]),
  )

  // computed
  const eventStatusId = computed(() => event.value.status_id)

  // state
  const incidentToSend = ref<SportIncident | null>(null)

  /**
   * maps incident_id to id's of non-deleted API Incidents
   */
  const incidentIdToIdsMap = ref(
    getIncidentIdToIdsMap(incidents.value, supportedIncidentIds),
  )

  // watchers
  watch(
    [eventStatusId, incidentIdToIdsMap],
    () => {
      incidentToSend.value = getIncidentToSend(
        eventStatusId.value,
        eventStatusToIncidentIdMap,
        incidentIdToIdsMap.value,
        incidentIdToSportIncidentMap,
      )
    },
    {
      deep: true,
      immediate: true,
    },
  )

  watch(incidentsUpdate, (newVal) => {
    for (const incidentUpdate of newVal) {
      const incidentId = incidentUpdate.data.incident_id
      const id = incidentUpdate.data.id

      if (supportedIncidentIds.has(incidentId)) {
        if (
          hasDeletedIncidentId(incidentIdToIdsMap.value, incidentUpdate.data)
        ) {
          incidentIdToIdsMap.value[incidentId].delete(id)
        } else if (incidentUpdate.data.action === 'insert') {
          incidentIdToIdsMap.value[incidentId] =
            incidentIdToIdsMap.value[incidentId] || new Set()
          incidentIdToIdsMap.value[incidentId].add(id)
        }
      }
    }
  })

  return {
    incidentToSend,
    getTeamSidePossession: getTeamSidePossession.bind(
      null,
      homeParticipant.value,
      incidentIdToIdsMap.value,
      getIncident,
    ),
  }
}
