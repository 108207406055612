<script setup lang="ts">
import { ref } from 'vue'

import Button from '../../Button/Button.vue'
import TextInput from '../../TextInput/TextInput.vue'
import Pages from './Pages.vue'

// props
interface Props {
  pagesCount: number
}

const props = defineProps<Props>()

// model
const currentPage = defineModel<number>({ required: true })

// state
const newPage = ref<number | null>(null)

// methods
function setNewPage(): void {
  if (newPage.value && newPage.value <= props.pagesCount) {
    currentPage.value = newPage.value
  }
}
</script>

<template>
  <div class="flex">
    <div class="m-auto flex items-center">
      <Button
        class="border-none"
        icon="backward-step"
        iconSize="lg"
        size="xs"
        @click="currentPage = 1"
      />
      <Button
        class="border-none"
        icon="chevron-left"
        iconSize="lg"
        size="xs"
        @click="currentPage = Math.max(currentPage - 1, 1)"
      />
      <Pages
        v-model="currentPage"
        :pagesCount
      />
      <Button
        class="border-none"
        icon="chevron-right"
        iconSize="lg"
        size="xs"
        @click="currentPage = Math.min(currentPage + 1, pagesCount)"
      />
      <Button
        class="border-none"
        icon="forward-step"
        iconSize="lg"
        size="xs"
        @click="currentPage = pagesCount"
      />

      <div class="mx-0.5 text-xs">Go to page</div>

      <div class="border-neutral-light-5 flex rounded border">
        <TextInput
          class="ml-1 flex h-6 w-6 items-center"
          width="full"
          size="xs"
          numeric
          :maxlength="3"
          inputClass=" border-none"
          @update:modelValue="newPage = Number($event)"
          @change="setNewPage"
        />
        <Button
          class="hover:bg-neutral-9 rounded-none border-none"
          size="xs"
          icon="chevron-right"
          @click="setNewPage"
        />
      </div>
    </div>
  </div>
</template>
