export const teamColors = {
  'team-home': {
    0: 'rgb(134, 99, 19)',
    1: 'rgb(157, 115, 22)',
    2: 'rgb(179, 132, 25)',
    3: 'rgb(201, 148, 28)',
    4: 'rgb(224, 164, 31)',
    5: 'rgb(227, 174, 55)',
    6: 'rgb(230, 183, 76)',
    7: 'rgb(233, 192, 98)',
    8: 'rgb(236, 201, 121)',
    9: 'rgb(245, 228, 188)',
    10: 'rgb(252, 246, 233)',
  },

  'team-away': {
    0: 'rgb(80, 21, 107)',
    1: 'rgb(96, 25, 128)',
    2: 'rgb(112, 29, 150)',
    3: 'rgb(129, 33, 171)',
    4: 'rgb(145, 37, 192)',
    5: 'rgb(160, 41, 213)',
    6: 'rgb(170, 63, 218)',
    7: 'rgb(180, 84, 222)',
    8: 'rgb(189, 105, 226)',
    9: 'rgb(217, 170, 238)',
    10: 'rgb(236, 212, 247)',
  },
}
