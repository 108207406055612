<script setup lang="ts">
import * as R from 'ramda'
import { ref, watch } from 'vue'

import { MultiSelect, SuccessBadge } from '@collector/shared-ui'
import { ValueType } from '@collector/shared-utils'
import { Incident } from '@collector/sportsapi-client'
import { useSportMaps } from '@desktop/views/Relation/composables'
import { useEventIncidentsQueue } from '@desktop/views/Relation/Sports/composables'
import { IncidentSettings } from '@desktop/views/Relation/Sports/configuration'

interface Props {
  incident: Incident
  disabled: boolean
  attributeIds: NonNullable<ValueType<IncidentSettings['attributeIds']>>
  singleAttributes: NonNullable<IncidentSettings['singleAttributes']>
}

const props = defineProps<Props>()

// composables
const { incidentAttributeIdToAttributeMap } = useSportMaps()
const eventIncidentsQueue = useEventIncidentsQueue()

// data
const attributesOptions = props.attributeIds.map((id) => ({
  name: incidentAttributeIdToAttributeMap[id].name,
  value: id,
}))

// state
const selectedAttributeIds = ref<number[] | number | undefined>(
  getSelectedAttributeIdsValue(),
)
const successBadge = ref<InstanceType<typeof SuccessBadge> | null>(null)

// methods
function getSelectedAttributeIdsValue(): number[] | number | undefined {
  if (props.singleAttributes) {
    return props.incident.attribute_ids === null ||
      props.incident.attribute_ids.length === 0
      ? undefined
      : props.incident.attribute_ids[0]
  }

  return props.incident.attribute_ids === null
    ? []
    : R.clone(props.incident.attribute_ids)
}

function updateAttributes(): void {
  const selectedAttributeIdsArray = Array.isArray(selectedAttributeIds.value)
    ? selectedAttributeIds.value
    : selectedAttributeIds.value !== undefined
      ? [selectedAttributeIds.value]
      : []

  // prevent updating:
  // - no changes in attributes
  // - same attributes clicked in different order
  if (
    R.symmetricDifference(
      props.incident.attribute_ids || [],
      selectedAttributeIdsArray,
    ).length === 0
  ) {
    return
  }

  eventIncidentsQueue.updateIncident(
    { attribute_ids: [...selectedAttributeIdsArray] },
    props.incident,
  )
}

// watchers
watch(
  () => props.incident.attribute_ids,
  () => {
    const attributeIds = getSelectedAttributeIdsValue()

    if (!R.equals(attributeIds, selectedAttributeIds.value)) {
      selectedAttributeIds.value = attributeIds
    } else {
      successBadge.value?.show()
    }
  },
)
</script>

<template>
  <MultiSelect
    v-model="selectedAttributeIds"
    :options="attributesOptions"
    size="xs"
    :disabled
    withFilter
    withOptionsFullWidth
    @blur="updateAttributes"
    @clearValue="updateAttributes"
  >
    <div class="flex">
      <span class="w-full">attributes</span>
      <SuccessBadge
        ref="successBadge"
        class="pr-2.5"
      />
    </div>
  </MultiSelect>
</template>
