import { Browser, detect } from 'detect-browser'
import devtools from 'devtools-detect'

import { AppMode } from '@collector/shared-plugin-config'

function setScaledRootFontSize(height?: number): void {
  const baseFontSize = 16
  const scaledFontSize = (getScaledRemFactor(height) * baseFontSize).toFixed(2)

  document.documentElement.style.fontSize = `${scaledFontSize}px`
}

function getZoomFactor(): number {
  // for some strange reason, outer width is 0 in some cases
  // this is probably bug in Chromium
  // https://issues.chromium.org/issues/41317497
  //
  // in this case, we return 1 which actually works like without zoom
  if (window.outerWidth === 0) return 1

  return window.outerWidth / window.innerWidth
}

/**
 * Return true if browser can properly calculate the zoom factor
 */
function isZoomFactorReliableBrowser(): boolean {
  const browser = detect()
  const supportedBrowsers: Browser[] = ['chrome', 'safari', 'edge-chromium']

  return supportedBrowsers.includes(browser?.name as Browser)
}

/**
 * Calculate root element font-size factor for scaling the layout based on the screen size
 */
export function getScaledRemFactor(height?: number): number {
  // 1440px is the base width of the Figma design
  // 790px is the base height of the Figma design, which acknowledges the unavailable height (browser bar, system bar, etc.)
  const baseHeight = 790
  const currentHeight = height || window.innerHeight
  const zoomFactor = isZoomFactorReliableBrowser() ? getZoomFactor() : 1
  const adjustedCurrentHeight = currentHeight * zoomFactor

  return adjustedCurrentHeight / baseHeight
}

/**
 * Scale the base layout for different resolutions, to always fit full screen
 */
export function scaleLayout(environment: AppMode): void {
  const scaleHeightBreakpointPercentage = 0.8

  // do not scale the layout when devtools are open because it make it difficult to inspect other problems
  if (devtools.isOpen && environment === 'development') {
    return
  }

  // init
  if (window.innerHeight / screen.height > scaleHeightBreakpointPercentage) {
    setScaledRootFontSize()
  } else {
    setScaledRootFontSize(
      screen.availHeight - window.outerHeight + window.innerHeight,
    )
  }
}
