<script setup lang="ts">
import { Box } from '@collector/shared-ui'
import { TeamSide } from '@collector/shared-ui-domain'
import { Incident, Participant } from '@collector/sportsapi-client'
import PitchTeamName from '@desktop/views/Relation/Sports/components/PitchTeamName.vue'

import ConfirmIncident from './ConfirmIncident.vue'

interface Props {
  reversed?: boolean
  teamSide: TeamSide
  participant: Participant
}

defineProps<Props>()

// data
const maxVisibleIncidents = 2

// model
const incidents = defineModel<Incident[]>({ required: true })
</script>

<template>
  <div class="relative w-full min-w-[8.25rem] flex-shrink-[3]">
    <Box
      class="h-full"
      :class="{ [`notify-pulse-team-${teamSide}`]: !!incidents.length }"
    >
      <PitchTeamName
        :teamSide
        :participant
      />
      <div
        v-if="!!incidents.length"
        class="-mr-1 h-20 space-y-2 overflow-y-auto pr-1 pt-2"
      >
        <ConfirmIncident
          v-for="incident in [...incidents].reverse()"
          :key="incident.id"
          :class="{ 'flex-row-reverse': reversed }"
          :incident
          :teamSide
        />
      </div>
      <div
        v-else
        class="text-neutral-dark-7 flex h-20 flex-col items-center justify-center text-xs font-bold"
        data-test-id="NoIncidentsToConfirmText"
      >
        <div>There are</div>
        <div>no incidents</div>
        <div>to confirm</div>
      </div>
    </Box>
    <div
      v-if="incidents.length > maxVisibleIncidents"
      class="text-neutral-dark-3 bg-neutral-light-2 absolute bottom-0 w-full rounded-md text-center text-xs font-bold"
    >
      +{{ incidents.length - maxVisibleIncidents }} more
    </div>
  </div>
</template>

<style scoped lang="scss">
$duration: 1.5s;

.notify-pulse-team {
  &-home {
    animation: notify-pulse-current-team-home-animation $duration infinite;
  }

  @keyframes notify-pulse-current-team-home-animation {
    50% {
      @apply bg-current-team-home-5/30;
    }
  }

  &-away {
    animation: notify-pulse-current-team-away-animation $duration infinite;
  }
  @keyframes notify-pulse-current-team-away-animation {
    50% {
      @apply bg-current-team-away-5/30;
    }
  }
}
</style>
