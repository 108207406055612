import * as R from 'ramda'
import { watch } from 'vue'

import {
  getParticipantIdOppositeTeamSide,
  getParticipantIdTeamSide,
} from '@collector/shared-ui-domain'
import { IncidentId } from '@collector/sportsapi-client-legacy'

import {
  useEventSettings,
  useIncidents,
  useParticipants,
} from '../../composables'
import { IncidentsChangeTeamPossessionConfig } from '../configuration'

export function useOnIncidentChangeTeamPossession<T extends IncidentId>(
  config: IncidentsChangeTeamPossessionConfig<T>,
): void {
  // composables
  const { updateEventSettings } = useEventSettings()
  const { userIncidentsUpdate } = useIncidents()
  const { homeParticipant, awayParticipant } = useParticipants()

  // watchers
  watch(userIncidentsUpdate, (update) => {
    const lastIncident = R.last(update)

    if (!lastIncident) return

    const {
      data: { incident_id, participant_team_id, action },
    } = lastIncident

    // only handle insert action
    if (action !== 'insert') return

    const incidentConfig = config[incident_id as T]

    if (incidentConfig) {
      switch (incidentConfig.toTeamPossession) {
        case 'current': {
          updateEventSettings({
            teamSidePossession: getParticipantIdTeamSide(
              participant_team_id,
              homeParticipant.value,
              awayParticipant.value,
            )!,
          })

          break
        }
        case 'opposite': {
          updateEventSettings({
            teamSidePossession: getParticipantIdOppositeTeamSide(
              participant_team_id,
              homeParticipant.value,
              awayParticipant.value,
            )!,
          })

          break
        }
      }
    }
  })
}
