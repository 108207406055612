<script setup lang="ts">
import { TeamSide } from '@collector/shared-ui-domain'
import { Incident } from '@collector/sportsapi-client'
import { useBetIncidents } from '@desktop/views/Relation/Sports/composables'

// props
interface Props {
  incident: Incident
  isDeleted?: boolean
  teamSide: TeamSide | null
}

const props = defineProps<Props>()

// composables
const { isEmergencyIncident, hasIncidentMasterAttribute, isBetIncident } =
  useBetIncidents()

function getGradient(incident: Incident, teamSide: TeamSide | null): string {
  switch (true) {
    case hasIncidentMasterAttribute(incident) || isEmergencyIncident(incident):
      return props.isDeleted
        ? 'from-error-5/25 to-error-10/25'
        : 'from-error-5/60 to-error-10/60'
    case isBetIncident(incident):
      return props.isDeleted
        ? 'from-error-9/20 to-error-10/15'
        : 'from-error-9/50 to-error-10/40'
    case teamSide === 'home':
      return props.isDeleted
        ? 'from-current-team-home-5/15 to-neutral-light-10/15'
        : 'from-current-team-home-5/40 to-neutral-light-10/40'
    case teamSide === 'away':
      return props.isDeleted
        ? 'from-current-team-away-5/15 to-neutral-light-10/15'
        : 'from-current-team-away-5/40 to-neutral-light-10/40'
    default:
      return props.isDeleted
        ? 'from-neutral-light-6/15 to-neutral-light-10/15'
        : 'from-neutral-light-6/40 to-neutral-light-10/40'
  }
}
</script>

<template>
  <div
    class="shadow-home-shadow rounded bg-gradient-to-tr"
    :class="{
      [getGradient(incident, teamSide)]: true,
    }"
  >
    <slot></slot>
  </div>
</template>
