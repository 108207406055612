import { computed, ref } from 'vue'

import { isHttpError } from '@collector/shared-utils'
import { useEventBus } from '@vueuse/core'

import { clearSportsApiClient } from '../sportsApiClient'
import { useAuthInfo } from './useAuthInfo'
import { initSportsApiClient } from './utils/client'
import { refreshTokens, setTokenRefreshTimeout } from './utils/refresh'
import {
  clearSportsApiTokens,
  getSportsApiTokens,
  setSportsApiTokens,
} from './utils/token'

export interface AuthTokens {
  accessToken: string
  refreshToken: string
}

const accessTokenBus = useEventBus<string>('accessTokenBus')
const initializing = ref(false)
let refreshPromise: Promise<AuthTokens> | null = null

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useAuth() {
  const { authInfo, initAuthInfo, destroyAuthInfo } = useAuthInfo()

  async function logIn(
    accessToken: string,
    refreshToken: string,
  ): Promise<void> {
    if (!import.meta.env.DEV) {
      // eslint-disable-next-line
      console.debug('[Auth] logIn')
    }

    initSportsApiClient(accessToken, refreshAuth)
    setSportsApiTokens(accessToken, refreshToken)
    accessTokenBus.emit(accessToken)

    await initAuthInfo()

    // ?NOTE: initAuthInfo may refresh tokens, need to get them from LS
    const tokens = getSportsApiTokens()
    if (tokens) setTokenRefreshTimeout(tokens.accessToken, refreshAuth)
  }

  // Allow only one refresh at a time
  async function refreshAuth(): Promise<AuthTokens> {
    if (refreshPromise) return refreshPromise
    refreshPromise = _refresh()
    return refreshPromise
  }

  async function _refresh(): Promise<AuthTokens> {
    // eslint-disable-next-line no-console
    console.log('[Auth] refresh')

    const tokens = getSportsApiTokens()
    if (tokens === null) throw new Error('Auth refresh failed: tokens not set')
    try {
      const refreshedTokens = await refreshTokens(tokens)
      await logIn(refreshedTokens.accessToken, refreshedTokens.refreshToken)
      return refreshedTokens
    } catch (err) {
      if (window.navigator.onLine) logOut()
      throw err
    } finally {
      refreshPromise = null
    }
  }

  function logOut(): void {
    if (!import.meta.env.DEV) {
      // eslint-disable-next-line
      console.debug('[Auth] logOut')
    }

    clearSportsApiTokens()
    clearSportsApiClient()
    destroyAuthInfo()
  }

  async function initAuth(): Promise<void> {
    if (!import.meta.env.DEV) {
      // eslint-disable-next-line
      console.debug('[Auth] init')
    }

    initializing.value = true
    try {
      const tokens = getSportsApiTokens()
      if (tokens !== null) await logIn(tokens.accessToken, tokens.refreshToken)
    } catch (err) {
      if (!isHttpError(err, 401)) throw err
    } finally {
      initializing.value = false
    }
  }

  return {
    initAuth,
    logIn,
    logOut,
    refreshAuth,
    authInfo,
    accessTokenBus,
    loggedIn: computed(() => !!authInfo.value),
    initialized: computed(() => !initializing.value),
    get tokens() {
      return getSportsApiTokens()
    },
  }
}
