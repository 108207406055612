import { computed, ComputedRef } from 'vue'

import { BetStatus } from '@collector/sportsapi-client-legacy'
import { useEvent } from '@desktop/views/Relation/composables'

type UseBetStatus = {
  betStatusBackgroundClass: ComputedRef<string>
}

export function useBetStatus(): UseBetStatus {
  // composables
  const { event } = useEvent()

  // computed
  const betStatusBackgroundClass = computed<string>(() => {
    if (isMainBetMarketSuspended()) {
      return 'bg-neutral-dark-1'
    } else if (isAnyOtherBetMarketSuspended()) {
      return 'bg-error-5'
    }

    return 'bg-success-0'
  })

  // methods
  function isMainBetMarketSuspended(): boolean {
    return event.value.bet_status === BetStatus.Suspended
  }

  function isAnyOtherBetMarketSuspended(): boolean {
    return [event.value.bet_cards, event.value.bet_corners].some(
      (value) => value === BetStatus.Suspended,
    )
  }

  return {
    betStatusBackgroundClass,
  }
}
