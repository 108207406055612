<script setup lang="ts">
import * as R from 'ramda'
import { computed, ref, watch } from 'vue'

import {
  Button,
  Modal,
  MultiSelect,
  runLoader,
  useConfirmModal,
} from '@collector/shared-ui'
import { ParticipantLogo } from '@collector/shared-ui-domain'
import { Participant, SoccerStatId } from '@collector/sportsapi-client-legacy'
import { sportsApiClient } from '@desktop/globalState/sportsApiClient'
import {
  useEvent,
  useParticipants,
  useSport,
} from '@desktop/views/Relation/composables'
import { fetchSubParticipantsForParticipant } from '@desktop/views/Relation/utils'
import { getSortedSquad } from '@desktop/views/Relation/utils/sportsapi/subParticipant'

import FormationsPitch from './FormationsPitch/FormationsPitch.vue'
import InvalidAssignedPlayersConfirmModal from './InvalidAssignedPlayersConfirmModal.vue'
import SubParticipantsList from './SubParticipantsList.vue'
import {
  getDeletedSubParticipantsPatchPayload,
  getGoalkeeperPositionToSubParticipantMap,
  getPlayerPositionToSubParticipantMap,
  getSquadSubParticipantsPatchPayload,
  patchParticipantFormation,
  sortSportFormations,
} from './utils'

interface Props {
  participant: Participant
}
const props = defineProps<Props>()

type Emits = { close: [] }
const emit = defineEmits<Emits>()

// composables
const { sport } = useSport()
const { event } = useEvent()
const { getParticipantStatsMap } = useParticipants()
const { openModalForConfirm } = useConfirmModal()

// data
const formationStatId = SoccerStatId.Formation

const subParticipants = getSortedSquad(
  await runLoader(
    fetchSubParticipantsForParticipant(event.value.id, props.participant.id),
  ),
)

const formations = sortSportFormations(sport.formations)

const initialPlayerPositionToSubParticipantMap =
  getPlayerPositionToSubParticipantMap(subParticipants)

// state
const playerPositionToSubParticipantMap = ref({
  ...initialPlayerPositionToSubParticipantMap,
})
const selectedPlayerPosition = ref<string>()
const formation = ref(getFormationValue())

// computed
const hasFormationChanges = computed(
  () => formation.value !== getFormationValue(),
)

const hasPlayerPositionChanges = computed(
  () =>
    !R.equals(
      playerPositionToSubParticipantMap.value,
      initialPlayerPositionToSubParticipantMap,
    ),
)

/** when only goalkeeper is left (1 player), do not disable select */
const formationSelectDisabled = computed(
  () => hasPlayerPositionChanges.value && getAssignedPlayersCount() > 1,
)

// methods
function getFormationValue(): string {
  return String(
    getParticipantStatsMap(props.participant).value[formationStatId]?.value ||
      '',
  )
}

function getAssignedPlayersCount(): number {
  return Object.keys(playerPositionToSubParticipantMap.value).length
}

function clearAll(): void {
  formation.value = ''
}

async function confirmUpdateFormations(): Promise<void> {
  if (getAssignedPlayersCount() < 11) {
    await openModalForConfirm(InvalidAssignedPlayersConfirmModal)
  }

  await runLoader(updateFormations())
}

async function sendUpdateSequence(): Promise<void> {
  const squadPayload = getSquadSubParticipantsPatchPayload(
    playerPositionToSubParticipantMap.value,
  )
  const deletedSquadPayload = getDeletedSubParticipantsPatchPayload(
    playerPositionToSubParticipantMap.value,
    initialPlayerPositionToSubParticipantMap,
  )

  if (deletedSquadPayload.length) {
    // subParticipants deleted from squad (position = null) must be send with separate request
    // (sending deleted players position = null with squad position like '2' in the same request causes API error)
    await sportsApiClient.Events.SubParticipants.patch(
      event.value.id,
      deletedSquadPayload,
    )
  }

  if (squadPayload.length) {
    await sportsApiClient.Events.SubParticipants.patch(
      event.value.id,
      squadPayload,
    )
  }
}

async function updateFormations(): Promise<void> {
  const promises: Promise<unknown>[] = []

  if (hasFormationChanges.value) {
    promises.push(
      patchParticipantFormation(
        event.value.id,
        props.participant.id,
        formationStatId,
        formation.value,
      ),
    )
  }

  if (hasPlayerPositionChanges.value) {
    promises.push(sendUpdateSequence())
  }

  await Promise.all(promises)
}

// watchers
watch(formation, () => {
  selectedPlayerPosition.value = undefined
  playerPositionToSubParticipantMap.value =
    getGoalkeeperPositionToSubParticipantMap(subParticipants)
})
</script>

<template>
  <Modal
    :confirmButton="{
      text: 'Save',
      confirm: confirmUpdateFormations,
      disabled: !hasFormationChanges && !hasPlayerPositionChanges,
    }"
    :withCloseIcon="false"
    :withCloseShortcuts="false"
    @close="emit('close')"
  >
    <template #title>
      <div class="flex items-center gap-x-2">
        <ParticipantLogo :participant />
        <span class="flex-1 text-lg font-bold">
          {{ participant.short_name }}
        </span>
        <span class="text-neutral-dark-3/30 text-lg font-bold">Formations</span>
        <MultiSelect
          v-model="formation"
          class="w-28"
          :options="formations"
          :disabled="formationSelectDisabled"
        />
        <Button
          class="bg-neutral-light-10 border-neutral-dark-3/15 hover:bg-neutral-light-4 hover:border-neutral-dark-2 flex-1 px-10 font-bold"
          size="md"
          @click="clearAll"
        >
          Clear all
        </Button>
      </div>
    </template>
    <div class="flex">
      <FormationsPitch
        v-model:selectedPlayerPosition="selectedPlayerPosition"
        v-model:formation="formation"
        :participant
        :playerPositionToSubParticipantMap
      />
      <SubParticipantsList
        v-model:selectedPlayerPosition="selectedPlayerPosition"
        v-model:playerPositionToSubParticipantMap="
          playerPositionToSubParticipantMap
        "
        :subParticipants
      />
    </div>
  </Modal>
</template>
