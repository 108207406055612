<script setup lang="ts">
import ListTitle from '../ListTitle.vue'
import { Player } from '../types'
import LineupPlayerRows from './LineupPlayerRows.vue'
import NoPlayers from './NoPlayers.vue'

interface Props {
  title: string
  players: Player[]
}
withDefaults(defineProps<Props>(), { players: () => [] })

// emits
type Emits = {
  playerRemove: [Player]
}
const emit = defineEmits<Emits>()
</script>

<template>
  <div class="space-y-1">
    <ListTitle :title="`${title} (${players.length})`" />

    <template v-if="players.length">
      <LineupPlayerRows
        :players
        @playerRemove="emit('playerRemove', $event)"
      >
        <template #default="{ player }">
          <slot v-bind="{ player }" />
        </template>
      </LineupPlayerRows>
    </template>

    <NoPlayers
      v-else
      :title
    />
  </div>
</template>
