it
<script setup lang="ts">
import { ref } from 'vue'

import { useBetStatus } from '@desktop/views/Relation/composables'
import EventStatusIncidentsOnField from '@desktop/views/Relation/Sports/components/EventStatusIncidentsOnField/EventStatusIncidentsOnField.vue'
import { useBlockades } from '@desktop/views/Relation/Sports/composables'

import Field from './Field/Field.vue'
import PenaltyOverlay from './overlays/Penalty/PenaltyOverlay.vue'
import PointOutIncidentPositionOverlay from './overlays/PointOutIncidentPosition/PointOutIncidentPositionOverlay.vue'
import StatusOverlays from './overlays/StatusOverlays.vue'

// composables
const { isPitchBlocked } = useBlockades()
const { betStatusBackgroundClass } = useBetStatus()

// data
const disabledClasses =
  'before:opacity-50 before:absolute before:w-full before:h-full before:bg-white before:inset-0'

// state
const eventStatusIncidentsOnFieldRendered = ref(false)
const pointOutIncidentPositionOverlayRendered = ref(false)
const penaltyOverlayRendered = ref(false)
</script>

<template>
  <div
    class="relative rounded px-6 py-2 transition-colors duration-200"
    :class="{
      [`pointer-events-none ${disabledClasses}`]: isPitchBlocked(),
      [`${betStatusBackgroundClass}`]: betStatusBackgroundClass,
    }"
  >
    <div class="relative">
      <!-- field of play (rectangle on pitch) -->
      <div
        class="absolute left-1/2 top-1/2 aspect-[1.545] h-full -translate-x-1/2 -translate-y-1/2"
        :class="{ [disabledClasses]: isPitchBlocked() }"
      >
        <EventStatusIncidentsOnField
          v-if="!pointOutIncidentPositionOverlayRendered"
          class="z-30"
          @open="eventStatusIncidentsOnFieldRendered = $event"
        />
        <PenaltyOverlay
          class="z-30"
          @open="penaltyOverlayRendered = $event"
        />
        <StatusOverlays
          :class="{
            'top-[6cqh]':
              eventStatusIncidentsOnFieldRendered || penaltyOverlayRendered,
          }"
        />
        <PointOutIncidentPositionOverlay
          v-if="!eventStatusIncidentsOnFieldRendered"
          @open="pointOutIncidentPositionOverlayRendered = $event"
        />
        <Field v-if="!pointOutIncidentPositionOverlayRendered" />
      </div>
      <img
        class="pointer-events-none h-auto w-full select-none"
        src="./pitch.svg"
        alt="pitch"
      />
    </div>
  </div>
</template>
