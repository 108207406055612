import {
  SportsApiAccessToken,
  SportsApiRefreshToken,
} from '@desktop/utils/localStorage'

import { AuthTokens } from '../useAuth'

export function getSportsApiTokens(): AuthTokens | null {
  const accessToken = SportsApiAccessToken.get()
  const refreshToken = SportsApiRefreshToken.get()
  if (!refreshToken || !accessToken) return null
  return { accessToken, refreshToken }
}

export function setSportsApiTokens(
  accessToken: string,
  refreshToken: string,
): void {
  SportsApiAccessToken.save(accessToken)
  SportsApiRefreshToken.save(refreshToken)
}

export function clearSportsApiTokens(): void {
  SportsApiAccessToken.remove()
  SportsApiRefreshToken.remove()
}
