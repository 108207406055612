import { Events, Incident } from '@collector/sportsapi-client'
import {
  Competition,
  EventScout,
  Sport,
  SubParticipant,
} from '@collector/sportsapi-client-legacy'
import {
  EventSettingsRecord,
  EventSettingsTable,
} from '@desktop/utils/database'

import { SyncedTimestamp } from '../utils'
import {
  fetchCompetitionWithTimestamp,
  fetchEventScouts,
  fetchIncidents,
  fetchSport,
  fetchSubParticipants,
  fetchSwappableIncidents,
} from './api'

type RelationDependencies = {
  competition: Competition
  competitionTimestamp: SyncedTimestamp
  eventSettings: EventSettingsRecord
  eventScouts: EventScout[]
  incidents: Incident[]
  sport: Sport
  subParticipants: SubParticipant[]
  swappableIncidents: Events.SwappableIncidents.SwappableIncidentsConfigurationMap
}

export async function fetchRelationDependencies(
  eventId: number,
): Promise<RelationDependencies> {
  const [
    { competition, competitionTimestamp },
    eventScouts,
    incidents,
    subParticipants,
    swappableIncidents,
    eventSettings,
  ] = await Promise.all([
    fetchCompetitionWithTimestamp(eventId),
    fetchEventScouts(eventId),
    fetchIncidents(eventId),
    fetchSubParticipants(eventId),
    fetchSwappableIncidents(eventId),
    EventSettingsTable.get(eventId),
  ])

  const sport = await fetchSport(competition.sport_id)

  return {
    competition,
    competitionTimestamp,
    eventScouts,
    eventSettings: eventSettings ?? {},
    incidents,
    sport,
    subParticipants,
    swappableIncidents,
  }
}
