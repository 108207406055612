<script setup lang="ts">
import { ref } from 'vue'

import { ButtonGroup } from '@collector/shared-ui'

import History from './History/History.vue'
import IncidentsTimeline from './IncidentsTimeline.vue'
import Statistics from './Statistics.vue'

// data
const heightFullscreenClass = '@lg:h-[calc(100vh-10.375rem)]'

// state
const selectedOptionIndex = ref(0)
</script>

<template>
  <div class="space-y-3">
    <ButtonGroup
      v-model="selectedOptionIndex"
      :options="['History', 'Incidents Timeline', 'Statistics']"
      width="full"
    />
    <div>
      <History
        v-show="selectedOptionIndex === 0"
        :class="heightFullscreenClass"
      />
      <IncidentsTimeline
        v-show="selectedOptionIndex === 1"
        class="overflow-y-auto"
        :class="heightFullscreenClass"
      />
      <Statistics
        v-show="selectedOptionIndex === 2"
        class="@lg:overflow-y-auto"
        :class="heightFullscreenClass"
      />
    </div>
  </div>
</template>
