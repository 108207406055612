<script setup lang="ts">
import { watch } from 'vue'

import { MultiSelect } from '@collector/shared-ui'
import { useEventSettings } from '@desktop/views/Relation/composables'
import { useSwitchTeamColors } from '@desktop/views/Relation/composables/useSwitchColors'

// composables
const { teamColorsSwitched, setTeamColors } = useSwitchTeamColors()
const { eventSettings, updateEventSettings } = useEventSettings()

// model
const model = defineModel<'yes' | 'no'>()

model.value = teamColorsSwitched.value ? 'yes' : 'no'

// watchers
watch(model, (value) => {
  updateEventSettings({
    teamColorsSwitched: value === 'yes',
  })
})

watch(
  () => eventSettings.value.teamColorsSwitched,
  (switched) => {
    setTeamColors('home', switched ? 'away' : 'home')
    setTeamColors('away', switched ? 'home' : 'away')
  },
  { immediate: true },
)
</script>

<template>
  <MultiSelect
    v-model="model"
    :withClear="false"
    :options="[
      { name: 'Yes', value: 'yes' },
      { name: 'No', value: 'no' },
    ]"
  >
    Team colors switched
  </MultiSelect>
</template>
