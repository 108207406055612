/* eslint-disable @typescript-eslint/no-unused-expressions */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
export function initWidget(widgetsUrl: string): void {
  !(function () {
    const d = 'STATSCOREWidgetsEmbederScript'
    if (!window.document.getElementById(d)) {
      ;(window.STATSCOREWidgets = {}),
        (window.STATSCOREWidgets.onLoadCallbacks = []),
        (window.STATSCOREWidgets.onLoad = function (d) {
          window.STATSCOREWidgets.onLoadCallbacks.push(d)
        })
      const n = window.document.createElement('script')
      ;(n.src = widgetsUrl),
        (n.async = !0),
        (n.id = d),
        n.addEventListener('error', function (d) {
          for (
            let n = 0;
            n < window.STATSCOREWidgets.onLoadCallbacks.length;
            n++
          )
            window.STATSCOREWidgets.onLoadCallbacks[n](d)
        }),
        window.document.body.appendChild(n)
    }
  })()
}

export function isWidgetResponse(response: Response): boolean {
  return response.url.includes('widgets') && response.url.includes('statscore')
}
