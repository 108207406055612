<script setup lang="ts">
import { computed } from 'vue'

import { Button, Icon } from '@collector/shared-ui'
import {
  BetMarketEventKey,
  translateBetMarketKeyToBetMarketType,
} from '@collector/shared-ui-domain'
import {
  BetStatus,
  EventStatusId,
  IncidentId,
} from '@collector/sportsapi-client-legacy'
import { useEvent } from '@desktop/views/Relation/composables'
import {
  useBlockades,
  useEventIncidentsQueue,
} from '@desktop/views/Relation/Sports/composables'

interface Props {
  betMarketEventKey: BetMarketEventKey
  betStatusActiveText: string
  betStatusInactiveText: string
  openMarketsIncidentId: IncidentId
  closeMarketsIncidentId: IncidentId
  disabledStatuses: Set<EventStatusId>
}

const props = defineProps<Props>()

// composables
const { event } = useEvent()
const { hasCurrentEventStatus, isBetMarketUpdateEnabled } = useBlockades()
const eventIncidentsQueue = useEventIncidentsQueue()

// methods
function toggleBetMarket(): void {
  eventIncidentsQueue.addIncident({
    incident_id: betMarketActive.value
      ? props.closeMarketsIncidentId
      : props.openMarketsIncidentId,
  })
}

// computed
const betMarketActive = computed(
  () => event.value[props.betMarketEventKey] === BetStatus.Active,
)

const disabled = computed(() => {
  const betMarketType = translateBetMarketKeyToBetMarketType(
    props.betMarketEventKey,
  )

  if (!isBetMarketUpdateEnabled(betMarketType)) {
    return true
  }

  return hasCurrentEventStatus(props.disabledStatuses)
})
</script>

<template>
  <Button
    class="text-neutral-10 disabled:text-neutral-10 @md:px-1.5 @md:text-sm @lg:text-xs @xl:text-sm px-0.5 text-xs font-bold disabled:border-transparent"
    :class="{
      'enabled:bg-success-2 enabled:border-success-2 enabled:hover:border-success-4 enabled:hover:bg-success-4 disabled:bg-success-4/50':
        betMarketActive,
      'enabled:bg-error-5 enabled:border-error-5 enabled:hover:bg-error-7 enabled:hover:border-error-7 disabled:bg-error-6/50':
        !betMarketActive,
    }"
    size="sm"
    :disabled
    width="full"
    @click="toggleBetMarket"
  >
    <span class="@md:inline @lg:hidden @xl:inline hidden">
      <slot v-if="betMarketActive" />
      <Icon
        v-else
        icon="lock"
      />
    </span>

    {{
      betMarketActive ? props.betStatusActiveText : props.betStatusInactiveText
    }}
  </Button>
</template>
