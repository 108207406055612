<script setup lang="ts">
import { Ref, ref, watch } from 'vue'

import { MultiSelect, MultiSelectObjectOption } from '@collector/shared-ui'
import { isLettersAndSpaces, isNumericInteger } from '@collector/shared-utils'
import { Participants } from '@collector/sportsapi-client'
import {
  BaseParticipant,
  ParticipantProviderId,
  SubParticipantPosition,
} from '@collector/sportsapi-client-legacy'
import { sportsApiClient } from '@desktop/globalState/sportsApiClient'
import { useSport } from '@desktop/views/Relation/composables'
import { useCompetition } from '@desktop/views/Relation/composables/useCompetition'

import {
  injectAddPlayer,
  injectCanAddPlayer,
} from '../provide-inject/subParticipant'
import ShirtNrTextInput from '../ShirtNrTextInput.vue'
import SubParticipantButton from '../SubParticipantButton.vue'
import { Player } from '../types'
import SearchForPlayerOption from './SearchForPlayerOption.vue'
import { useNewPlayerConfirmModal } from './useNewPlayerConfirmModal'
import { getSelectOptionExtendedText, subParticipantPositions } from './utils'

// inject
const { canAddSubParticipant, canAddSubParticipantTitle } = injectCanAddPlayer()
const addPlayer = injectAddPlayer()

// composables
const { canCreateNewPlayer } = useNewPlayerConfirmModal()
const { competition } = useCompetition()
const { sport } = useSport()

// state
const shirtNr = ref('')
const selectedPlayerId = ref<number | undefined>()
const searchTerm = ref('')
const open = ref(false)
const options: Ref<Promise<MultiSelectObjectOption[]> | []> = ref([])

// methods
async function confirmAddNewPlayer(
  subParticipantPosition: SubParticipantPosition,
): Promise<void> {
  if (await canCreateNewPlayer(shirtNr.value, subParticipantPosition)) {
    const player = (await options.value).find(
      (participant) => participant.value === selectedPlayerId.value,
    )

    const newPlayer = {
      ...player?.metadata,
      shirt_nr: shirtNr.value,
    }

    addPlayer(newPlayer as Player, subParticipantPosition)

    options.value = []
    shirtNr.value = ''
    searchTerm.value = ''
    selectedPlayerId.value = undefined
  }
}

function getSelectOptions(
  participants: BaseParticipant[],
): MultiSelectObjectOption[] {
  return participants.map((participant) => ({
    value: participant.id,
    name: `${participant.short_name} ${getSelectOptionExtendedText(participant)}`,
    metadata: participant,
  }))
}

async function fetchParticipants(
  query: Partial<Participants.ParticipantsGetQuery>,
): Promise<BaseParticipant[]> {
  const participants = await sportsApiClient.Participants.get({
    sport_id: sport.id,
    type: 'person',
    gender: competition.gender,
    ...query,
  })

  const filteredParticipants = (
    participants.api.data.participants || []
  ).filter(
    (participant) =>
      participant.details.subtype !== 'referee' &&
      participant.details.subtype !== 'director',
  )

  return filteredParticipants
}

async function fetchSelectOptions(
  query: Partial<Participants.ParticipantsGetQuery>,
): Promise<MultiSelectObjectOption[]> {
  const participants = await fetchParticipants(query)

  return getSelectOptions(participants)
}

async function searchForPlayer(): Promise<void> {
  const searchTermValue = searchTerm.value.trim()

  if (isNumericInteger(searchTermValue) && Number(searchTermValue) > 0) {
    options.value = fetchSelectOptions({
      provider_id: ParticipantProviderId.Scoresway,
      provider_participant_id: Number(searchTermValue),
    })

    open.value = true
  } else if (
    isLettersAndSpaces(searchTermValue) &&
    searchTermValue.length > 2
  ) {
    options.value = fetchSelectOptions({
      short_name: searchTermValue,
    })

    open.value = true
  } else {
    options.value = []
    open.value = false
  }
}

watch(searchTerm, searchForPlayer)
</script>

<template>
  <ShirtNrTextInput
    v-model="shirtNr"
    :withValidationStyle="false"
  />

  <MultiSelect
    v-model="selectedPlayerId"
    v-model:searchTerm="searchTerm"
    v-model:open="open"
    class="w-full"
    :options
    mode="combobox"
    placeholder="Player name or ID"
  >
    <template #displayedValue="{ selectedOption }">
      <SearchForPlayerOption
        :option="selectedOption as MultiSelectObjectOption"
      />
    </template>
    <template #option="{ option }">
      <SearchForPlayerOption :option />
    </template>
  </MultiSelect>

  <SubParticipantButton
    v-for="subParticipantPosition in subParticipantPositions"
    :key="subParticipantPosition"
    :subParticipantPosition
    :disabled="
      !selectedPlayerId ||
      !canAddSubParticipant(subParticipantPosition, {
        id: selectedPlayerId,
        shirtNr,
      })
    "
    :tooltip="
      canAddSubParticipantTitle(subParticipantPosition, {
        id: selectedPlayerId,
        shirtNr,
      })
    "
    @click="confirmAddNewPlayer(subParticipantPosition)"
  />
</template>
