<script setup lang="ts">
import { computed, onMounted, watch } from 'vue'

import { Overlay } from '@collector/shared-ui'
import { useEvent, useSportMaps } from '@desktop/views/Relation/composables'
import { injectPitchInteractionDisabledStatuses } from '@desktop/views/Relation/Sports/provide-inject'

import BallPositionIndicator from './PointOutIncidentBallPositionIndicator.vue'
import { useIncidentsRequirePointingPosition } from './useIncidentsRequirePointingPosition'

// emits
const emit = defineEmits<{
  open: [open: boolean]
}>()

// composables
const { getIncidentName } = useSportMaps()
const { event } = useEvent()
const incidentsRequirePointingPosition = useIncidentsRequirePointingPosition()

// data
const { currentIncidentsWithoutPosition, saveIncidentPosition } =
  incidentsRequirePointingPosition

// injections
const pitchInteractionDisabledStatuses =
  injectPitchInteractionDisabledStatuses()

// computed
const currentIncidentToUpdate = computed(() => {
  return currentIncidentsWithoutPosition.value[0]
})

const hasValidEventStatus = computed(() => {
  return !pitchInteractionDisabledStatuses.has(event.value.status_id)
})

const open = computed(() => {
  return !!currentIncidentToUpdate.value && hasValidEventStatus.value
})

onMounted(() => {
  incidentsRequirePointingPosition.init()
})

// methods
function handleBallPositionIndicatorClick(clickedPosition: {
  x: number
  y: number
}): void {
  saveIncidentPosition(currentIncidentToUpdate.value, clickedPosition)
}

// watchers
watch(
  open,
  () => {
    emit('open', open.value)
  },
  { immediate: true },
)
</script>

<template>
  <Overlay
    position="contained"
    :modelValue="open"
    scrim="none"
  >
    <BallPositionIndicator @click="handleBallPositionIndicatorClick">
      <div
        class="absolute left-0 top-0 w-full"
        :style="{ containerType: 'size' }"
      >
        <div class="w-full pt-6 text-center text-xl font-thin opacity-50">
          <div class="font-bold">
            {{ getIncidentName(currentIncidentToUpdate.incident_id) }}
          </div>
          <div>Point out the position on the field</div>
        </div>
      </div>
    </BallPositionIndicator>
  </Overlay>
</template>
