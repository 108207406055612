import type { ApiResponse } from '@collector/sportsapi-client-legacy'
import { Incident } from '@collector/sportsapi-types'

import { HttpClient } from '../../httpClient'

export type IncidentGetResponse = ApiResponse<{
  event_incidents: Incident[]
}>

export type { Incident }

export type IncidentsGetQuery = {
  sort_type?: string
  sort_order?: 'asc' | 'desc'
  limit?: 5 | 10 | 25 | 50 | 100 | 250 | 500 | 99999
}

export async function get(
  httpClient: HttpClient,
  eventId: number,
  query: IncidentsGetQuery = {},
): Promise<IncidentGetResponse> {
  return httpClient
    .url(`/events/${eventId}/incidents`)
    .query(query)
    .get()
    .json()
}
