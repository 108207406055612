import 'floating-vue/dist/style.css'

import { createApp } from 'vue'

// eslint-disable-next-line @nx/enforce-module-boundaries
import '../../../libs/shared/ui/src/style.css'

import App from './App.vue'
import router from './router'

import './style.css'

import { scaleLayout, trackNetworkStatus } from '@collector/shared-ui'

import { Database } from './utils/database'
import { getHandleError } from './utils/errorHandler'
import { initSentry } from './utils/sentry/runtime'

if (!import.meta.env.DEV) {
  // eslint-disable-next-line
  console.log(import.meta.env.VITE_RELEASE_NAME)
  trackNetworkStatus()
}

const app = createApp(App)

const handleError = getHandleError()

// handle sync errors
app.config.errorHandler = handleError

// async errors (rejected promise) not handled by Vue app
window.addEventListener('unhandledrejection', (event) => {
  // prevent default to skip logging "Uncaught (in promise)" in console
  event.preventDefault()

  handleError(event.reason)
})

initSentry(app, router)

app.use(router)
app.mount('#app')

Database.init()

scaleLayout(import.meta.env.VITE_APP_MODE)
