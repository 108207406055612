<script setup lang="ts">
import { computed, ref } from 'vue'

import { ParticipantName } from '@collector/shared-ui-domain'
import { CommonStatId, Participant } from '@collector/sportsapi-client-legacy'
import LineupsButton from '@desktop/views/Relation/components/RelationView/Main/Summary/Lineups/LineupsButton.vue'
import SkinsButton from '@desktop/views/Relation/components/RelationView/Main/Summary/Skins/SkinsButton.vue'
import SkinsModal from '@desktop/views/Relation/components/RelationView/Main/Summary/Skins/SkinsModal.vue'
import { useParticipants } from '@desktop/views/Relation/composables'

import FormationsButton from './Formations/FormationsButton.vue'
import FormationsModal from './Formations/FormationsModal/FormationsModal.vue'
import LineupsModal from './Lineups/LineupsModal.vue'

interface Props {
  participant: Participant
  reversed?: boolean
}

const props = defineProps<Props>()

// state
const isLineupsModalVisible = ref(false)
const isSkinsModalVisible = ref(false)
const isFormationsModalVisible = ref(false)

// composables
const { getParticipantStatsMap } = useParticipants()

const participantStatsMap = getParticipantStatsMap(props.participant)

// computed
// on non-development environment, this should always be true
// (but there could be rare cases when CommonStatId.SkinPattern is not available)
const skinsButtonEnabled = computed(() => {
  return participantStatsMap.value[CommonStatId.SkinPattern]
})
</script>

<template>
  <div class="flex w-full flex-col">
    <div
      class="flex flex-1 gap-2"
      :class="{ 'flex-row-reverse': reversed }"
    >
      <FormationsButton
        :participant
        @click="isFormationsModalVisible = true"
      />
      <SkinsButton
        :disabled="!skinsButtonEnabled"
        @click="isSkinsModalVisible = true"
      />
      <LineupsButton @click="isLineupsModalVisible = true" />
      <ParticipantName
        class="@md:text-2xl @lg:text-xl @xl:text-2xl line-clamp-2 flex-1 px-2 pt-1 text-xl font-bold"
        :class="{
          'text-right': !reversed,
        }"
        :withTeamColors="false"
        :participant
      />
    </div>
  </div>
  <LineupsModal
    v-if="isLineupsModalVisible"
    :participant
    @close="isLineupsModalVisible = false"
  />
  <SkinsModal
    v-if="isSkinsModalVisible"
    :participant
    @close="isSkinsModalVisible = false"
  />
  <FormationsModal
    v-if="isFormationsModalVisible"
    :participant
    @close="isFormationsModalVisible = false"
  />
</template>
