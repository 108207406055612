<script setup lang="ts">
import { ref } from 'vue'

import { TextInput } from '@collector/shared-ui'
import { SubParticipantPosition } from '@collector/sportsapi-client-legacy'

import {
  injectAddPlayer,
  injectCanAddPlayer,
} from '../provide-inject/subParticipant'
import ShirtNrTextInput from '../ShirtNrTextInput.vue'
import SubParticipantButton from '../SubParticipantButton.vue'
import { Player } from '../types'
import { useNewPlayerConfirmModal } from './useNewPlayerConfirmModal'
import { subParticipantPositions } from './utils'

// inject
const { canAddSubParticipant, canAddSubParticipantTitle } = injectCanAddPlayer()
const addPlayer = injectAddPlayer()

// composables
const { canCreateNewPlayer } = useNewPlayerConfirmModal()

// state
const shirtNr = ref('')
const playerShortName = ref('')

// methods
async function confirmAddNewPlayer(
  subParticipantPosition: SubParticipantPosition,
): Promise<void> {
  if (await canCreateNewPlayer(shirtNr.value, subParticipantPosition)) {
    addPlayer(
      {
        shirt_nr: shirtNr.value,
        short_name: playerShortName.value,
      } as Player, // TODO: avoid casting, improve types - define type accepting new/existing player with minimal payload
      subParticipantPosition,
    )

    shirtNr.value = ''
    playerShortName.value = ''
  }
}
</script>

<template>
  <ShirtNrTextInput
    v-model="shirtNr"
    :withValidationStyle="false"
  />
  <div class="w-full">
    <TextInput
      v-model="playerShortName"
      placeholder="Player name"
      :maxlength="20"
      variant="light"
      width="full"
      inputClass="px-2"
    />
    <div class="text-2xs text-neutral-dark-3/60 mt-0.5">Max 20 characters</div>
  </div>

  <SubParticipantButton
    v-for="subParticipantPosition in subParticipantPositions"
    :key="subParticipantPosition"
    :subParticipantPosition
    :disabled="
      !playerShortName ||
      !canAddSubParticipant(subParticipantPosition, {
        shortName: playerShortName,
        shirtNr,
      })
    "
    :tooltip="
      canAddSubParticipantTitle(subParticipantPosition, {
        shortName: playerShortName,
        shirtNr,
      })
    "
    @click="confirmAddNewPlayer(subParticipantPosition)"
  />
</template>
