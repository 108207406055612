import memoizee from 'memoizee'
import * as R from 'ramda'
import { computed, ComputedRef, Ref } from 'vue'

import {
  Detail,
  Event,
  EventDetailId,
} from '@collector/sportsapi-client-legacy'

import { injectEvent, injectEventTimestamp } from '../provide-inject'
import { SyncedTimestamp } from '../utils'

const memoizedGetEventDetailsMap = memoizee(function (
  event: Ref<Event>,
): ComputedRef<Record<EventDetailId, Detail>> {
  return computed(() =>
    R.indexBy<Detail, EventDetailId>(R.prop('id'), event.value.details),
  )
})

type UseEvent = {
  event: Ref<Event>
  eventTimestamp: Ref<SyncedTimestamp>
  eventDetailsMap: ComputedRef<Record<EventDetailId, Detail>>
}

export function useEvent(): UseEvent {
  const event = injectEvent()
  const eventTimestamp = injectEventTimestamp()
  const eventDetailsMap = memoizedGetEventDetailsMap(event)

  return {
    event,
    eventTimestamp,
    eventDetailsMap,
  }
}
