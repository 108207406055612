import * as R from 'ramda'
import { computed, Ref, ref } from 'vue'

import { EventIncidentsQueue } from '@collector/desktop-feature-incidents-queue-core'
import { Events, Incident } from '@collector/sportsapi-client'
import {
  Competition,
  Event,
  EventDetailId,
  EventScout,
  EventStatusId,
  IncidentId,
  Participant,
  Season,
  Sport,
  SportDetails,
  SportIncident,
  SportStatus,
  SubParticipant,
} from '@collector/sportsapi-client-legacy'
import { useAuth } from '@desktop/globalState/userAuth/useAuth'
import { EventSettingsRecord } from '@desktop/utils/database'

import {
  provideCompetition,
  provideEvent,
  provideEventDetailIdToSportDetailsMap,
  provideEventIncidentsQueue,
  provideEventScouts,
  provideEventSettings,
  provideEventStatusToSportStatusMap,
  provideEventTimestamp,
  provideIncidentAttributeIdToAttributeMap,
  provideIncidentIdIncidentIndexMap,
  provideIncidentIdToSportIncidentMap,
  provideIncidents,
  provideIncidentsUpdate,
  provideSeason,
  provideSport,
  provideSubParticipants,
  provideSwappableIncidents,
  provideUserIncidentsUpdate,
} from '.'
import { useParticipantsQuery } from '../composables/queries'
import {
  getIncidentAttributeIdToAttributeMap,
  initQueueRabbitClient,
  SyncedTimestamp,
} from '../utils'

type Params = {
  competition: Competition
  event: Event
  eventIncidentsQueue: EventIncidentsQueue
  eventTimestamp: SyncedTimestamp
  eventScouts: EventScout[]
  eventSettings: EventSettingsRecord
  incidents: Incident[]
  season: Season
  sport: Sport
  subParticipants: SubParticipant[]
  swappableIncidents: Events.SwappableIncidents.SwappableIncidentsConfigurationMap
}

type QueryRefs = {
  participants: Ref<Participant[]>
}

export async function initQueries(eventId: number): Promise<QueryRefs> {
  const participants = ref() as Ref<Participant[]>
  await useParticipantsQuery(eventId, participants).suspense()

  return { participants }
}

/**
 * Vue provide/inject - provide dependencies for all sports
 *
 * You cant use async/await here - Vue will show warning and won't resolve value provided with inject
 */
export function provideAndInitRelation(params: Params, refs: QueryRefs): void {
  const event = ref(params.event)
  const eventTimestamp = ref(params.eventTimestamp)
  const eventScouts = ref(params.eventScouts)
  const incidents = ref(params.incidents)
  const subParticipants = ref(params.subParticipants)

  const { authInfo } = useAuth()

  // queue-gateway connection
  const {
    onQueueRabbitConfirmIncident,
    incidentsUpdate,
    incidentIdIncidentIndexMap,
  } = initQueueRabbitClient(
    event,
    eventTimestamp,
    refs.participants,
    subParticipants,
    incidents,
    eventScouts,
  )

  // incidents queue
  params.eventIncidentsQueue.setConfirmQueueIncident(
    onQueueRabbitConfirmIncident,
  )
  provideEventIncidentsQueue(params.eventIncidentsQueue)

  // provide relation dependencies
  provideCompetition(params.competition)
  provideEvent(event)
  provideEventTimestamp(eventTimestamp)
  provideEventScouts(eventScouts)
  provideEventSettings(ref(params.eventSettings))
  provideSeason(params.season)
  provideSport(params.sport)
  provideSubParticipants(subParticipants)
  provideSwappableIncidents(params.swappableIncidents)
  provideIncidents(incidents)
  provideIncidentsUpdate(incidentsUpdate)
  provideUserIncidentsUpdate(
    computed(() =>
      incidentsUpdate.value.filter(
        ({ source }) => source === authInfo.value?.id,
      ),
    ),
  )
  provideIncidentIdIncidentIndexMap(incidentIdIncidentIndexMap)

  // provide sport maps
  provideIncidentIdToSportIncidentMap(
    R.indexBy<SportIncident, IncidentId>(R.prop('id'), params.sport.incidents),
  )
  provideEventStatusToSportStatusMap(
    R.indexBy<SportStatus, EventStatusId>(R.prop('id'), params.sport.statuses),
  )
  provideEventDetailIdToSportDetailsMap(
    R.indexBy<SportDetails, EventDetailId>(R.prop('id'), params.sport.details),
  )
  provideIncidentAttributeIdToAttributeMap(
    getIncidentAttributeIdToAttributeMap(params.sport.incidents),
  )
}
