import { computed, ComputedRef } from 'vue'

import { Participant } from '@collector/sportsapi-client-legacy'
import {
  useEventSettings,
  useParticipants,
} from '@desktop/views/Relation/composables'

type UseParticipantInPossession = {
  participantInPossession: ComputedRef<Participant | null>
}

export function useParticipantInPossession(): UseParticipantInPossession {
  // composables
  const { homeParticipant, awayParticipant } = useParticipants()
  const { eventSettings } = useEventSettings()

  // computed
  const participantInPossession = computed(() => {
    switch (eventSettings.value.teamSidePossession) {
      case 'home':
        return homeParticipant.value
      case 'away':
        return awayParticipant.value
      default:
        return null
    }
  })

  return {
    participantInPossession,
  }
}
