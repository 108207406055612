import { Database } from '..'
import { upsertTableItem } from '../utils'

export type IncidentsRequirePointingPositionRecord = {
  eventId?: number
  incidents?: number[]
}

export async function upsert(
  eventId: number,
  incidentId: number,
): Promise<void> {
  const database = Database.get()
  const table = Database.Tables.INCIDENTS_REQUIRE_POINTING_POSITION

  await database.transaction('rw', table, async () => {
    const eventIncidentsRecord = await get(eventId)
    const currentIncidents = eventIncidentsRecord?.incidents ?? []
    const newIncidentsValue = [...new Set([...currentIncidents, incidentId])]

    return upsertTableItem<
      Omit<IncidentsRequirePointingPositionRecord, 'eventId'>
    >(database, table, eventId, {
      incidents: newIncidentsValue,
    })
  })
}

export async function deleteIncident(
  eventId: number,
  incidentId: number,
): Promise<void> {
  const database = Database.get()
  const table = Database.Tables.INCIDENTS_REQUIRE_POINTING_POSITION

  await database.transaction('rw', table, async () => {
    const eventIncidentsRecord = await get(eventId)
    const currentIncidents = eventIncidentsRecord?.incidents ?? []
    const newIncidentsValue = currentIncidents.filter(
      (currentIncidentId) => currentIncidentId !== incidentId,
    )

    return upsertTableItem<
      Omit<IncidentsRequirePointingPositionRecord, 'eventId'>
    >(database, table, eventId, {
      incidents: newIncidentsValue,
    })
  })
}

export function get(
  eventId: number,
): Promise<IncidentsRequirePointingPositionRecord | undefined> {
  const database = Database.get()
  const table = database.table(
    Database.Tables.INCIDENTS_REQUIRE_POINTING_POSITION,
  )

  return table.get(eventId)
}
