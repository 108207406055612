<script setup lang="ts">
import { onBeforeMount, onMounted, onUnmounted, ref } from 'vue'

import { CancelModalError } from '../../composables'
import { getHandlePressed, isPressedEscape } from '../../utils'
import Button from '../Button/Button.vue'
import { modalContainerId } from './consts'

interface Props {
  title?: string
  withFadeInAnimation?: boolean
  withCloseIcon?: boolean
  withCloseShortcuts?: boolean
  confirmButton?: {
    text: string
    confirm: () => Promise<void> | void
    disabled?: boolean
    tooltip?: string
  }
  cancelButton?: {
    text?: string
    cancel?: () => void
  }
}

const props = withDefaults(defineProps<Props>(), {
  withCloseIcon: true,
  withCloseShortcuts: true,
  withFadeInAnimation: true,
  teleportTo: '#modal_container',
})

type Emits = { close: [] }
const emit = defineEmits<Emits>()

// data
const cancelButtonText = props.cancelButton?.text || 'Cancel'

const modalContentOffset = 32 // 4rem * 2

// state
const confirmed = ref(false) // if true, the confirm button should be disabled to prevent multiple clicks
const modalContentRef = ref<HTMLDivElement>()
const shouldModalContentStickToTop = ref(false)

const modalBackgroundTopPosition = ref(0)
const modalBackgroundRef = ref<HTMLDivElement>()

// methods
function onCancelClick(): void {
  if (props.cancelButton?.cancel) {
    props.cancelButton?.cancel()
  }

  closeModal()
}

function closeModal(): void {
  emit('close')
}

async function confirm(): Promise<void> {
  confirmed.value = true

  try {
    await props.confirmButton?.confirm()

    closeModal()
  } catch (error) {
    if (error instanceof CancelModalError === false) {
      throw error
    }
  } finally {
    confirmed.value = false
  }
}

function updateShouldStickToTop(): void {
  if (!modalContentRef.value) {
    return
  }

  shouldModalContentStickToTop.value =
    window.innerHeight < modalContentRef.value.clientHeight + modalContentOffset
}

function calculateBackgroundTopPosition(): void {
  const modalHeight = modalBackgroundRef.value?.clientHeight || 0
  const bodyHeight = document.body.clientHeight
  const scrollY = window.scrollY

  modalBackgroundTopPosition.value = Math.min(scrollY, bodyHeight - modalHeight)
}

const closeModalOnEscape = getHandlePressed(isPressedEscape, closeModal)

// init
onBeforeMount(() => {
  // unfocus last clicked element which triggered modal, to prevent opening another modal when pressing Enter
  ;(document.activeElement as HTMLElement)?.blur()
})

onMounted(() => {
  const scrollBarWidth = window.innerWidth - document.body.offsetWidth

  document.body.classList.add('overflow-hidden')
  document.body.style.marginRight = scrollBarWidth + 'px'

  if (props.withCloseShortcuts) {
    document.addEventListener('keydown', closeModalOnEscape)
  }

  // Firefox positioning fixes
  updateShouldStickToTop()
  window.addEventListener('resize', updateShouldStickToTop)
  window.addEventListener('scroll', calculateBackgroundTopPosition)
  window.addEventListener('resize', calculateBackgroundTopPosition)

  calculateBackgroundTopPosition()
})

onUnmounted(() => {
  document.body.classList.remove('overflow-hidden')
  document.body.style.marginRight = 0 + 'px'

  if (props.withCloseShortcuts) {
    document.removeEventListener('keydown', closeModalOnEscape)
  }

  window.removeEventListener('resize', updateShouldStickToTop)
  window.removeEventListener('scroll', calculateBackgroundTopPosition)
  window.removeEventListener('resize', calculateBackgroundTopPosition)
})
</script>

<template>
  <Teleport :to="`#${modalContainerId}`">
    <div
      ref="modalBackgroundRef"
      class="bg-neutral-dark-3/35 absolute z-20 h-screen w-screen"
      :class="{
        'fade-in-animation': withFadeInAnimation,
      }"
      :style="{ top: modalBackgroundTopPosition + 'px' }"
    >
      <div
        class="grid h-full w-full overflow-y-scroll p-4"
        :style="{
          placeContent: shouldModalContentStickToTop
            ? 'start space-around'
            : 'space-around',
        }"
        @click.self="withCloseShortcuts ? closeModal() : undefined"
      >
        <div
          ref="modalContentRef"
          class="bg-neutral-light-9 text-gray shadow-drop-shadow rounded-2xl p-4"
        >
          <div
            v-if="$slots.title || title || withCloseIcon"
            class="mb-4 flex min-h-7 justify-end"
          >
            <span class="flex-1">
              <span
                v-if="title"
                class="text-lg font-bold"
              >
                {{ title }}
              </span>
              <slot name="title" />
            </span>
            <Button
              v-if="withCloseIcon"
              class="text-neutral-dark-3/60 hover:text-neutral-2 border-none pl-2 text-lg"
              icon="close"
              iconSize="lg"
              size="xs"
              square
              @click="closeModal"
            />
          </div>
          <slot />
          <div class="flex justify-end gap-4 pt-4">
            <Button
              class="text-neutral-dark-2 bg-neutral-light-10 hover:bg-neutral-light-4 hover:border-neutral-dark-2 w-28 text-lg font-bold"
              size="lg"
              @click="onCancelClick"
            >
              {{ cancelButtonText }}
            </Button>
            <Button
              v-if="confirmButton"
              class="min-w-28 text-lg font-bold"
              :class="{
                'text-neutral-light-10 border-success-5 bg-success-5 hover:bg-success-5/60 hover:border-transparent':
                  !confirmed && !confirmButton.disabled,
              }"
              size="lg"
              :disabled="confirmed || confirmButton.disabled"
              :title="confirmButton.tooltip"
              @click="confirm"
            >
              {{ confirmButton.text }}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style scoped>
.fade-in-animation {
  animation: fadeIn 100ms ease-in;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
