export enum ShotBlockedAttribute {
  InsidePenaltyArea = 440,
  OutsidePenaltyArea = 441,
  LeftFoot = 442,
  RightFoot = 443,
  Head = 444,
  Volley = 853,
  BicycleKick = 854,
  Chip = 855,
}
