import { Ref } from 'vue'

import { QueueRabbitClient } from '@collector/queue-gateway-shared-client'
import { RabbitEventsLineupsMessage } from '@collector/queue-gateway-shared-types'
import { SubParticipant } from '@collector/sportsapi-client-legacy'

import { fetchSubParticipants } from '../../dependencies'

async function updateSubParticipants(
  subParticipants: Ref<SubParticipant[]>,
  message: RabbitEventsLineupsMessage,
): Promise<void> {
  const subParticipantsFromApi = await fetchSubParticipants(message.data.id)
  if (!import.meta.env.DEV) {
    // eslint-disable-next-line
    console.debug('[Update] QG SubParticipants', message.data.lineups)
    // eslint-disable-next-line
    console.debug('[Update] API SubParticipants', subParticipantsFromApi)
  }

  subParticipants.value = subParticipantsFromApi
}

export function updateSubParticipantsOnConnect(
  queueRabbitClient: QueueRabbitClient,
  subParticipants: Ref<SubParticipant[]>,
): void {
  queueRabbitClient.subLineups<RabbitEventsLineupsMessage>({
    onMessage: (message) => {
      updateSubParticipants(subParticipants, message)
    },
  })
}
