<script setup lang="ts">
import * as R from 'ramda'
import { computed, ref } from 'vue'

import { Modal, runLoader, useConfirmModal } from '@collector/shared-ui'
import { Participants } from '@collector/sportsapi-client'
import {
  Participant,
  SubParticipant,
  SubParticipantPosition,
} from '@collector/sportsapi-client-legacy'
import { useEvent, useSeason } from '@desktop/views/Relation/composables'
import { fetchSubParticipantsForParticipant } from '@desktop/views/Relation/utils'

import { useAddPosition } from './composables/useAddPosition'
import { useSubParticipantsByPositionSorted } from './composables/useSubParticipantsByPositionSorted'
import InvalidAssignedPlayersConfirmModal from './ConfirmModals/InvalidAssignedPlayersConfirmModal.vue'
import NoCaptainConfirmModal from './ConfirmModals/NoCaptainConfirmModal.vue'
import NoCoachConfirmModal from './ConfirmModals/NoCoachConfirmModal.vue'
import NoGoalkeeperConfirmModal from './ConfirmModals/NoGoalkeeperConfirmModal.vue'
import CaptainChip from './LineupPlayers/CaptainChip.vue'
import LineupPlayerRows from './LineupPlayers/LineupPlayerRows.vue'
import LineupPlayers from './LineupPlayers/LineupPlayers.vue'
import NoPlayers from './LineupPlayers/NoPlayers.vue'
import ListTitle from './ListTitle.vue'
import ModalTitle from './ModalTitle.vue'
import NewPlayer from './NewPlayer/NewPlayer.vue'
import ClearProbableLineupOverlay from './Overlays/ClearProbableLineupOverlay.vue'
import PlayerName from './PlayerName.vue'
import {
  provideAddPlayer,
  provideCanAddPlayer,
} from './provide-inject/subParticipant'
import ShirtNrTextInput from './ShirtNrTextInput.vue'
import SubParticipantButton from './SubParticipantButton.vue'
import { Player } from './types'
import {
  fetchParticipantSquadBySeasonId,
  findPlayer,
  isCaptain,
  isPlayerSame,
  setCaptainStat,
  toSortedBySurname,
  updateLineups,
} from './utils'

interface Props {
  participant: Participant
}
const props = defineProps<Props>()

type Emits = { close: [] }
const emit = defineEmits<Emits>()

// state
const maxFirstLineupCount = 11

const subParticipants = ref<Player[]>([])
const initialSubParticipants = ref<Player[]>([])

const seasonSquadSubParticipants = ref<
  Participants.Squad.SeasonSquadParticipant[]
>([])

// composables
const { event } = useEvent()
const { season } = useSeason()
const { openModalForConfirm } = useConfirmModal()
const {
  goalkeeperSubParticipants,
  firstLineupSubParticipants,
  benchSubParticipants,
  coachSubParticipants,
  willNotPlaySubParticipants,
} = useSubParticipantsByPositionSorted(subParticipants)

const {
  canAddGoalKeeper,
  canAddGoalKeeperTitle,
  canAddFirstLineup,
  canAddFirstLineupTitle,
  canAddCoach,
  canAddCoachTitle,
  canAddSubParticipant,
  canAddSubParticipantTitle,
} = useAddPosition({
  seasonSquadSubParticipants,
  goalkeeperSubParticipants,
  firstLineupSubParticipants,
  benchSubParticipants,
  coachSubParticipants,
  maxFirstLineupCount,
})

// computed
const availableSeasonSquadSubParticipants = computed(() =>
  toSortedBySurname(
    R.differenceWith(
      (s1, s2) => s1.id === s2.id,
      seasonSquadSubParticipants.value,
      subParticipants.value,
    ),
  ),
)

const captainSubParticipant = computed(() =>
  subParticipants.value.find(isCaptain),
)

const goalkeeperFirstLineupCount = computed(
  () =>
    goalkeeperSubParticipants.value.length +
    firstLineupSubParticipants.value.length,
)

const hasChanges = computed(() => {
  const sameLength =
    initialSubParticipants.value.length === subParticipants.value.length
  if (!sameLength) return true

  for (const player of subParticipants.value) {
    const initialPlayer = findPlayer(initialSubParticipants.value, player)
    const changed = !R.equals(initialPlayer, player)
    if (changed) return true
  }

  return false
})

async function fetchSeasonSquad(): Promise<void> {
  seasonSquadSubParticipants.value = await fetchParticipantSquadBySeasonId(
    props.participant.id,
    season.id,
  )
}

async function fetchLineups(): Promise<void> {
  const result = await fetchSubParticipantsForParticipant(
    event.value.id,
    props.participant.id,
  )
  subParticipants.value = result
  initialSubParticipants.value = R.clone(result)
}

function addPlayer(player: Player, position: SubParticipantPosition): void {
  const existingInitial = R.clone(
    initialSubParticipants.value.find((v) => isPlayerSame(v, player)),
  )
  const playerToAdd = existingInitial ?? player

  playerToAdd.position = position
  setCaptainStat(playerToAdd, 0)

  subParticipants.value.push(playerToAdd)
}

function removePlayer(player: Player): void {
  subParticipants.value
    .splice(subParticipants.value.indexOf(player as SubParticipant), 1)
    .forEach((subParticipant) => {
      setCaptainStat(subParticipant, 0)
      delete subParticipant.position
    })
}

function setCaptain(player: Player): void {
  const currentCaptain = subParticipants.value.find(isCaptain)
  if (currentCaptain) setCaptainStat(currentCaptain, 0)
  setCaptainStat(player, 1)
}

async function confirmUpdateLineups(): Promise<void> {
  if (goalkeeperSubParticipants.value.length === 0) {
    await openModalForConfirm(NoGoalkeeperConfirmModal)
  }

  if (goalkeeperFirstLineupCount.value !== maxFirstLineupCount) {
    await openModalForConfirm(InvalidAssignedPlayersConfirmModal)
  }

  if (!captainSubParticipant.value) {
    await openModalForConfirm(NoCaptainConfirmModal)
  }

  if (coachSubParticipants.value.length === 0) {
    await openModalForConfirm(NoCoachConfirmModal)
  }

  await runLoader(
    updateLineups(event.value.id, props.participant.id, subParticipants.value),
  )
}

await runLoader(Promise.all([fetchSeasonSquad(), fetchLineups()]))

// provide
provideCanAddPlayer({ canAddSubParticipant, canAddSubParticipantTitle })
provideAddPlayer(addPlayer)
</script>

<template>
  <Modal
    :confirmButton="{
      text: 'Save',
      confirm: confirmUpdateLineups,
      disabled: !hasChanges,
    }"
    :withCloseIcon="false"
    :withCloseShortcuts="false"
    @close="emit('close')"
  >
    <template #title>
      <ModalTitle :participant />
    </template>

    <NewPlayer class="mb-4" />

    <div
      class="@sm:w-[77vw] @lg:w-[49.125rem] relative flex h-[30rem] w-full gap-x-2 text-sm"
    >
      <div class="relative flex h-[30rem] w-full flex-col gap-y-1">
        <div class="relative space-y-1 overflow-auto">
          <ListTitle
            title="Season squad"
            light
          />
          <template v-if="availableSeasonSquadSubParticipants.length">
            <div
              v-for="seasonSquadParticipant in availableSeasonSquadSubParticipants"
              :key="seasonSquadParticipant.id"
              class="bg-neutral-light-10 @sm:flex-nowrap flex flex-wrap items-center gap-1 rounded px-2 py-1"
            >
              <PlayerName
                class="flex pr-2"
                :player="seasonSquadParticipant"
              >
                <div class="w-[1.25rem] text-left">
                  {{ seasonSquadParticipant.shirt_nr }}
                </div>
              </PlayerName>
              <SubParticipantButton
                :subParticipantPosition="SubParticipantPosition.Goalkeeper"
                :disabled="!canAddGoalKeeper"
                :tooltip="canAddGoalKeeperTitle"
                @click="
                  addPlayer(
                    seasonSquadParticipant,
                    SubParticipantPosition.Goalkeeper,
                  )
                "
              />
              <SubParticipantButton
                :subParticipantPosition="SubParticipantPosition.FirstLineup"
                :disabled="!canAddFirstLineup"
                :tooltip="canAddFirstLineupTitle"
                @click="
                  addPlayer(
                    seasonSquadParticipant,
                    SubParticipantPosition.FirstLineup,
                  )
                "
              />

              <SubParticipantButton
                :subParticipantPosition="SubParticipantPosition.Bench"
                @click="
                  addPlayer(
                    seasonSquadParticipant,
                    SubParticipantPosition.Bench,
                  )
                "
              />

              <SubParticipantButton
                :subParticipantPosition="SubParticipantPosition.Coach"
                :disabled="!canAddCoach"
                :tooltip="canAddCoachTitle"
                @click="
                  addPlayer(
                    seasonSquadParticipant,
                    SubParticipantPosition.Coach,
                  )
                "
              />
            </div>
          </template>
          <NoPlayers v-else>
            No season squad subparticipants available
          </NoPlayers>
        </div>
      </div>

      <div class="flex h-[30rem] w-full flex-col gap-y-1">
        <div class="space-y-4 overflow-auto">
          <div class="space-y-1">
            <ListTitle
              :title="`First lineup (${goalkeeperFirstLineupCount})`"
            />
            <NoPlayers
              v-if="goalkeeperFirstLineupCount === 0"
              title="First lineup"
            />

            <template v-else>
              <LineupPlayerRows
                :players="goalkeeperSubParticipants"
                @playerRemove="removePlayer"
              >
                <template #default="{ player }">
                  <ShirtNrTextInput
                    v-model="player.shirt_nr"
                    withValidationStyle
                  />
                  <CaptainChip
                    :player
                    @captainSet="setCaptain(player)"
                  />
                </template>
                <template #playerName>(GK)</template>
              </LineupPlayerRows>

              <LineupPlayerRows
                :players="firstLineupSubParticipants"
                @playerRemove="removePlayer"
              >
                <template #default="{ player }">
                  <ShirtNrTextInput
                    v-model="player.shirt_nr"
                    withValidationStyle
                  />
                  <CaptainChip
                    :player
                    @captainSet="setCaptain(player)"
                  />
                </template>
              </LineupPlayerRows>
            </template>
          </div>

          <LineupPlayers
            :players="benchSubParticipants"
            title="Bench"
            @playerRemove="removePlayer"
          >
            <template #default="{ player }">
              <ShirtNrTextInput
                v-model="player.shirt_nr"
                withValidationStyle
              />
            </template>
          </LineupPlayers>

          <LineupPlayers
            :players="coachSubParticipants"
            title="Coach"
            @playerRemove="removePlayer"
          />

          <LineupPlayers
            :players="willNotPlaySubParticipants"
            title="Will not play"
            @playerRemove="removePlayer"
          />
        </div>
      </div>
      <ClearProbableLineupOverlay
        :participant
        @clear="runLoader(fetchLineups())"
      />
    </div>
  </Modal>
</template>
