import { computed, Ref, ref, watch } from 'vue'

import { formatTimePart, getMinuteWithSecond } from '@collector/shared-utils'
import { ClockStatus, Event } from '@collector/sportsapi-client-legacy'

import { createTimer } from './timer'

export type UseEventClock = {
  eventTime: Ref<{ minute: number; second: number }>
  eventTimeFormatted: Ref<{ minute: string; second: string }>
  startClock: () => void
  stopClock: () => void
  resetClockTime(value?: number): void
  clockRunning: Ref<boolean>
}

export function useEventClock(
  event: Ref<Event>,
  eventLocalSyncTime: number,
  periodTime: Ref<number>,
): UseEventClock {
  const { startTimer: _startTimer, stopTimer } = createTimer()

  const clockRunning = ref<boolean>(
    event.value.clock_status === ClockStatus.Running,
  )

  const elapsedSeconds = ref(0)
  const localClockTime = ref(0)

  const eventTimeSeconds = computed(
    () => periodTime.value + localClockTime.value + elapsedSeconds.value,
  )
  const eventTime = computed(() => getMinuteWithSecond(eventTimeSeconds.value))
  const eventTimeFormatted = computed(() => ({
    minute: formatTimePart(eventTime.value.minute),
    second: formatTimePart(eventTime.value.second),
  }))

  function setLocalClockTime(value: number): void {
    localClockTime.value = value
  }

  function startTimer(): void {
    _startTimer((ticks) => {
      elapsedSeconds.value += ticks
    })
  }

  /**
   * When this composable is executed,
   * the event.clock_time (provided by the API) may already be a few seconds in the past relative to the time of execution.
   *
   * For example:
   * - Browser time: 10:00:00 (before requesting the API)
   * - API response: 10:00:01 (response includes eventTimestamp and clock_time)
   * - After fetching other asynchronous dependencies, this composable is executed at 10:00:03.
   *
   * This means that clock_time is 2 seconds behind the current time and must be adjusted accordingly.
   */
  function getEventClockDelay(): number {
    return clockRunning.value
      ? Math.round((Date.now() - eventLocalSyncTime) / 1000)
      : 0
  }

  function resetClockTime(value: number): void {
    setLocalClockTime(value)
    elapsedSeconds.value = 0

    if (clockRunning.value) {
      stopTimer()
      startTimer()
    }
  }

  function startClock(): void {
    clockRunning.value = true
    startTimer()
  }

  function stopClock(): void {
    clockRunning.value = false
    stopTimer()
  }

  function syncEventClockStatus(): void {
    const eventClockRunning = event.value.clock_status === ClockStatus.Running

    if (eventClockRunning !== clockRunning.value) {
      if (eventClockRunning) startClock()
      else stopClock()
    }
  }

  watch([periodTime, () => event.value.status_id], (newValue, oldValue) => {
    const [newPeriodTime, newEventStatusId] = newValue
    const [oldPeriodTime, oldEventStatusId] = oldValue

    if (newEventStatusId !== oldEventStatusId) {
      if (newPeriodTime !== oldPeriodTime) {
        // only if periodTime, event.status_id changed due to sending some incident affecting event status
        if (event.value.clock_status === ClockStatus.Stopped) {
          setLocalClockTime(event.value.clock_time!)
          elapsedSeconds.value = 0
        }
      }

      syncEventClockStatus()
    }
  })

  setLocalClockTime(event.value.clock_time! + getEventClockDelay())

  if (clockRunning.value) {
    startClock()
  }

  return {
    eventTime,
    eventTimeFormatted,
    startClock,
    stopClock,
    resetClockTime,
    clockRunning,
  }
}
