import * as R from 'ramda'
import { computed, ComputedRef, reactive } from 'vue'

import { MultiSelectObjectOption } from '@collector/shared-ui'
import { isIncidentDeleted } from '@collector/shared-ui-domain'
import { Confirmation } from '@collector/sportsapi-client-legacy'
import {
  useParticipants,
  useSportMaps,
  useSubParticipants,
} from '@desktop/views/Relation/composables'
import {
  useBetIncidents,
  useEventStatuses,
} from '@desktop/views/Relation/Sports/composables'
import { isIncidentIdsGroup } from '@desktop/views/Relation/Sports/configuration'
import {
  injectHistoryIncidentsConfig,
  injectIncidentsInFilter,
} from '@desktop/views/Relation/Sports/provide-inject'

import { isOptionMatched } from '../utils'
import {
  isIncidentEmpty as _isIncidentEmpty,
  isIncidentImportant as _isIncidentImportant,
  hasIncidentConfirmation,
  hasIncidentEventStatus,
  hasIncidentId,
  hasIncidentParticipantId,
  hasIncidentParticipantOrAssistant,
} from './filterFunctions'
import {
  FilterFunction,
  IncidentFilterSelect,
  IncidentFilterToggle,
} from './types'
import { getFilterFunctionsSelect, getFilterFunctionsToggle } from './utils'

type UseIncidentFilters = {
  incidentFiltersToggle: IncidentFilterToggle[]
  incidentFiltersSelect: IncidentFilterSelect[]
  activeFilterFunctions: ComputedRef<FilterFunction[]>
}

export function useIncidentFilters(): UseIncidentFilters {
  // composables
  const { incidentIdToSportIncidentMap, getIncidentName } = useSportMaps()
  const { isIncidentEmergencyOrBet } = useBetIncidents()
  const { homeParticipant, awayParticipant } = useParticipants()
  const { eventStatuses } = useEventStatuses()
  const { homePlayersOptions, awayPlayersOptions } = useSubParticipants()

  // inject
  const historyIncidentsConfig = injectHistoryIncidentsConfig()
  const incidentsInFilter = injectIncidentsInFilter()

  // data
  const incidentsInFilterOptions: MultiSelectObjectOption[] =
    incidentsInFilter.map((value) => ({
      name: isIncidentIdsGroup(value) ? value.name : getIncidentName(value),
      value: isIncidentIdsGroup(value) ? value.incidentIds : value,
    }))

  const isIncidentImportant = R.partialRight(_isIncidentImportant, [
    incidentIdToSportIncidentMap,
  ])

  const isIncidentEmpty = R.partialRight(_isIncidentEmpty, [
    historyIncidentsConfig,
  ])

  // state
  const incidentFiltersSelect: IncidentFilterSelect[] = [
    {
      name: 'Participant',
      filterFunction: hasIncidentParticipantId,
      options: computed(() => [
        {
          name: homeParticipant.value.short_name,
          value: homeParticipant.value.id,
        },
        {
          name: awayParticipant.value.short_name,
          value: awayParticipant.value.id,
        },
      ]),
    },
    {
      name: 'Person',
      withFilter: true,
      value: [],
      filterFunction: hasIncidentParticipantOrAssistant,
      filterPredicate: isOptionMatched,
      options: computed(() => [
        ...homePlayersOptions.value,
        ...awayPlayersOptions.value,
      ]),
    },
    {
      name: 'Incident',
      withFilter: true,
      value: [],
      filterFunction: hasIncidentId,
      options: incidentsInFilterOptions,
    },
    {
      name: 'Status',
      withFilter: true,
      value: [],
      filterFunction: hasIncidentEventStatus,
      options: eventStatuses,
    },
    {
      name: 'Confirmation',
      filterFunction: hasIncidentConfirmation,
      options: [
        {
          name: 'Tbd',
          value: Confirmation.ToBeDone,
        },
        {
          name: 'Confirmed',
          value: Confirmation.Confirmed,
        },
        {
          name: 'Cancelled',
          value: Confirmation.Cancelled,
        },
      ],
    },
  ].map(reactive)

  const incidentFiltersToggle: IncidentFilterToggle[] = [
    {
      name: 'important',
      filterFunction: isIncidentImportant,
    },
    {
      name: 'deleted',
      filterFunction: isIncidentDeleted,
    },
    {
      name: 'empty',
      filterFunction: isIncidentEmpty,
    },
    {
      name: 'emergency',
      filterFunction: isIncidentEmergencyOrBet,
    },
  ].map(reactive)

  // computed
  const activeFilterFunctions = computed(() => [
    ...getFilterFunctionsToggle(incidentFiltersToggle),
    ...getFilterFunctionsSelect(incidentFiltersSelect),
  ])

  return {
    incidentFiltersSelect,
    incidentFiltersToggle,
    activeFilterFunctions,
  }
}
