<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import { TextInput } from '@collector/shared-ui'
import { getEventTime, isIncidentDeleted } from '@collector/shared-ui-domain'
import { formatTimePart } from '@collector/shared-utils'
import { Incident } from '@collector/sportsapi-client'
import {
  useBetIncidents,
  useEventIncidentsQueue,
} from '@desktop/views/Relation/Sports/composables'
import { OnClickOutside } from '@vueuse/components'

interface Props {
  incident: Incident
  groupedIncident?: Incident
  isDeleted: boolean
}

const props = defineProps<Props>()

// composables
const eventIncidentsQueue = useEventIncidentsQueue()
const { hasIncidentMasterAttribute } = useBetIncidents()

// state
const editing = ref(false)
const newMinute = ref<number | null>(null)
const newSecond = ref<number | null>(null)

// methods
function onFocus({ event }: { event: FocusEvent }): void {
  if (isEditable.value) {
    if (!editing.value) {
      editing.value = true

      newMinute.value = props.incident.minute
      newSecond.value = props.incident.second
    }

    const inputElement = event.target as HTMLInputElement
    inputElement.select()
  }
}

function onClickOutside(): void {
  if (editing.value) {
    updateEventTime()
  }
}

function formatTime(value: number | null): string | null {
  return value === null ? null : formatTimePart(value)
}

function isValidNewMinute(): boolean {
  // API allows maximum 255 minutes
  return (
    newMinute.value !== null && newMinute.value >= 0 && newMinute.value <= 255
  )
}

function isValidNewSecond(): boolean {
  return (
    newSecond.value !== null && newSecond.value >= 0 && newSecond.value <= 59
  )
}

function updateEventTime(): void {
  if (
    isValidNewMinute() &&
    isValidNewSecond() &&
    (props.incident.minute !== newMinute.value ||
      props.incident.second !== newSecond.value)
  ) {
    const payload = {
      minute: newMinute.value,
      second: newSecond.value,
    }

    if (!isIncidentDeleted(props.incident)) {
      eventIncidentsQueue.updateIncident(payload, props.incident)
    }

    if (props.groupedIncident && !isIncidentDeleted(props.groupedIncident)) {
      eventIncidentsQueue.updateIncident(payload, props.groupedIncident)
    }
  }

  editing.value = false
}

// computed
const isEditable = computed(
  () =>
    !props.isDeleted &&
    props.incident.category !== 'emergency' &&
    !hasIncidentMasterAttribute(props.incident),
)

const eventTime = computed(() => getEventTime(props.incident))

const inputClass = computed(() => {
  const opacity = editing.value ? '' : 'opacity-0'
  const cursor = !editing.value && isEditable.value ? 'cursor-pointer' : ''

  return [opacity, cursor].join(' ')
})

// watch
watch(
  () => props.isDeleted,
  (newVal) => {
    if (newVal) {
      editing.value = false
    }
  },
)
</script>

<template>
  <div class="flex justify-end">
    <div
      class="relative flex h-6 w-[3.5rem] border-transparent"
      :class="{
        'hover:border-neutral-dark-3/10 hover:bg-neutral-light-10 border hover:rounded':
          !editing && isEditable,
      }"
    >
      <div
        v-show="!editing"
        class="pointer-events-none absolute flex h-full w-full items-center justify-end pr-0.5 text-sm font-bold"
        :class="{
          'text-neutral-dark-3/40': isDeleted,
        }"
      >
        {{ eventTime }}
      </div>
      <OnClickOutside
        v-if="isEditable"
        class="flex"
        @trigger="onClickOutside"
      >
        <TextInput
          :modelValue="formatTime(incident.minute)"
          size="xs"
          numeric
          :maxlength="3"
          :inputClass="`text-right w-7 pr-0.25 px-1 ${inputClass}`"
          @update:modelValue="newMinute = parseInt($event as string)"
          @keyup.enter="updateEventTime"
          @focus="onFocus"
        />
        <div
          class="relative -top-[0.06rem] w-1 text-center"
          :class="{
            invisible: !editing,
          }"
        >
          :
        </div>
        <TextInput
          :modelValue="formatTime(incident.second)"
          size="xs"
          numeric
          :maxlength="2"
          :inputClass="`w-6 text-right pr-0.75 h-6 px-1 ${inputClass}`"
          @update:modelValue="newSecond = parseInt($event as string)"
          @keyup.enter="updateEventTime"
          @focus="onFocus"
        />
      </OnClickOutside>
    </div>
  </div>
</template>
