import { Extras } from '@sentry/core'

const key = 'issueTitle'

type Options = {
  /** append page title set on Sentry context */
  withContextPageTitle: boolean
}

type Value = {
  value: string
  options?: Options
}

export function createIssueTitleExtra(
  value: string,
  options?: Options,
): Extras {
  return {
    extra: {
      [key]: {
        value,
        options,
      },
    },
  }
}

export function deleteIssueTitleExtra(extra: Extras): void {
  delete extra[key]
}

export function hasIssueTitleExtra(
  extra: Extras | undefined,
): extra is { [key]: Value } {
  return !!extra?.[key]
}
