<script lang="ts" setup>
import { useModalStack } from '../../composables'
import { modalContainerId } from './consts'

const modalStack = useModalStack()
</script>

<template>
  <div :id="modalContainerId">
    <component
      :is="modalComponent"
      v-for="(modalComponent, index) in modalStack"
      :key="`opened-modal-${index}`"
    />
  </div>
</template>
