import { App } from 'vue'
import { Router } from 'vue-router'

import Sentry, {
  getDefaultIntegrations,
  groupWithCustomNamedIssue,
} from '@collector/shared-plugin-sentry-vue'
import { SportsApiEndpointsWithoutLoginPattern } from '@collector/sportsapi-types'
import { Env } from '@desktop/envs'
import { BaseTransportOptions, Client, ClientOptions } from '@sentry/core'

export const initSentry = (
  app: App<Element>,
  router: Router,
): Client<ClientOptions<BaseTransportOptions>> | undefined => {
  return Sentry.init({
    app,
    enabled: Env.VITE_SENTRY_ENABLED === 'true',
    environment: Env.VITE_SENTRY_ENVIRONMENT,
    dsn: Env.VITE_SENTRY_DSN,
    release: Env.VITE_RELEASE_NAME,
    integrations: getDefaultIntegrations({
      router,
      failedRequestStatusCodes: [400, [402, 599]],
      failedRequestTargets: [SportsApiEndpointsWithoutLoginPattern],
      networkDetailAllowUrlsPattern: [SportsApiEndpointsWithoutLoginPattern],
    }),
    tracesSampleRate: 1,
    tracePropagationTargets: [import.meta.env.VITE_QUEUE_GATEWAY_URL],
    attachErrorHandler: true,
    beforeSend: (event) => {
      event.transaction = window.location.pathname

      groupWithCustomNamedIssue(event)

      return event
    },
    urlsWithTrackedPayload: SportsApiEndpointsWithoutLoginPattern,
  })
}
