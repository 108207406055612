<script setup lang="ts">
import { Button } from '@collector/shared-ui'
import { TeamSide } from '@collector/shared-ui-domain'
import { IncidentId } from '@collector/sportsapi-client-legacy'

import { useSportMaps } from '../composables'
import SportIncidentIcon from './SportIncidentIcon.vue'

type ButtonProps = InstanceType<typeof Button>['$props']

interface Props {
  incidentId: IncidentId
  teamSide?: TeamSide | null
  size: 'md' /** 40px */ | 'lg' /** 48px */
  disabled?: boolean
  width?: ButtonProps['width']
  selected?: boolean
  withActive?: boolean
  customIconPath?: string
}

const props = defineProps<Props>()

// emits
type Emits = {
  click: [incidentId: IncidentId]
}

defineEmits<Emits>()

// composables
const { getIncidentName } = useSportMaps()

// data
const incidentName = getIncidentName(props.incidentId)
</script>

<template>
  <Button
    class="disabled:text-neutral-dark-2/50 font-bold"
    :class="{
      'text-neutral-light-9': selected,
      'text-neutral-dark-2': !selected,

      // home
      'border-current-team-home-5 hover:bg-current-team-home-5':
        teamSide === 'home',
      'active:bg-current-team-home-5/15': teamSide === 'home' && withActive,
      'bg-current-team-home-5/15': teamSide === 'home' && !selected,
      'bg-current-team-home-5': teamSide === 'home' && selected,
      'disabled:bg-current-team-home-7/10 disabled:border-current-team-home-9':
        teamSide === 'home' && disabled,
      //
      // away
      'border-current-team-away-5 hover:bg-current-team-away-5':
        teamSide === 'away',
      'active:bg-current-team-away-5/15': teamSide === 'away' && withActive,
      'bg-current-team-away-5/15': teamSide === 'away' && !selected,
      'bg-current-team-away-5': teamSide === 'away' && selected,
      'disabled:bg-current-team-away-7/10 disabled:border-current-team-away-9':
        teamSide === 'away' && disabled,
    }"
    :disabled
    :size
    :width
    @click="$emit('click', incidentId)"
  >
    <slot name="icon">
      <SportIncidentIcon
        :class="{
          'opacity-50': disabled,
        }"
        :incidentId
        :size="size === 'md' ? '3xs' : undefined"
      />
    </slot>
    <slot v-bind="{ incidentName }">
      {{ incidentName }}
    </slot>
  </Button>
</template>
