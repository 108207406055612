import {
  isSearchTermIncludedInOption,
  isStringOption,
  MultiSelectObjectOption,
} from '@collector/shared-ui'

/**
 * Check if the option is matched with the search term.
 * In case when the search term is a number, compare to shirtNr
 * otherwise the whole option name is checked.
 */
export function isOptionMatched(
  option: MultiSelectObjectOption | string,
  searchTerm: string,
): boolean {
  if (isNaN(Number(searchTerm)) || isStringOption(option)) {
    return isSearchTermIncludedInOption(option, searchTerm)
  }

  return Number(option.metadata?.shirtNr) === Number(searchTerm)
}
