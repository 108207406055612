<script setup lang="ts">
import { computed, StyleValue } from 'vue'

interface Props {
  position: { x: number; y: number }
}

const props = defineProps<Props>()

// computed
const position = computed<StyleValue>(() => ({
  top: `${props.position.y}%`,
  left: `${props.position.x}%`,
}))
</script>

<template>
  <img
    class="pointer-events-none absolute w-[5%] min-w-3 -translate-x-1/2 -translate-y-1/2 transform select-none"
    src="./ball.svg"
    :style="position"
  />
</template>
