<script setup lang="ts">
import * as R from 'ramda'

import { Icon } from '@collector/shared-ui'
import { formatTime } from '@collector/shared-utils'
import { useNotifications } from '@desktop/globalState/notifications'

// composables
const { notifications } = useNotifications()
</script>

<template>
  <div class="notifications mt-2 space-y-2 text-xs">
    <template v-if="notifications.length">
      <div
        v-for="(notification, index) in R.reverse(notifications)"
        :key="index"
        class="flex w-full items-center gap-x-2"
      >
        <div class="min-w-14 font-bold">
          {{ formatTime(notification.time / 1000) }}
        </div>
        <Icon
          :class="{
            'text-error-6': notification.type === 'error',
            'text-success-3': notification.type === 'success',
          }"
          :icon="
            notification.type === 'error'
              ? 'triangle-exclamation'
              : 'check-circle'
          "
          size="lg"
        />
        <div>{{ notification.value }}</div>
      </div>
    </template>

    <div v-else>There are no notifications</div>
  </div>
</template>

<style lang="scss" scoped>
.notifications {
  @apply max-h-[calc(100vh-11rem)] overflow-y-auto;
}
</style>
