<script setup lang="ts">
import { ref } from 'vue'

import {
  EventIncidentsQueue,
  QueueIncident,
  QueueIncidentStatus,
} from '@collector/desktop-feature-incidents-queue-core'
import { Button, Icon } from '@collector/shared-ui'
import { TeamSide } from '@collector/shared-ui-domain'
import { getDisplayedError } from '@collector/shared-utils'

import ListItemStatus from './ListItemStatus.vue'

interface Props {
  eventIncidentsQueue: EventIncidentsQueue
  queueIncident: QueueIncident
  status: QueueIncidentStatus
  type: string
  teamName: string | null
  incidentTitle: string
  incidentName: string
  displayedData: string
  incidentImportant: boolean
  teamSide: TeamSide | null
  index: number
  disabled: boolean
}

const props = defineProps<Props>()

// state
const dataOpened = ref(false)
const errorOpened = ref(false)
const displayedText = ref('')

// methods
function toggleData(): void {
  dataOpened.value = !dataOpened.value
  errorOpened.value = false

  setDisplayText()
}

function toggleError(): void {
  errorOpened.value = !errorOpened.value
  dataOpened.value = false

  setDisplayText()
}

function setDisplayText(): void {
  if (dataOpened.value) {
    displayedText.value = String(props.displayedData)
  } else if (errorOpened.value) {
    displayedText.value = getDisplayedError(props.queueIncident.error)
  } else {
    displayedText.value = ''
  }
}
</script>

<template>
  <div>
    <div
      class="flex w-full items-center bg-gradient-to-r px-1.5 text-sm"
      :class="{
        'to-current-team-home-5/40 from-transparent': teamSide === 'home',
        'to-current-team-away-5/40 from-transparent': teamSide === 'away',
        'to-error-9/40 from-transparent': incidentImportant,
        'bg-neutral-light-10': teamSide === null && !incidentImportant,
      }"
    >
      <div class="flex items-center">
        <div
          class="bg-neutral-light-10 text-neutral-3 mr-2 flex h-6 w-16 items-center justify-center rounded border p-1"
        >
          {{ type }}
        </div>
      </div>
      <div class="my-1 mr-1 flex w-44 flex-col justify-center leading-normal">
        <div
          v-if="teamName"
          class="text-default/60 text-2xs font-bold"
        >
          {{ teamName }}
        </div>
        <div class="text-xs">
          {{ incidentTitle }}
          <span class="text-nowrap font-bold">{{ incidentName }}</span>
        </div>
      </div>
      <ListItemStatus :status />
      <div class="flex h-10 w-40 items-center justify-end gap-2">
        <Button
          v-if="queueIncident.error"
          class="text-neutral-light-10 bg-team-home-5 border-team-home-5 relative"
          size="sm"
          square
          @click="toggleError"
        >
          <div class="flex flex-col">
            <Icon
              icon="warning"
              size="sm"
            />
            <Icon
              :icon="errorOpened ? 'angle-up' : 'angle-down'"
              size="xs"
            />
          </div>
        </Button>
        <Button
          class="text-neutral-light-10 bg-neutral-3 border-neutral-3 relative"
          size="sm"
          square
          @click="toggleData"
        >
          <div class="flex flex-col">
            <Icon
              icon="circle-info"
              size="sm"
            />
            <Icon
              :icon="dataOpened ? 'angle-up' : 'angle-down'"
              size="xs"
            />
          </div>
        </Button>
        <Button
          class="bg-success-5 text-neutral-light-10 border-success-5 disabled:bg-success-5/40 disabled:border-transparent"
          :disabled
          icon="paper-plane"
          size="sm"
          square
          @click="eventIncidentsQueue.startProcessingQueueIncident(index)"
        />
        <Button
          class="bg-error-5 text-neutral-light-10 border-error-5 disabled:bg-error-5/40 disabled:border-transparent"
          :disabled
          icon="times"
          size="sm"
          square
          @click="eventIncidentsQueue.removeQueueIncident(index)"
        />
      </div>
    </div>
    <div
      v-if="displayedText"
      class="bg-neutral-dark-10/10 mt-1 break-words p-2 text-xs"
      :class="{
        'whitespace-pre': dataOpened,
      }"
    >
      {{ displayedText }}
    </div>
  </div>
</template>
