import * as R from 'ramda'
import wretch, { WretchError } from 'wretch'

export class NetworkError extends Error {
  constructor(message: string, options?: ErrorOptions) {
    super(message, options)
    this.name = 'Network Error'
  }
}

/**
 * Check if error is caused by internet disconnection (triggered by window.fetch)
 *
 * ERR_INTERNET_DISCONNECTED
 */
export function isInternetDisconnectedError(error: unknown): boolean {
  const errorMessages = [
    'Load failed', // Safari
    'NetworkError when attempting to fetch resource.', // Firefox
    'Failed to fetch', // other browsers
  ]

  return error instanceof TypeError && errorMessages.includes(error.message)
}

export function isHttpError(
  error: unknown,
  status: number | number[],
): boolean {
  if (error instanceof Error && 'status' in error) {
    if (Array.isArray(status)) {
      return status.includes(error.status as number)
    } else {
      return status === error.status
    }
  }

  return false
}

export function isWretchError(error: unknown): error is WretchError {
  return error instanceof wretch.WretchError
}

export function getErrorName(error: unknown): string {
  if (error instanceof Error) {
    return error.name
  }

  return 'Error'
}

export function getErrorMessage(error: unknown): string {
  if (error instanceof Error) {
    return error.message
  }

  return R.toString(error)
}

export function getDisplayedError(error: unknown): string {
  return `[${getErrorName(error)}] ${getErrorMessage(error)}`
}
