import { computed, ComputedRef, Ref } from 'vue'

import { SubParticipantPosition } from '@collector/sportsapi-client-legacy'

import { Player } from '../types'
import { toSortedByPosition, toSortedBySurname } from '../utils'

function usePositionSubParticipants(
  players: Ref<Player[]>,
  position: SubParticipantPosition,
  toSorted: (players: Player[]) => Player[] = (p) => p,
): ComputedRef<Player[]> {
  return computed(() => {
    const participants = players.value.filter((p) => p.position === position)
    return toSorted(participants)
  })
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useSubParticipantsByPositionSorted(players: Ref<Player[]>) {
  return {
    goalkeeperSubParticipants: usePositionSubParticipants(
      players,
      SubParticipantPosition.Goalkeeper,
      (p) => toSortedByPosition(toSortedBySurname(p)),
    ),
    firstLineupSubParticipants: usePositionSubParticipants(
      players,
      SubParticipantPosition.FirstLineup,
      (p) => toSortedByPosition(toSortedBySurname(p)),
    ),
    benchSubParticipants: usePositionSubParticipants(
      players,
      SubParticipantPosition.Bench,
    ),
    coachSubParticipants: usePositionSubParticipants(
      players,
      SubParticipantPosition.Coach,
    ),
    willNotPlaySubParticipants: usePositionSubParticipants(
      players,
      SubParticipantPosition.WillNotPlay,
    ),
  }
}
