<script setup lang="ts">
import { Button, useBreakpoints } from '@collector/shared-ui'
import { useTeamSides } from '@desktop/views/Relation/composables'

const { changeSides } = useTeamSides()
const { breakpoints } = useBreakpoints()
</script>

<template>
  <div class="@md:p-5 @lg:p-1 @xl:p-5 p-1">
    <Button
      class="text-neutral-light-10 bg-neutral-3 border-neutral-3 [&>span]:hover:text-neutral-dark-0 hover:bg-neutral-9 hover:border-neutral-9 !text-2xs @md:!text-sm flex-row-reverse px-2 font-bold"
      width="full"
      :size="breakpoints.md.value ? 'md' : 'sm'"
      icon="exchange"
      @click="changeSides"
    >
      <span>Change sides</span>
    </Button>
  </div>
</template>
