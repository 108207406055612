export function formatTimePart(num: number): string {
  return num.toString().padStart(2, '0')
}

export function getFormattedTime(minute: number, second: number): string {
  return formatTimePart(minute) + ':' + formatTimePart(second)
}

export function getMinuteWithSecond(seconds: number): {
  minute: number
  second: number
} {
  const minute = Math.trunc(seconds / 60) || 0 // if result is -0, assign 0
  const second = seconds % 60 || 0 // if result is -0, assign 0

  return {
    minute,
    second,
  }
}
