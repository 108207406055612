import { modalContainerId } from '../components/Modal/consts'

export function hasOpenModal(): boolean {
  const modalContainer = document.querySelector(`#${modalContainerId}`)

  if (modalContainer) {
    return modalContainer.children.length > 0
  }

  return false
}
