<script setup lang="ts">
import { ref } from 'vue'

import { Button, Dropdown } from '@collector/shared-ui'
import { useNotifications } from '@desktop/globalState/notifications'

import NotificationsDropdown from './NotificationsDropdown.vue'

// composables
const { isNotificationVisible, notifications } = useNotifications()

// state
const dropdownOpen = ref(false)
</script>

<template>
  <Button
    class="border-neutral-light-2 bg-neutral-light-2 relative"
    icon="bell"
    size="xs"
    square
    @click="dropdownOpen = true"
  >
    <span
      v-if="isNotificationVisible"
      class="text-2xs bg-error-5 text-neutral-light-10 absolute -right-1 -top-1 h-2.5 w-2.5 rounded-full"
    ></span>
  </Button>
  <Dropdown
    v-model="dropdownOpen"
    class="top-9 w-96"
    :title="
      `Notifications` +
      (notifications.length ? ` ( ${notifications.length} )` : '')
    "
    withCloseIcon
  >
    <NotificationsDropdown />
  </Dropdown>
</template>
