import { ref, Ref } from 'vue'
import { useRouter } from 'vue-router'

import { ConfirmQueueIncident } from '@collector/desktop-feature-incidents-queue-core'
import { QueueRabbitClient } from '@collector/queue-gateway-shared-client'
import {
  ClientErrorType,
  RabbitErrorMessage,
} from '@collector/queue-gateway-shared-types'
import { NetworkError } from '@collector/shared-utils'
import { Incident } from '@collector/sportsapi-client'
import {
  Event,
  EventScout,
  Participant,
  SubParticipant,
} from '@collector/sportsapi-client-legacy'
import { Env } from '@desktop/envs'
import { useNotifications } from '@desktop/globalState/notifications'
import { useAuth } from '@desktop/globalState/userAuth/useAuth'
import { Routes } from '@desktop/router/routes'
import { handleError } from '@desktop/utils/errorHandler'

import { SyncedTimestamp } from '../syncedTimestamp'
import { updateEventOnConnect } from './event'
import { updateEventScoutsOnConnect } from './eventScouts'
import {
  initIncidentIdIncidentIndexMap,
  updateIncidentsOnConnect,
} from './incidents'
import { updateSubParticipantsOnConnect } from './subParticipants'
import { IncidentIdIncidentIndexMap, IncidentsUpdate } from './types'
import { useConfirmIncident } from './useConfirmIncident'

type InitQueueRabbitClient = {
  onQueueRabbitConfirmIncident: ConfirmQueueIncident
  incidentIdIncidentIndexMap: IncidentIdIncidentIndexMap
  incidentsUpdate: IncidentsUpdate
}

export function initQueueRabbitClient(
  event: Ref<Event>,
  eventTimestamp: Ref<SyncedTimestamp>,
  participants: Ref<Participant[]>,
  subParticipants: Ref<SubParticipant[]>,
  incidents: Ref<Incident[]>,
  eventScouts: Ref<EventScout[]>,
): InitQueueRabbitClient {
  const router = useRouter()
  const { addSuccessNotification } = useNotifications()

  const { refreshAuth, tokens, accessTokenBus, logOut } = useAuth()

  const queueRabbitClient = new QueueRabbitClient({
    eventId: event.value.id,
    url: Env.VITE_QUEUE_GATEWAY_URL,
    auth: tokens ? { accessToken: tokens.accessToken } : undefined,
  })

  accessTokenBus.on((accessToken) => queueRabbitClient.setAuth({ accessToken }))

  let initialConnection = true
  const lastIncidentMessageId = ref<number | null>(null)
  const incidentsUpdate: IncidentsUpdate = ref([])
  const { onQueueRabbitConfirmIncident, onQueueRabbitErrorMessage } =
    useConfirmIncident(incidentsUpdate)
  const { incidentIdIncidentIndexMap } = initIncidentIdIncidentIndexMap(
    incidents.value,
  )

  queueRabbitClient.onConnect(
    () => {
      initialConnection = false

      updateEventOnConnect(
        queueRabbitClient,
        event.value,
        eventTimestamp,
        participants,
        subParticipants,
      )
      updateIncidentsOnConnect(
        queueRabbitClient,
        incidents.value,
        incidentsUpdate,
        incidentIdIncidentIndexMap,
        lastIncidentMessageId,
      )

      updateSubParticipantsOnConnect(queueRabbitClient, subParticipants)

      updateEventScoutsOnConnect(queueRabbitClient, eventScouts)

      queueRabbitClient.subError<RabbitErrorMessage>({
        onMessage: onQueueRabbitErrorMessage,
      })

      if (!import.meta.env.DEV) {
        // eslint-disable-next-line
        console.debug('Connection to Websocket has been established')
      }
    },
    () => {
      addSuccessNotification('Connection to Websocket has been restored')
    },
  )

  queueRabbitClient.onDisconnect((reason) => {
    let humanReason = ''
    switch (reason) {
      case 'ping timeout':
        humanReason = 'you are offline'
        break
      case 'transport close':
        humanReason = 'connection closed'
        break
      case 'transport error':
        humanReason = 'connection error'
        break
      case 'io server disconnect':
        humanReason = 'server disconnected'
        break
      case 'io client disconnect':
        humanReason = 'client disconnected'
        break
      default:
        humanReason = reason
    }

    handleError(
      new NetworkError(
        `Connection to Websocket has been lost - ${humanReason}`,
        { cause: new Error(reason) },
      ),
    )
  })

  queueRabbitClient.onConnectError((error) => {
    handleError(
      new NetworkError('Error connecting to Websocket', { cause: error }),
      initialConnection,
    )
  })

  queueRabbitClient.onClientError((clientError) => {
    clientError.errors.forEach(async (error) => {
      const options = { cause: error }
      // eslint-disable-next-line no-console
      console.error('QueueRabbitClient clientError', error)

      switch (error.type) {
        case ClientErrorType.JwtExpiredError: {
          const tokens = await refreshAuth()
          queueRabbitClient.setAuth({ accessToken: tokens.accessToken })
          break
        }
        case ClientErrorType.JwtError:
          logOut()
          throw new Error(`JWT error: ${error.message}`, options)
        case ClientErrorType.UnauthorizedError:
          router.replace(Routes.Home)
          throw new Error(`Unauthorized error: ${error.message}`, options)
        default:
          throw new Error(
            `Queue Gateway error: ${JSON.stringify(error)}`,
            options,
          )
      }
    })
  })

  return {
    onQueueRabbitConfirmIncident,
    incidentsUpdate,
    incidentIdIncidentIndexMap,
  }
}
