<script setup lang="ts">
import { useScrollPositionFixed } from '../composables'
import Button from './Button/Button.vue'
import Icon from './Icon/Icon.vue'

interface Props {
  message: string
  name?: string
  type: 'error' | 'success'
}

defineProps<Props>()

// emits
defineEmits(['close'])

// composables
const { element } = useScrollPositionFixed()
</script>

<template>
  <div
    ref="element"
    class="absolute left-1/2 z-40 -translate-x-1/2"
  >
    <div
      class="text-neutral-light-7 mt-1 flex items-center gap-x-2 rounded-lg px-3 py-1"
      :class="{
        'bg-error-6': type === 'error',
        'bg-success-3': type === 'success',
      }"
    >
      <Icon
        :icon="type === 'error' ? 'triangle-exclamation' : 'check-circle'"
        size="lg"
      />
      <div :title="message">
        <div
          v-if="name"
          class="py-0.5 text-sm font-bold"
        >
          {{ name }}
        </div>
        <div class="max-w-80 truncate text-xs">
          {{ message }}
        </div>
      </div>
      <Button
        class="hover:text-neutral-light-10 border-none"
        square
        icon="xmark"
        iconSize="xl"
        @click="$emit('close')"
      />
    </div>
  </div>
</template>
