import { Ref, ref } from 'vue'

import Sentry from '@collector/shared-plugin-sentry-vue'
import { AuthInfo } from '@collector/sportsapi-client'

import { sportsApiClient } from '../sportsApiClient'

// initial state of logged user is unknown
const authInfo: Ref<AuthInfo.AuthInfoType | null> = ref(null)

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useAuthInfo() {
  async function initAuthInfo(): Promise<void> {
    const authInfoRes = await sportsApiClient.AuthInfo.get()
    authInfo.value = authInfoRes.api.data
    Sentry.setUser({ id: authInfo.value.id })
  }

  function destroyAuthInfo(): void {
    authInfo.value = null
    Sentry.setUser(null)
  }

  return {
    authInfo,
    initAuthInfo,
    destroyAuthInfo,
  }
}
