import { Ref, toRaw } from 'vue'

import { RabbitIncidentMessage } from '@collector/queue-gateway-shared-types'
import { GetIncident } from '@collector/shared-ui-domain'
import { Incident } from '@collector/sportsapi-client'

import {
  injectIncidentIdIncidentIndexMap,
  injectIncidents,
  injectIncidentsUpdate,
  injectUserIncidentsUpdate,
} from '../provide-inject'
import { IncidentIdIncidentIndexMap } from '../utils'

type UseIncidents = {
  /** reactive array of incidents, initially loaded with sports API and updated through websocket */
  incidents: Ref<Incident[]>

  /**
   *
   * Incidents "raw data" (with uuid) received through websocket as "diff" array,
   * every time queue-gateway receives an update from rabbitMQ.
   *
   * May contain more than 1 element.
   */
  incidentsUpdate: Ref<RabbitIncidentMessage[]>

  userIncidentsUpdate: Ref<RabbitIncidentMessage[]>

  /** map of incident id to incident index in incidents array */
  incidentIdIncidentIndexMap: IncidentIdIncidentIndexMap

  /** get incident by id from incidents array */
  getIncident: GetIncident
}

function getIncident(
  incidents: Ref<Incident[]>,
  incidentIdIncidentIndexMap: Map<number, number>,
  id: number,
): Incident {
  const incidentIndex = incidentIdIncidentIndexMap.get(id)

  if (incidentIndex === undefined) {
    throw Error(`Incident with id ${id} not found`)
  }

  return toRaw(incidents.value[incidentIndex])
}

export function useIncidents(): UseIncidents {
  const incidents = injectIncidents()
  const incidentsUpdate = injectIncidentsUpdate()
  const userIncidentsUpdate = injectUserIncidentsUpdate()
  const incidentIdIncidentIndexMap = injectIncidentIdIncidentIndexMap()

  return {
    incidents,
    incidentsUpdate,
    userIncidentsUpdate,
    incidentIdIncidentIndexMap,
    getIncident: getIncident.bind(null, incidents, incidentIdIncidentIndexMap),
  }
}
