export enum ShotOnTargetAttribute {
  GoallineClearance = 731,
  LeftFoot = 404,
  RightFoot = 405,
  Head = 429,
  OutsidePenaltyArea = 428,
  InsidePenaltyArea = 427,
  UpperLeft = 430,
  UpperRight = 431,
  UpperMiddle = 432,
  BottomMiddle = 433,
  BottomLeft = 434,
  BottomRight = 435,
  PowerShot = 844,
  Volley = 845,
  BicycleKick = 846,
  Chip = 847,
  OneOnOne = 848,
}
