import {
  StatId,
  SubParticipant,
  SubParticipantPosition,
} from '@collector/sportsapi-client-legacy'

export type SubParticipantOptionValue = {
  shortName: SubParticipant['short_name'] | null
  id: SubParticipant['id']
  teamId: SubParticipant['team_id']
}

export type SubParticipantOption = {
  name: string
  value: SubParticipantOptionValue
  metadata: {
    position: SubParticipant['position']
    shirtNr: string
    stats: Record<StatId, unknown>
  }
}

export function getSubparticipantsOptions(
  subParticipants: SubParticipant[],
): SubParticipantOption[] {
  return subParticipants.map((subParticipant) => ({
    name: `${subParticipant.shirt_nr ?? ''} ${subParticipant.short_name}`.trim(),
    value: {
      shortName: subParticipant.short_name,
      id: subParticipant.id,
      teamId: subParticipant.team_id,
    },
    metadata: {
      position: subParticipant.position,
      shirtNr: subParticipant.shirt_nr ?? '',
      stats: (subParticipant.stats ?? []).reduce(
        (acc, stat) => {
          acc[stat.id] = stat.value
          return acc
        },
        {} as Record<StatId, unknown>,
      ),
    },
  }))
}

export function getTeamIdToSubParticipantsMap(
  subParticipants: SubParticipant[],
): Record<string, SubParticipant[]> {
  return subParticipants.reduce(
    (acc, subParticipant) => {
      if (subParticipant.team_id) {
        acc[subParticipant.team_id] = acc[subParticipant.team_id] || []

        acc[subParticipant.team_id].push(subParticipant)
      }

      return acc
    },
    {} as Record<string, SubParticipant[]>,
  )
}

export function sortSubParticipants(
  subParticipants: SubParticipant[],
): SubParticipant[] {
  return subParticipants.sort((a, b) => {
    // Rule 1: "goalkeeper" on top
    if (
      a.position === SubParticipantPosition.Goalkeeper &&
      b.position !== SubParticipantPosition.Goalkeeper
    )
      return -1
    if (
      a.position !== SubParticipantPosition.Goalkeeper &&
      b.position === SubParticipantPosition.Goalkeeper
    )
      return 1

    // Rule 2: "1st lineup" with non-empty shirt_nr in ascending order
    if (a.shirt_nr && b.shirt_nr) {
      const shirtNrA = +a.shirt_nr
      const shirtNrB = +b.shirt_nr

      if (shirtNrA > shirtNrB) {
        return 1
      }

      if (shirtNrA < shirtNrB) {
        return -1
      }

      return a.short_name.localeCompare(b.short_name)
    }

    // Rule 3: "1st lineup" with empty shirt_nr at the bottom
    if (a.shirt_nr && !b.shirt_nr) return -1
    if (!a.shirt_nr && b.shirt_nr) return 1

    // If all else is equal, maintain original order
    return 0
  })
}

export function getSortedSquad(
  subParticipants: SubParticipant[],
): SubParticipant[] {
  const filteredSubParticipants = subParticipants.filter(
    (subParticipant) =>
      subParticipant.position === SubParticipantPosition.Goalkeeper ||
      subParticipant.position === SubParticipantPosition.FirstLineup,
  )

  return sortSubParticipants(filteredSubParticipants)
}

/**
 * GK + first lineup + bench subParticipants
 */
export function getSortedPlayers(
  subParticipants: SubParticipant[],
): SubParticipant[] {
  return sortSubParticipants(
    subParticipants.filter((subParticipant) =>
      isPlayerPosition(subParticipant.position),
    ),
  )
}

const firstLineupPositions = new Set([
  SubParticipantPosition.FirstLineup,
  SubParticipantPosition.Goalkeeper,
])

export function isFirstLineupPosition(
  position: SubParticipantPosition,
): boolean {
  return firstLineupPositions.has(position)
}

export function isBenchPosition(position: SubParticipantPosition): boolean {
  return position === SubParticipantPosition.Bench
}

export function isPlayerPosition(position: SubParticipantPosition): boolean {
  return isFirstLineupPosition(position) || isBenchPosition(position)
}
