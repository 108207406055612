import * as R from 'ramda'

import {
  hasIncidentMasterAttribute,
  IsBetIncident,
  IsEmergencyIncident,
  IsIncidentImportant,
  type HasIncidentMasterAttribute,
} from '@collector/shared-ui-domain'
import { Incident } from '@collector/sportsapi-client'
import { IncidentId } from '@collector/sportsapi-client-legacy'

import { injectBetIncidents } from '../provide-inject'

type UseSportIncidentConfig = {
  isIncidentEmergencyOrBet: IsIncidentImportant
  hasIncidentMasterAttribute: HasIncidentMasterAttribute
  isBetIncident: IsBetIncident
  isEmergencyIncident: IsEmergencyIncident
}

function isBetIncident(
  betIncidents: Set<IncidentId>,
  incident: Pick<Incident, 'incident_id'>,
): boolean {
  return betIncidents.has(incident.incident_id)
}

function isEmergencyIncident(
  incident: Partial<Pick<Incident, 'category'>>,
): boolean {
  return incident.category === 'emergency'
}

function isIncidentEmergencyOrBet(
  betIncidents: Set<IncidentId>,
  incident: Pick<Incident, 'incident_id'> & Partial<Pick<Incident, 'category'>>,
): boolean {
  return betIncidents.has(incident.incident_id) || isEmergencyIncident(incident)
}

export function useBetIncidents(): UseSportIncidentConfig {
  const betIncidents = injectBetIncidents()

  return {
    isIncidentEmergencyOrBet: R.partial(isIncidentEmergencyOrBet, [
      betIncidents,
    ]),
    isBetIncident: R.partial(isBetIncident, [betIncidents]),
    isEmergencyIncident,
    hasIncidentMasterAttribute,
  }
}
