import { teamColors as _teamColors } from '@collector/shared-ui'

type Color = `${number} ${number} ${number}`

type TeamColorConfigRecord = Record<number, Color>

function transformColors(colors: string[]): TeamColorConfigRecord {
  return colors.reduce((acc, color, index) => {
    const transformedColor = color.slice(4, -1).replace(/,/g, '')
    acc[index] = transformedColor as Color
    return acc
  }, {} as TeamColorConfigRecord)
}

export const teamColors: {
  home: TeamColorConfigRecord
  away: TeamColorConfigRecord
} = {
  home: transformColors(Object.values(_teamColors['team-home'])),
  away: transformColors(Object.values(_teamColors['team-away'])),
}
