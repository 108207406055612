<script setup lang="ts">
import { ref, watch } from 'vue'

import { isWidgetResponse } from '@collector/desktop-feature-widgets'
import { NotificationBar, TransitionFadeOut } from '@collector/shared-ui'
import {
  createReschedulableTimeout,
  getErrorMessage,
  getErrorName,
} from '@collector/shared-utils'
import {
  NotificationType,
  useNotifications,
} from '@desktop/globalState/notifications'

// data
const hideTimeoutMs = 5000

// composables
const { notifications, isNotificationVisible } = useNotifications()

// state
const message = ref<string>()
const name = ref<string>()
const type = ref<NotificationType>()

const {
  clearScheduledTimeout: clearHideNotification,
  rescheduleTimeout: scheduleHideNotification,
} = createReschedulableTimeout(() => {
  isNotificationVisible.value = false
}, hideTimeoutMs)

// methods
async function getResponseErrorData(response: Response): Promise<{
  message: string
  name: string
}> {
  const text = await response.text()

  return {
    name: isWidgetResponse(response)
      ? 'Statscore Widget Error'
      : 'Network Error',
    message: text,
  }
}

// watchers
watch(
  notifications,
  async () => {
    const lastNotification = notifications.value[notifications.value.length - 1]

    if (lastNotification.type === 'success') {
      name.value = undefined
      message.value = lastNotification.value as string

      scheduleHideNotification()
    } else {
      if (lastNotification.value instanceof Response) {
        const responseErrorData = await getResponseErrorData(
          lastNotification.value,
        )

        message.value = responseErrorData.message
        name.value = responseErrorData.name
      } else {
        message.value = getErrorMessage(lastNotification.value)
        name.value = getErrorName(lastNotification.value)
      }

      clearHideNotification()
    }

    type.value = lastNotification.type
    isNotificationVisible.value = true
  },
  { deep: true },
)
</script>

<template>
  <TransitionFadeOut>
    <NotificationBar
      v-if="message && type && isNotificationVisible"
      :type
      :name
      :message
      @close="isNotificationVisible = false"
    />
  </TransitionFadeOut>
</template>
