<script setup lang="ts" generic="T extends MultiSelectOption">
import {
  ComboboxAnchor,
  ComboboxContent,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxItem,
  ComboboxPortal,
  ComboboxRoot,
  ComboboxTrigger,
  ComboboxViewport,
} from 'radix-vue'
import * as R from 'ramda'
import { computed, ref, watch } from 'vue'

import { vRequired } from '../../directives'
import Button from '../Button/Button.vue'
import Checkbox from '../Checkbox/Checkbox.vue'
import FormControl from '../FormControl.vue'
import Icon from '../Icon/Icon.vue'
import {
  MultiSelectObjectOption,
  MultiSelectOption,
  MultiSelectOptionValue,
  MultiSelectValue,
} from './types'
import { isSearchTermIncludedInOption, isStringOption } from './utils'

interface Props {
  withClear?: boolean
  placeholder?: string
  disabled?: boolean
  required?: boolean
  mode?: 'select' | 'combobox'
  options: T[] | Promise<T[]>
  withFilter?: boolean
  withOptionsFullWidth?: boolean
  size?: 'xs' /** 24px */ | 'sm' /** 32px */
  filterPredicate?: (
    option: MultiSelectObjectOption | string,
    searchTerm: string,
  ) => boolean
}

const props = withDefaults(defineProps<Props>(), {
  mode: 'select',
  placeholder: 'select',
  size: 'sm',
  withClear: true,
})

// emits
type Emits = {
  /**
   * after selected value is cleared, but only if the component is not open - otherwise, it emits blur
   */
  clearValue: []
  blur: []
}

const emit = defineEmits<Emits>()

// data
const optionsWidthStyle = props.withOptionsFullWidth
  ? ''
  : 'width: calc(var(--radix-combobox-trigger-width) - 2px)'

// state
const pendingOptions = ref<Promise<T[]>>()
const resolvedOptions = ref<T[]>([])

// models
const searchTerm = defineModel<string>('searchTerm', { default: '' })
const open = defineModel<boolean>('open', { default: false })

/* default null - single select */
const selected = defineModel<MultiSelectValue>({ default: null })

function clearValue(): void {
  if (Array.isArray(selected.value)) {
    selected.value = []
  } else if (R.is(String, selected.value)) {
    selected.value = ''
  } else {
    selected.value = undefined
  }

  searchTerm.value = ''

  if (open.value) {
    open.value = false
  } else {
    emit('clearValue')
  }
}

function getOptionName(option: MultiSelectOption): string {
  if (isStringOption(option)) {
    return option
  }

  return option.name
}

function getOptionValue(option: MultiSelectOption): MultiSelectOptionValue {
  if (isStringOption(option)) {
    return option
  }

  return option.value
}

function getOptionTextValue(option: MultiSelectOption): string {
  if (isStringOption(option)) {
    return option
  }

  return option.name
}

function hasSelectedOption(option: MultiSelectOption): boolean {
  const value = getOptionValue(option)

  if (Array.isArray(selected.value)) {
    return R.includes(value, selected.value)
  }

  return R.equals(value, selected.value)
}

function findOption(
  value: MultiSelectOptionValue,
): MultiSelectOption | undefined {
  return resolvedOptions.value.find((option) => {
    if (isStringOption(option)) {
      return option === value
    }

    return R.equals(option.value, value)
  })
}

function updateSearchTerm(value: string): void {
  /** on mode=combobox, prevent resetting searchTerm after value is selected, to allow re-select options */
  if (props.mode === 'select' || isSelectedEmptyValue.value) {
    searchTerm.value = value
  }
}

const isSelectedEmptyValue = computed(() => {
  return (
    selected.value === '' ||
    selected.value === undefined ||
    selected.value === null
  )
})

// computed
const filteredOptions = computed(() => {
  if (searchTerm.value === '' || props.mode === 'combobox')
    return resolvedOptions.value

  const predicate = props.filterPredicate ?? isSearchTermIncludedInOption

  return resolvedOptions.value.filter((option) =>
    predicate(option, searchTerm.value),
  )
})

const displayedValue = computed(() => {
  if (Array.isArray(selected.value) && selected.value.length > 1) {
    return `${selected.value.length} selected`
  }

  return selectedOption.value ? getOptionName(selectedOption.value) : ''
})

const selectedOption = computed(() => {
  if (Array.isArray(selected.value) && selected.value.length > 1) {
    return null // multiple selected options not implemented yet (not used)
  }

  const selectedValue = Array.isArray(selected.value)
    ? selected.value[0]
    : selected.value

  if (selectedValue === '' || selectedValue === undefined) return null

  return findOption(selectedValue)
})

const emptyText = computed(() => {
  if (props.mode === 'combobox' || resolvedOptions.value.length > 0) {
    return 'No results found'
  }

  return 'No results available'
})

// watchers
watch(open, (newVal) => {
  if (newVal === false) {
    emit('blur')
  }
})

watch(
  () => props.options,
  (newVal) => {
    if (Array.isArray(newVal)) {
      resolvedOptions.value = newVal
    } else {
      pendingOptions.value = newVal

      newVal.then((options) => {
        // ensure that resolved options are last promise we are waiting for
        // (prevents showing outdated options from previous promise if it will resolve after the current one)
        if (pendingOptions.value === newVal) {
          pendingOptions.value = undefined
          resolvedOptions.value = options
        }
      })
    }
  },
  { immediate: true },
)
</script>

<template>
  <FormControl
    :size
    :disabled
  >
    <template #label>
      <slot />
    </template>
    <ComboboxRoot
      v-model="selected"
      v-model:open="open"
      class="w-full"
      :disabled
      :multiple="true"
      :resetSearchTermOnBlur="false"
      @update:searchTerm="updateSearchTerm"
    >
      <!-- :multiple="true" is needed to display all options to choose when one is already selected -->
      <ComboboxAnchor
        class="inline-flex w-full leading-none outline-none"
        :class="{
          'h-6': size === 'xs',
          'h-8': size === 'sm',
        }"
      >
        <ComboboxTrigger
          v-required="{
            required: required && !disabled,
            hasValue: !isSelectedEmptyValue,
          }"
          class="flex w-full items-center rounded border outline-none"
          :class="{
            'bg-neutral-light-10': !disabled,
            'bg-neutral-dark-8/10': disabled,
            'border-neutral-dark-3/15': !required || disabled,
          }"
          role="combobox"
          :disabled="mode === 'combobox' && !searchTerm"
        >
          <div
            v-if="mode === 'combobox'"
            class="flex h-full w-full items-center text-xs"
          >
            <Icon
              class="text-neutral-dark-3/60 pr-1.5"
              :class="{
                'pl-1.5': size === 'xs',
                'pl-2.5': size === 'sm',
              }"
              size="sm"
              icon="search"
            />
            <ComboboxInput
              v-if="!displayedValue"
              class="placeholder-neutral-dark-3/40 bg-neutral-light-10 mr-0.5 h-full w-full outline-none"
              :placeholder
              @keyup.space.prevent
            />
            <!-- @keyup.space.prevent : prevents setting open="false" after pressing space -->
            <slot
              v-else
              name="displayedValue"
              :selectedOption
            >
              <span>{{ displayedValue }}</span>
            </slot>
          </div>

          <div
            v-else
            class="text-neutral-dark-3 w-full overflow-hidden whitespace-nowrap text-left text-xs leading-3"
            :class="{
              'text-neutral-dark-3/50': disabled,
              'pl-1.5': size === 'xs',
              'pl-2.5': size === 'sm',
            }"
          >
            <slot
              name="displayedValue"
              :selectedOption
            >
              {{ displayedValue || placeholder }}
            </slot>
          </div>

          <Button
            v-if="withClear && !disabled && selected && !R.isEmpty(selected)"
            class="hover:text-neutral-dark-3/60 p-2"
            @click.stop="clearValue"
          >
            <Icon
              class="h-3.5 w-3.5"
              icon="circle-xmark"
            />
          </Button>

          <Icon
            v-else-if="mode === 'select'"
            class="mx-2"
            :class="{
              'text-neutral-dark-3/50': disabled,
            }"
            size="sm"
            :icon="open ? 'chevron-up' : 'chevron-down'"
          />
        </ComboboxTrigger>
      </ComboboxAnchor>

      <ComboboxPortal>
        <ComboboxContent
          class="bg-neutral-light-10 border-neutral-dark-3/1 5 z-20 w-full overflow-auto rounded rounded-t-none border border-t-0 text-xs"
          disableOutsidePointerEvents
          position="popper"
          align="start"
        >
          <div
            v-if="withFilter"
            class="flex min-h-8 items-center"
            :style="optionsWidthStyle"
          >
            <Icon
              class="text-neutral-dark-3/60 pr-1.75 pl-2.5"
              size="sm"
              icon="search"
            />
            <ComboboxInput
              v-if="withFilter"
              class="placeholder-neutral-dark-3/40 bg-neutral-light-10 w-full outline-none"
              autoFocus
              placeholder="search"
            />
          </div>
          <ComboboxViewport
            class="!overflow-x-hidden"
            :style="optionsWidthStyle"
          >
            <div class="max-h-40 overflow-y-auto">
              <div
                v-if="pendingOptions"
                class="flex h-8 items-center justify-center gap-x-1 text-xs"
              >
                <Icon
                  class="animate-spin"
                  icon="rotate-right"
                />
                Searching...
              </div>

              <template v-else>
                <ComboboxEmpty class="py-2 text-center text-xs">
                  {{ emptyText }}
                </ComboboxEmpty>
                <ComboboxItem
                  v-for="option in filteredOptions"
                  :key="getOptionTextValue(option)"
                  class="data-[highlighted]:bg-neutral-light-5 flex h-8 cursor-pointer select-none items-center px-1.5 leading-3 data-[disabled]:pointer-events-none data-[highlighted]:outline-none"
                  :value="getOptionValue(option)"
                >
                  <div class="flex items-center space-x-1.5 overflow-hidden">
                    <Checkbox
                      :modelValue="hasSelectedOption(option)"
                      size="2xs"
                    />

                    <span>
                      <slot
                        name="option"
                        :option
                      >
                        {{ getOptionName(option) }}
                      </slot>
                    </span>
                  </div>
                </ComboboxItem>
              </template>
            </div>
          </ComboboxViewport>
        </ComboboxContent>
      </ComboboxPortal>
    </ComboboxRoot>
  </FormControl>
</template>
