<script setup lang="ts">
import {
  useParticipants,
  useTeamSides,
} from '@desktop/views/Relation/composables'
import PocketIncidents from '@desktop/views/Relation/Sports/components/PocketIncidents/PocketIncidents.vue'

import EventInfo from './EventInfo/EventInfo.vue'
import IncidentsToConfirm from './IncidentsToConfirm/IncidentsToConfirm.vue'
import { useIncidentsToConfirm } from './IncidentsToConfirm/useIncidentsConfirmation'

const {
  currentFirstParticipantIncidentsTbd,
  currentSecondParticipantIncidentsTbd,
} = useIncidentsToConfirm()

const { getWithCurrentTeamSides } = useTeamSides()
const { homeParticipant, awayParticipant } = useParticipants()
const [currentFirstParticipant, currentSecondParticipant] =
  getWithCurrentTeamSides(homeParticipant, awayParticipant)

const { currentFirstTeamSide, currentSecondTeamSide } = useTeamSides()
</script>

<template>
  <div class="flex flex-col gap-y-3">
    <EventInfo />
    <slot name="summary" />
    <div class="flex gap-x-2">
      <IncidentsToConfirm
        v-model="currentFirstParticipantIncidentsTbd"
        :teamSide="currentFirstTeamSide"
        :participant="currentFirstParticipant"
      />
      <div class="w-full max-w-[30rem] space-y-2">
        <PocketIncidents />
        <slot name="middle" />
      </div>
      <IncidentsToConfirm
        v-model="currentSecondParticipantIncidentsTbd"
        :teamSide="currentSecondTeamSide"
        :participant="currentSecondParticipant"
        reversed
      />
    </div>
    <slot name="bottom" />
  </div>
</template>
