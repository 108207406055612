import * as R from 'ramda'

export function formatJSON(json: Record<string, unknown>): string {
  return JSON.stringify(json, null, 2)
}

/**
 * Compare object with a given shape.
 *
 * Comparision is shallow - only first level properties are checked.
 */
export function hasEqualShape<T extends Record<string, unknown>>(
  shape: Record<keyof T, ArrayConstructor | StringConstructor>,
  object: T,
): boolean {
  const shapeKeys = Object.keys(shape)
  const objectKeys = Object.keys(object)

  if (shapeKeys.length !== objectKeys.length) {
    return false
  }

  return shapeKeys.every(
    (key) => objectKeys.includes(key) && R.is(shape[key], object[key]),
  )
}
