<script setup lang="ts">
import { ref, watch } from 'vue'

import { Button, useBreakpoints } from '@collector/shared-ui'
import { Incident } from '@collector/sportsapi-client'
import {
  useEventSettings,
  useParticipants,
  useTeamSides,
} from '@desktop/views/Relation/composables'
import { useEventIncidentsQueue } from '@desktop/views/Relation/Sports/composables'

import { injectEventStatusIncidentsOnFieldConfig } from '../../provide-inject'
import ChangeSidesButton from './ChangeSidesButton.vue'
import ParticipantButton from './ParticipantButton.vue'
import { useIncidentToSend } from './useIncidentToSend'

// emits
const emit = defineEmits<{
  open: [open: boolean]
}>()

// inject
const { eventStatusToIncidentIdMap, kickOffIncidentId, secondHalfIncidentId } =
  injectEventStatusIncidentsOnFieldConfig()!

// composables
const { homeParticipant, awayParticipant } = useParticipants()
const { updateEventSettings } = useEventSettings()
const eventIncidentsQueue = useEventIncidentsQueue()
const { getWithCurrentTeamSides } = useTeamSides()

const { incidentToSend, getTeamSidePossession } = useIncidentToSend(
  eventStatusToIncidentIdMap,
)
const { breakpoints } = useBreakpoints()

// state
const shouldShowIncidentToSend = ref(false)
const [currentFirstParticipant, currentSecondParticipant] =
  getWithCurrentTeamSides(homeParticipant, awayParticipant)

// methods
async function sendEventStatusIncident(
  participantTeamId?: Incident['participant_team_id'],
): Promise<void> {
  const incidentId = incidentToSend.value!.id

  try {
    // immediately set to null to hide buttons and prevent multiple clicks which send duplicated incidents
    shouldShowIncidentToSend.value = false

    eventIncidentsQueue.addIncident({
      incident_id: incidentId,
      participant_team_id: participantTeamId,
    })
  } catch (error) {
    // if request failed, show again buttons with incident to send
    shouldShowIncidentToSend.value = true

    throw error
  }

  const teamSidePossession = getTeamSidePossession(
    incidentId,
    participantTeamId,
    kickOffIncidentId,
    secondHalfIncidentId,
  )

  if (teamSidePossession) {
    updateEventSettings({ teamSidePossession })
  }
}

// watchers
watch(
  incidentToSend,
  () => {
    if (incidentToSend.value) {
      shouldShowIncidentToSend.value = true
    }

    emit('open', !!incidentToSend.value)
  },
  {
    immediate: true,
  },
)
</script>

<template>
  <div
    v-if="incidentToSend"
    class="bg-neutral-dark-0/60 absolute top-0 h-full w-full"
  >
    <Transition>
      <div v-if="shouldShowIncidentToSend">
        <ChangeSidesButton />
        <div
          class="absolute top-1/2 flex w-full -translate-y-1/2 flex-col items-center gap-y-2"
        >
          <template v-if="incidentToSend.id === kickOffIncidentId">
            <div
              class="text-neutral-light-10 text-2xs @md:pt-0 @md:text-base pt-7 text-center font-bold"
            >
              Which team will KICK-OFF ?
            </div>

            <div class="flex w-full gap-x-6 px-2">
              <ParticipantButton
                class="w-1/2 px-3"
                :participant="currentFirstParticipant"
                :size="breakpoints.md.value ? 'lg' : 'xs'"
                @click="sendEventStatusIncident(currentFirstParticipant.id)"
              />
              <ParticipantButton
                class="w-1/2 px-3"
                :participant="currentSecondParticipant"
                :size="breakpoints.md.value ? 'lg' : 'xs'"
                @click="sendEventStatusIncident(currentSecondParticipant.id)"
              />
            </div>
          </template>
          <Button
            v-else
            class="shadow-simple-shadow bg-neutral-light-10 border-neutral-light-10 hover:bg-neutral-light-10/80 @md:mt-0 mt-5 w-3/5 !text-wrap font-bold hover:border-transparent"
            :size="breakpoints.md.value ? 'md' : 'sm'"
            :fontSize="breakpoints.md.value ? 'text-sm' : 'text-2xs'"
            width="slim"
            @click="sendEventStatusIncident()"
          >
            Send "{{ incidentToSend.name }}"
          </Button>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
