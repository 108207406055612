<script setup lang="ts">
import { computed } from 'vue'

import { Participant } from '@collector/sportsapi-client-legacy'

import { getParticipantTeamSide } from '../utils'

interface Props {
  participant: Participant
  withTeamColors?: boolean
  isDeleted?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  withTeamColors: true,
})

// data
const teamSide = getParticipantTeamSide(props.participant)

// computed
const textClass = computed(() => {
  if (!props.withTeamColors) {
    return 'text-neutral-dark-0'
  }

  if (teamSide === 'home') {
    return props.isDeleted
      ? 'text-current-team-home-4/40'
      : 'text-current-team-home-4'
  }

  return props.isDeleted
    ? 'text-current-team-away-4/40'
    : 'text-current-team-away-4'
})
</script>

<template>
  <div :class="textClass">
    {{ participant.short_name }}
  </div>
</template>
