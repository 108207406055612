import {
  CommonIncidentId,
  SoccerIncidentId,
} from '@collector/sportsapi-client-legacy'

import { IncidentsInFilter } from '../../../configuration'
import { incidentIdsGroup } from './incidentsGroup'

export const incidentsInFilter: IncidentsInFilter = [
  SoccerIncidentId.Goal,
  SoccerIncidentId.RedCard,
  SoccerIncidentId.YellowCard,
  SoccerIncidentId.Corner,
  SoccerIncidentId.Penalty,
  SoccerIncidentId.PenaltySavedByGoalkeeper,
  SoccerIncidentId.MissedPenalty,
  SoccerIncidentId.ShotOnTarget,
  SoccerIncidentId.ShotOffTarget,
  SoccerIncidentId.ShotBlocked,
  SoccerIncidentId.Foul,
  SoccerIncidentId.Offside,
  SoccerIncidentId.FreeKick,
  SoccerIncidentId.ThrowIn,
  SoccerIncidentId.Injury,
  incidentIdsGroup,
  SoccerIncidentId.GoalKick,
  SoccerIncidentId.AddedTime,
  SoccerIncidentId.PossibleVAR,
  SoccerIncidentId.VarEnded,
  CommonIncidentId.Betstart,
  CommonIncidentId.Betstop,
  SoccerIncidentId.BookingMarketsDisabled,
  SoccerIncidentId.BookingMarketsEnabled,
  SoccerIncidentId.CornerMarketsDisabled,
  SoccerIncidentId.CornerMarketsEnabled,
  CommonIncidentId.TechnicalProblems,
  SoccerIncidentId.BreakDuringTheGame,
  CommonIncidentId.MatchResumed,
  SoccerIncidentId.WaterBreak,
  SoccerIncidentId.RefereesInjury,
  SoccerIncidentId.InPossession,
  SoccerIncidentId.Attack,
  SoccerIncidentId.DangerousAttack,
  SoccerIncidentId.KickOff,
  SoccerIncidentId.Tackle,
  SoccerIncidentId.Interception,
  SoccerIncidentId.BallSkills,
  SoccerIncidentId.LooseBall,
]
