function logNetworkStatus(online: boolean): void {
  // eslint-disable-next-line
  console.debug(
    `${new Date().toISOString()} Application is ${online ? 'online' : 'offline'}.`,
  )
}

function checkNetworkStatus(): void {
  logNetworkStatus(navigator.onLine)
}

function registerNetworkStateChangeHandlers(): void {
  window.addEventListener('online', () => {
    logNetworkStatus(true)
  })

  window.addEventListener('offline', () => {
    logNetworkStatus(false)
  })
}

export function trackNetworkStatus(): void {
  checkNetworkStatus()
  registerNetworkStateChangeHandlers()
}
