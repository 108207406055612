import { MultiSelectObjectOption, MultiSelectOption } from './types'

export function isStringOption(value: MultiSelectOption): value is string {
  return typeof value === 'string'
}

export function isSearchTermIncludedInOption(
  option: MultiSelectObjectOption | string,
  searchTerm: string,
): boolean {
  const name = isStringOption(option) ? option : option.name

  return name.toLowerCase().includes(searchTerm.toLowerCase())
}
