import {
  createAuthClient as _createAuthClient,
  createSportsApiClient,
  HttpClient,
  withUnauthorizedCatcher,
} from '@collector/sportsapi-client'
import { Env } from '@desktop/envs'
import { setSportsApiClient } from '@desktop/globalState/sportsApiClient'

export function createAuthClient(token: string): HttpClient {
  return _createAuthClient(Env.VITE_SPORTS_API_URL, token)
}

export function initSportsApiClient(
  accessToken: string,
  refreshAuth: () => Promise<{ accessToken: string }>,
): void {
  const httpClient = createAuthClient(accessToken)
  const withCatcher = withUnauthorizedCatcher(httpClient, refreshAuth)
  const sportsApiClient = createSportsApiClient(withCatcher)
  setSportsApiClient(sportsApiClient)
}
