import * as R from 'ramda'
import { ref, Ref, watch } from 'vue'

import { Incident } from '@collector/sportsapi-client'
import { Confirmation } from '@collector/sportsapi-client-legacy'
import {
  useIncidents,
  useParticipants,
  useTeamSides,
} from '@desktop/views/Relation/composables'

type UseIncidentsToConfirm = {
  currentFirstParticipantIncidentsTbd: Ref<Incident[]>
  currentSecondParticipantIncidentsTbd: Ref<Incident[]>
}

function getParticipantIdToIncidentsTbdMap(
  incidents: Incident[],
): Record<number, Incident[]> {
  return incidents.reduce(
    (acc, incident) => {
      const participantId = incident.participant_team_id

      if (
        incident.confirmation === Confirmation.ToBeDone &&
        participantId !== null
      ) {
        if (!acc[participantId]) {
          acc[participantId] = []
        }

        acc[participantId].push(incident)
      }

      return acc
    },
    {} as Record<number, Incident[]>,
  )
}

export function useIncidentsToConfirm(): UseIncidentsToConfirm {
  const { incidents } = useIncidents()
  const { homeParticipant, awayParticipant } = useParticipants()
  const { incidentsUpdate } = useIncidents()
  const { getWithCurrentTeamSides } = useTeamSides()

  const participantIdToIncidentsTbdMap = getParticipantIdToIncidentsTbdMap(
    incidents.value,
  )

  const homeIncidentsTbd = ref(
    participantIdToIncidentsTbdMap[homeParticipant.value.id] ?? [],
  )

  const awayIncidentsTbd = ref(
    participantIdToIncidentsTbdMap[awayParticipant.value.id] ?? [],
  )

  watch(incidentsUpdate, (newVal) => {
    for (const incidentUpdate of newVal) {
      if (
        incidentUpdate.data.action === 'insert' &&
        incidentUpdate.data.confirmation === Confirmation.ToBeDone
      ) {
        if (
          incidentUpdate.data.participant_team_id === homeParticipant.value.id
        ) {
          homeIncidentsTbd.value.push(incidentUpdate.data)
        } else if (
          incidentUpdate.data.participant_team_id === awayParticipant.value.id
        ) {
          awayIncidentsTbd.value.push(incidentUpdate.data)
        }
      } else if (
        incidentUpdate.data.action === 'update' &&
        [Confirmation.Confirmed, Confirmation.Cancelled].includes(
          incidentUpdate.data.confirmation,
        )
      ) {
        if (
          incidentUpdate.data.participant_team_id === homeParticipant.value.id
        ) {
          const index = R.findLastIndex(
            (incident) => incident.id === incidentUpdate.data.id,
            homeIncidentsTbd.value,
          )

          if (index !== -1) {
            homeIncidentsTbd.value.splice(index, 1)
          }
        } else if (
          incidentUpdate.data.participant_team_id === awayParticipant.value.id
        ) {
          const index = R.findLastIndex(
            (incident) => incident.id === incidentUpdate.data.id,
            awayIncidentsTbd.value,
          )

          if (index !== -1) {
            awayIncidentsTbd.value.splice(index, 1)
          }
        }
      }
    }
  })

  const [
    currentFirstParticipantIncidentsTbd,
    currentSecondParticipantIncidentsTbd,
  ] = getWithCurrentTeamSides(homeIncidentsTbd, awayIncidentsTbd)

  return {
    currentFirstParticipantIncidentsTbd,
    currentSecondParticipantIncidentsTbd,
  }
}
