<script setup lang="ts">
import * as R from 'ramda'
import { Ref, ref, watch } from 'vue'

import { TeamSide } from '@collector/shared-ui-domain'
import { Incident } from '@collector/sportsapi-client'
import { IncidentAttributeId } from '@collector/sportsapi-client-legacy'
import { useSubParticipants } from '@desktop/views/Relation/composables'
import { ShouldLoadOppositeSubParticipants } from '@desktop/views/Relation/Sports/configuration'
import { SubParticipantOption } from '@desktop/views/Relation/utils'

import AttributesSelect from './AttributesSelect.vue'
import DisplayInfo from './DisplayInfo.vue'
import PlayerSelect from './PlayerSelect.vue'
import { useIncidentSettings } from './useIncidentSettings'
import { getLoadOppositeParticipants } from './utils'

interface Props {
  incident: Incident
  disabled: boolean
  teamSide: TeamSide | null
}

const props = defineProps<Props>()

// composables
const { awayPlayersOptions, homePlayersOptions } = useSubParticipants()
const players =
  props.teamSide === 'home' ? homePlayersOptions : awayPlayersOptions

const oppositePlayers =
  props.teamSide === 'home' ? awayPlayersOptions : homePlayersOptions

// inject
const {
  getSubParticipantSettings,
  attributeIds,
  singleAttributes,
  displayInfo,
} = useIncidentSettings(props.incident)

// data
const incidentParticipantSettings = getSubParticipantSettings('participant')
const incidentAssistantSettings = getSubParticipantSettings('assistant')

// state
const participantOptions: Ref<SubParticipantOption[]> = ref([])
const assistantOptions: Ref<SubParticipantOption[]> = ref([])

// methods
function getPlayersOptions(
  attributeIds: IncidentAttributeId[] | null,
  shouldLoadOppositeSubParticipants?: ShouldLoadOppositeSubParticipants,
): SubParticipantOption[] {
  return getLoadOppositeParticipants(
    attributeIds,
    shouldLoadOppositeSubParticipants,
  )
    ? oppositePlayers.value
    : players.value
}

function updateSubParticipantsOptions(
  attributeIds: IncidentAttributeId[] | null,
): void {
  participantOptions.value =
    incidentParticipantSettings?.filterSubParticipantsOptions?.(
      getPlayersOptions(
        attributeIds,
        incidentParticipantSettings?.shouldLoadOppositeSubParticipants,
      ),
    ) ?? []

  assistantOptions.value =
    incidentParticipantSettings?.filterSubParticipantsOptions?.(
      getPlayersOptions(
        attributeIds,
        incidentAssistantSettings?.shouldLoadOppositeSubParticipants,
      ),
    ) ?? []
}

// watchers
if (incidentParticipantSettings || incidentAssistantSettings) {
  /* update settings only if incident_id needs it */
  watch(
    () => props.incident.attribute_ids,
    (newVal, oldVal) => {
      if (!R.equals(newVal, oldVal)) {
        updateSubParticipantsOptions(newVal)
      }
    },
    { immediate: true },
  )
}

watch(
  () => [players.value, oppositePlayers.value],
  (newVal, oldVal) => {
    if (!R.equals(newVal, oldVal)) {
      updateSubParticipantsOptions(props.incident.attribute_ids)
    }
  },
)
</script>

<template>
  <PlayerSelect
    v-if="incidentParticipantSettings"
    :label="incidentParticipantSettings.label"
    :required="incidentParticipantSettings.required"
    :type="incidentParticipantSettings.type"
    :keepSelectedOption="incidentParticipantSettings.keepSelectedOption"
    :incident
    :disabled
    :options="participantOptions"
  />
  <PlayerSelect
    v-if="incidentAssistantSettings"
    :label="incidentAssistantSettings.label"
    :required="incidentAssistantSettings.required"
    :type="incidentAssistantSettings.type"
    :keepSelectedOption="incidentAssistantSettings.keepSelectedOption"
    :incident
    :disabled
    :options="assistantOptions"
  />
  <AttributesSelect
    v-if="attributeIds"
    :attributeIds
    :disabled
    :incident
    :singleAttributes
  />
  <DisplayInfo
    v-if="displayInfo !== undefined"
    :incident
    :displayInfo
    :disabled
  />
</template>
