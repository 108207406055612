<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue'

import { Button, hasOpenModal } from '@collector/shared-ui'
import { TeamSide } from '@collector/shared-ui-domain'
import { useEventSettings } from '@desktop/views/Relation/composables'
import { useBlockades } from '@desktop/views/Relation/Sports/composables'
import { injectPitchInteractionDisabledStatuses } from '@desktop/views/Relation/Sports/provide-inject'

// composables
const { eventSettings, updateEventSettings } = useEventSettings()
const { hasCurrentEventStatus } = useBlockades()

// inject
const pitchInteractionDisabledStatuses =
  injectPitchInteractionDisabledStatuses()

// methods
function changePossession(): void {
  const teamSidePossession: TeamSide =
    eventSettings.value.teamSidePossession === 'home' ? 'away' : 'home'

  updateEventSettings({ teamSidePossession })
}

function isChangePossessionBlocked(): boolean {
  return hasCurrentEventStatus(pitchInteractionDisabledStatuses)
}

function changePossessionOnSpace(e: KeyboardEvent): void {
  const focusedElement = document.activeElement

  if (
    e.code === 'Space' &&
    focusedElement?.tagName !== 'INPUT' &&
    focusedElement?.tagName !== 'TEXTAREA'
  ) {
    e.preventDefault()

    if (!isChangePossessionBlocked() && !hasOpenModal()) {
      changePossession()
    }
  }
}

// init
onMounted(() => {
  window.addEventListener('keydown', changePossessionOnSpace)
})

onUnmounted(() => {
  window.removeEventListener('keydown', changePossessionOnSpace)
})
</script>

<template>
  <Button
    class="enabled:hover:bg-error-6/60 text-neutral-dark-0 bg-neutral-light-2 border-neutral-light-2 font-bold enabled:hover:border-transparent"
    size="lg"
    width="full"
    :disabled="isChangePossessionBlocked()"
    @click="changePossession"
  >
    Change possession
  </Button>
</template>
