<script setup lang="ts">
import { computed, onUnmounted, ref } from 'vue'

import {
  AddIncident,
  EventIncidentsQueue,
  OnChangePayload,
  QueueIncident,
  QueueStatus,
} from '@collector/desktop-feature-incidents-queue-core'
import { Button } from '@collector/shared-ui'
import { GetIncident, IsIncidentImportant } from '@collector/shared-ui-domain'
import { Participant } from '@collector/sportsapi-client'
import { IncidentId, SportIncident } from '@collector/sportsapi-client-legacy'

import ListItem from './ListItem.vue'
import { getListItemData as _getListItemData, ListItemData } from './utils'

interface Props {
  eventIncidentsQueue: EventIncidentsQueue
  getIncident: GetIncident
  isIncidentImportant: IsIncidentImportant
  homeParticipant: Participant
  awayParticipant: Participant
  incidentIdToSportIncidentMap: Record<IncidentId, SportIncident>
}

const props = defineProps<Props>()

// state
const queueIncidents = ref<QueueIncident[]>([])
const queueStatus = ref<QueueStatus | null>(null)
const queueIncidentsErrorCount = ref(0)

// methods
function getListItemData(queueIncident: QueueIncident): ListItemData {
  const id =
    queueIncident instanceof AddIncident ? undefined : queueIncident.getId()
  const incident = id ? props.getIncident(id) : undefined

  return _getListItemData(
    queueIncident,
    incident,
    props.isIncidentImportant,
    props.homeParticipant,
    props.awayParticipant,
    props.incidentIdToSportIncidentMap,
  )
}

// computed
const canChangeQueue = computed(
  () => queueStatus.value !== 'processing' && queueIncidents.value.length > 0,
)

function onChange({
  queue,
  status,
  errorQueueIncidents,
}: OnChangePayload): void {
  queueIncidents.value = [...queue]
  queueStatus.value = status
  queueIncidentsErrorCount.value = errorQueueIncidents.length
}

// init
props.eventIncidentsQueue.subscribeOnChange(onChange)

onUnmounted(() => {
  props.eventIncidentsQueue.unsubscribeOnChange(onChange)
})
</script>

<template>
  <div class="w-[31.25rem]">
    <div class="flex">
      <div class="mx-0.5 h-16 flex-1 text-left">
        <div class="text-lg font-bold">
          Queue
          <span class="text-success-5">in progress</span>
        </div>
        <div
          v-if="queueIncidentsErrorCount && canChangeQueue"
          class="text-error-7 text-xs font-bold"
        >
          You have {{ queueIncidentsErrorCount }} Error{{
            queueIncidentsErrorCount > 1 ? 's' : ''
          }}. Click "Retry" to resend errored incidents.
        </div>
      </div>
      <div>
        <Button
          class="font-bold"
          :class="{
            'text-neutral-light-10 border-success-5 bg-success-5':
              canChangeQueue,
            'border-neutral-dark-10': !canChangeQueue,
          }"
          :disabled="!canChangeQueue"
          size="md"
          @click="eventIncidentsQueue.rerunQueue()"
        >
          <span class="px-5">Retry</span>
        </Button>
      </div>
    </div>
    <div
      v-if="queueIncidents.length"
      class="header-dropdown-scrollable-queue"
    >
      <ListItem
        v-for="(queueIncident, index) in queueIncidents"
        :key="index"
        v-bind="getListItemData(queueIncident)"
        class="pb-1"
        :eventIncidentsQueue
        :queueIncident
        :index
        :status="queueIncident.getStatus()"
        :disabled="!canChangeQueue"
      />
    </div>
  </div>
</template>
