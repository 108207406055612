<script setup lang="ts">
import { computed } from 'vue'

import { TeamSide } from '@collector/shared-ui-domain'
import { Confirmation, IncidentId } from '@collector/sportsapi-client-legacy'
import IncidentButton from '@desktop/views/Relation/components/IncidentButton.vue'
import { useTeamSides } from '@desktop/views/Relation/composables'
import {
  AddIncidentPayloadWithOptionalTime,
  useBlockades,
  useEventIncidentsQueue,
} from '@desktop/views/Relation/Sports/composables'
import { injectPitchIncidentsConfig } from '@desktop/views/Relation/Sports/provide-inject'

import { injectIncidentsRequireConfirmation } from '../provide-inject/incidentsRequireConfirmation'

interface Props {
  incidentId: IncidentId
  teamSide: TeamSide
  name?: string
  horizontal?: boolean
  position?: { x: number; y: number }
}

const props = defineProps<Props>()

// composables
const eventIncidentsQueue = useEventIncidentsQueue()
const { isIncidentBlocked } = useBlockades()
const { getParticipantTeamId } = useTeamSides()

// inject
const pitchIncidentsConfig = injectPitchIncidentsConfig()
const incidentsRequireConfirmation = injectIncidentsRequireConfirmation()

// state
const disabled = computed(() => isChangePossessionBlocked())

// methods
function isChangePossessionBlocked(): boolean {
  return isIncidentBlocked(
    props.incidentId,
    pitchIncidentsConfig.blockedPitchIncidentsMap,
  )
}

function onIncidentButtonClick(incidentId: number): void {
  const payload: AddIncidentPayloadWithOptionalTime = {
    incident_id: incidentId,
    participant_team_id: getParticipantTeamId(props.teamSide),
  }

  if (incidentsRequireConfirmation.includes(incidentId)) {
    payload.confirmation = Confirmation.ToBeDone
  }

  if (props.position) {
    payload.x_pos = props.position.x
    payload.y_pos = props.position.y
  }

  eventIncidentsQueue.addIncident(payload)
}
</script>

<template>
  <IncidentButton
    :class="{
      'flex-row !gap-1': horizontal,
      'flex-col !gap-0.5': !horizontal,
    }"
    size="md"
    width="full"
    :incidentId
    :teamSide
    :disabled
    :withActive="true"
    @click="onIncidentButtonClick"
  >
    <template #icon>
      <slot
        v-bind="{
          disabled,
        }"
        name="icon"
      />
    </template>
    <template #default="{ incidentName }">
      <span
        class="text-2xs text-wrap text-center leading-[1]"
        :class="horizontal ? '' : 'w-full'"
      >
        {{ name || incidentName }}
      </span>
    </template>
  </IncidentButton>
</template>
