<script setup lang="ts">
import * as R from 'ramda'
import { computed, ComputedRef, ref, watch, watchEffect } from 'vue'

import Sentry from '@collector/shared-plugin-sentry-vue'
import { MultiSelect, SuccessBadge } from '@collector/shared-ui'
import { Incident } from '@collector/sportsapi-client'
import { useSubParticipants } from '@desktop/views/Relation/composables'
import { useEventIncidentsQueue } from '@desktop/views/Relation/Sports/composables'
import { SubParticipantType } from '@desktop/views/Relation/Sports/configuration'
import {
  SubParticipantOption,
  SubParticipantOptionValue,
} from '@desktop/views/Relation/utils'

import { isOptionMatched } from '../../utils'

type SubParticipantValue = Omit<SubParticipantOptionValue, 'teamId'> | undefined

// props
interface Props {
  disabled: boolean
  incident: Incident
  label: string
  options: SubParticipantOption[]
  required: boolean
  type: SubParticipantType
  keepSelectedOption?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  keepSelectedOption: false,
})

// composables
const eventIncidentsQueue = useEventIncidentsQueue()
const { homePlayersOptions, awayPlayersOptions } = useSubParticipants()

// data
const propertyId =
  props.type === 'participant' ? 'participant_id' : 'assistant_id'

const propertyName =
  props.type === 'participant' ? 'participant_name' : 'assistant_name'

// state
const subParticipantValue = ref(
  getSubparticipantValue(
    props.incident[propertyName],
    props.incident[propertyId],
  ),
)

const successBadge = ref<InstanceType<typeof SuccessBadge> | null>(null)

// computed
type OptionWithoutTeamId = Omit<SubParticipantOption, 'value'> & {
  value: Omit<SubParticipantOptionValue, 'teamId'>
}

type OptionsWithoutTeamId = OptionWithoutTeamId[]

const optionsWithoutTeamId: ComputedRef<OptionsWithoutTeamId> = computed(() => {
  const options = props.options.map((option) => {
    const { teamId, ...rest } = option.value
    return { ...option, value: rest }
  })

  if (props.keepSelectedOption) {
    appendCurrentSelectedOption(options)
  }

  return options
})

// methods
function appendCurrentSelectedOption(options: OptionsWithoutTeamId): void {
  const isSubParticipantNotSelected =
    subParticipantValue.value &&
    !options.some((option) => option.value.id === subParticipantValue.value?.id)

  if (isSubParticipantNotSelected) {
    options.unshift(prepareCurrentSelectedOption())
  }
}

function prepareCurrentSelectedOption(): OptionWithoutTeamId {
  const subParticipantOption = [
    ...homePlayersOptions.value,
    ...awayPlayersOptions.value,
  ].find((player) => player.value.id === subParticipantValue.value?.id)!

  const metadata = subParticipantOption.metadata

  return {
    name: `${metadata.shirtNr} ${subParticipantValue.value?.shortName}`.trim(),
    value: {
      shortName: subParticipantValue.value?.shortName ?? '',
      id: subParticipantValue.value?.id ?? null,
    },
    metadata,
  }
}

function getSubparticipantValue(
  shortName: string | null,
  id: number | null,
): SubParticipantValue {
  if (shortName === null && id === null) return undefined

  return {
    shortName,
    id,
  }
}

function updatePlayer(value: SubParticipantValue): void {
  const payload = {
    [propertyId]: value?.id ?? null,
    [propertyName]: value?.shortName ?? null,
    participant_team_id: props.incident.participant_team_id,
  }

  eventIncidentsQueue.updateIncident(payload, props.incident)

  subParticipantValue.value = value
}

// watchers
watch(
  [() => props.incident[propertyName], () => props.incident[propertyId]],
  ([newName, newId]) => {
    // updated by desktop
    if (
      R.equals(
        subParticipantValue.value,
        getSubparticipantValue(newName, newId),
      )
    ) {
      successBadge.value?.show()
    }

    // updated by other client
    subParticipantValue.value = getSubparticipantValue(newName, newId)
  },
)

// TODO: Remove watchEffect below - used for debug of COLL-1154
watchEffect(() => {
  if (subParticipantValue.value) {
    const selectedFromOptions = optionsWithoutTeamId.value.find((option) => {
      return R.equals(option.value, subParticipantValue.value)
    }) //
    if (!selectedFromOptions) {
      const msg =
        'PlayerSelect - selected value is not included in provided options'
      // eslint-disable-next-line no-console
      console.warn(msg, subParticipantValue.value)
      Sentry.captureMessage(msg, {
        level: 'warning',
        contexts: {
          Data: {
            selected: subParticipantValue.value,
            options: optionsWithoutTeamId.value.map((o) => o.value),
          },
        },
      })
    }
  }
})
</script>

<template>
  <MultiSelect
    :options="optionsWithoutTeamId"
    size="xs"
    :disabled
    :required
    withFilter
    withOptionsFullWidth
    :filterPredicate="isOptionMatched"
    :modelValue="subParticipantValue"
    @update:modelValue="(value) => updatePlayer(value as SubParticipantValue)"
  >
    <template #option="{ option }">
      <div class="whitespace-nowrap">
        {{ option.value.shortName }}
      </div>
      <div class="text-2xs">
        {{ option.metadata.position }}
        <span
          v-if="option.metadata.shirtNr"
          class="font-bold"
        >
          {{ option.metadata.shirtNr }}
        </span>
      </div>
    </template>
    <div class="flex w-full">
      <span class="w-full">
        {{ label }}
      </span>
      <SuccessBadge
        ref="successBadge"
        class="pr-2.5"
      />
    </div>
  </MultiSelect>
</template>
