import { onMounted, onUnmounted, ref, Ref } from 'vue'

export function useScrollPositionFixed(): { element: Ref<HTMLElement | null> } {
  const element = ref<HTMLElement | null>(null)

  function setStyleTop(): void {
    element.value!.style.top = `${window.scrollY}px`
  }

  onMounted(() => {
    setStyleTop()

    window.addEventListener('scroll', setStyleTop)
  })

  onUnmounted(() => {
    window.removeEventListener('scroll', setStyleTop)
  })

  return { element }
}
