<script setup lang="ts">
import { FlagChip } from '@collector/shared-ui'

import { Player } from '../types'
import { isCaptain } from '../utils'

interface Props {
  player: Player
}
defineProps<Props>()

type Emits = {
  captainSet: []
}

const emit = defineEmits<Emits>()
</script>

<template>
  <FlagChip
    size="sm"
    :variant="isCaptain(player) ? 'fill' : 'outlined'"
    :modelValue="true"
    @update:modelValue="emit('captainSet')"
  >
    <span class="font-bold">C</span>
  </FlagChip>
</template>
