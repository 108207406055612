import { hasAccessToEvent } from '@collector/sportsapi-client'
import { EventScout, Sport } from '@collector/sportsapi-client-legacy'
import { useAuth } from '@desktop/globalState/userAuth/useAuth'

import { isSportImplemented } from '../Sports/utils'

// TODO: move to auth(orization) module
export function validateAssignedScouts(assignedScouts: EventScout[]): void {
  const { authInfo } = useAuth()
  if (!authInfo.value || !hasAccessToEvent(authInfo.value, assignedScouts))
    throw Error('You are not assigned to this relation')
}

export function validateRelationAccess(
  sport: Sport,
  assignedScouts: EventScout[],
): void {
  if (!isSportImplemented(sport.id)) {
    throw Error(`Unsupported sport: "${sport.name}" (id = ${sport.id})`)
  }

  validateAssignedScouts(assignedScouts)
}
