import { ApiResponse } from '@collector/sportsapi-client-legacy'

import { HttpClient } from '../../httpClient'
import { SeasonSquadParticipant } from './types'

export type ParticipantSquadGetBySeasonIdResponse = ApiResponse<{
  participants: SeasonSquadParticipant[]
}>

export async function getBySeasonId(
  httpClient: HttpClient,
  participantId: number,
  seasonId: number,
): Promise<ParticipantSquadGetBySeasonIdResponse> {
  return httpClient
    .url(
      `/participants/${participantId}/squad?season_id=${seasonId}&team_connection=current`,
    )
    .get()
    .json()
}
