import * as R from 'ramda'
import { Ref } from 'vue'

import { QueueRabbitClient } from '@collector/queue-gateway-shared-client'
import { RabbitEventMessage } from '@collector/queue-gateway-shared-types'
import { formatDateInTimezone } from '@collector/shared-utils'
import {
  Event,
  Participant,
  Stat,
  StatId,
  SubParticipant,
} from '@collector/sportsapi-client-legacy'

import { SyncedTimestamp } from '../syncedTimestamp'

function updateEvent(event: Event, eventUpdate: Event): void {
  for (const _key in eventUpdate) {
    const key = _key as keyof Event

    if (!R.equals(event[key], eventUpdate[key])) {
      // start-date must be re-formatted to match start_date from API response (which uses Timezone)
      const value =
        key === 'start_date'
          ? formatDateInTimezone(eventUpdate[key] + 'Z', 'yyyy-MM-dd HH:mm')
          : eventUpdate[key]

      event[key] = value as never
    }
  }
}

function updateSubParticipants(
  subParticipants: SubParticipant[],
  eventUpdate: Event,
): SubParticipant[] {
  const newSubParticipants = eventUpdate.participants
    .map((x) => x.subparticipants as SubParticipant[])
    .flat()

  return [
    ...subParticipants.map((oldSubParticipant) => {
      const updatedSubParticipant = newSubParticipants.find(
        (x) =>
          x.id === oldSubParticipant.id ||
          x.short_name === oldSubParticipant.short_name,
      )

      if (!updatedSubParticipant || !oldSubParticipant.stats) {
        return oldSubParticipant
      }

      const updatedStatsMap = updatedSubParticipant.stats?.reduce(
        (acc, stat) => {
          acc[stat.id] = stat
          return acc
        },
        {} as Record<StatId, Stat>,
      )

      return {
        ...oldSubParticipant,
        ...updatedSubParticipant,
        id: oldSubParticipant.id, // keep the old id, overwritten breaks bulk stats update
        stats: oldSubParticipant.stats.map((oldStat) => {
          if (!updatedStatsMap) {
            return oldStat
          }

          const updatedStat = updatedStatsMap[oldStat.id]

          if (!updatedStat) {
            return oldStat
          }

          return {
            ...oldStat,
            ...updatedStat,
          }
        }),
      }
    }),
  ]
}

export function updateEventOnConnect(
  queueRabbitClient: QueueRabbitClient,
  event: Event,
  eventTimestamp: Ref<SyncedTimestamp>,
  participants: Ref<Participant[]>,
  subParticipants: Ref<SubParticipant[]>,
): void {
  queueRabbitClient.subEvent<RabbitEventMessage>({
    onMessage: (message) => {
      if (!import.meta.env.DEV) {
        // eslint-disable-next-line
        console.debug('[queueRabbitClient] event message received', message)
      }
      updateEvent(event, message.data)
      eventTimestamp.value.timestamp = message.ut

      if (!R.equals(event.participants, participants.value)) {
        participants.value = event.participants
      }

      subParticipants.value = updateSubParticipants(
        subParticipants.value,
        message.data,
      )
    },
    sync: { method: 'latest' },
  })
}
