import { Ref, ref, watch } from 'vue'

import { hasIncidentPosition, TeamSide } from '@collector/shared-ui-domain'
import { Confirmation } from '@collector/sportsapi-client-legacy'
import { Incident } from '@collector/sportsapi-types'
import { IncidentsRequirePointingPosition } from '@desktop/utils/database'
import {
  useEvent,
  useEventSettings,
  useIncidents,
  useParticipants,
} from '@desktop/views/Relation/composables'
import {
  UpdateIncidentPayloadWithoutIncidentId,
  useEventIncidentsQueue,
} from '@desktop/views/Relation/Sports/composables'
import {
  injectIncidentsRequirePointingPosition,
  injectPitchInteractionDisabledStatuses,
} from '@desktop/views/Relation/Sports/provide-inject'

import { getPercentagePositionWithTeamSideChanged } from '../../Field/utils'

export type UseIncidentsRequirePointingPosition = {
  currentIncidentsWithoutPosition: Ref<Incident[]>
  init: () => Promise<void>
  saveIncidentPosition: (
    incidentToUpdate: Incident,
    clickedPosition: { x: number; y: number },
  ) => Promise<void>
}

export function useIncidentsRequirePointingPosition(): UseIncidentsRequirePointingPosition {
  // composables
  const incidentsRequirePointingPositionConfig =
    injectIncidentsRequirePointingPosition()
  const pitchInteractionDisabledStatuses =
    injectPitchInteractionDisabledStatuses()
  const { incidents, userIncidentsUpdate } = useIncidents()
  const { event } = useEvent()
  const { homeParticipant } = useParticipants()
  const eventIncidentsQueue = useEventIncidentsQueue()
  const { eventSettings } = useEventSettings()

  // data
  const incidentsRequirePointingPositionSet = new Set(
    incidentsRequirePointingPositionConfig.map(({ incidentId }) => incidentId),
  )

  // state
  const currentIncidentsWithoutPosition = ref<Incident[]>([])

  // methods
  function storeIncidentIfRequired(incident: Incident): void {
    if (incidentsRequirePointingPositionSet.has(incident.incident_id)) {
      currentIncidentsWithoutPosition.value = [
        ...currentIncidentsWithoutPosition.value,
        incident,
      ]

      IncidentsRequirePointingPosition.upsert(event.value.id, incident.id)
    }
  }

  function deleteIncidentFromState(incident: Incident): void {
    const incidentIndex = currentIncidentsWithoutPosition.value.findIndex(
      ({ id }) => id === incident.id,
    )

    if (incidentIndex !== -1) {
      currentIncidentsWithoutPosition.value.splice(incidentIndex, 1)

      IncidentsRequirePointingPosition.deleteIncident(
        event.value.id,
        incident.id,
      )
    }
  }

  async function init(): Promise<void> {
    const incidentsRequirePointingPositionRecord =
      await IncidentsRequirePointingPosition.get(event.value.id)

    if (incidentsRequirePointingPositionRecord) {
      const { incidents: currentIncidents } =
        incidentsRequirePointingPositionRecord

      currentIncidentsWithoutPosition.value = incidents.value.filter(({ id }) =>
        currentIncidents?.includes(id),
      )
    }

    watch(userIncidentsUpdate, (incidentMessages) => {
      for (const incidentMessage of incidentMessages) {
        if (
          pitchInteractionDisabledStatuses.has(incidentMessage.data.status_id)
        ) {
          continue
        }

        switch (incidentMessage.data.action) {
          case 'delete':
            deleteIncidentFromState(incidentMessage.data)
            break
          case 'update':
            if (
              hasIncidentPosition(incidentMessage.data) ||
              incidentMessage.data.confirmation === Confirmation.Cancelled
            ) {
              deleteIncidentFromState(incidentMessage.data)
            }
            break
          case 'insert':
            storeIncidentIfRequired(incidentMessage.data)
            break
        }
      }
    })
  }

  // methods
  async function saveIncidentPosition(
    incidentToUpdate: Incident,
    clickedPosition: {
      x: number
      y: number
    },
  ): Promise<void> {
    const teamSide: TeamSide =
      incidentToUpdate.participant_team_id === homeParticipant.value.id
        ? 'home'
        : 'away'
    const positionWithTeamSidesChanged =
      getPercentagePositionWithTeamSideChanged(
        clickedPosition,
        !!eventSettings.value.teamSideChanged,
      )
    const position = incidentsRequirePointingPositionConfig
      .find(({ incidentId }) => incidentId === incidentToUpdate.incident_id)!
      .transformPosition(positionWithTeamSidesChanged, teamSide)
    const payload: UpdateIncidentPayloadWithoutIncidentId = {
      x_pos: position.x,
      y_pos: position.y,
    }

    deleteIncidentFromState(incidentToUpdate)

    eventIncidentsQueue.updateIncident(payload, incidentToUpdate)
  }

  return {
    init,
    saveIncidentPosition,
    currentIncidentsWithoutPosition,
  }
}
