import { isHttpError } from '@collector/shared-utils'
import { SportsApiClient } from '@collector/sportsapi-client'

import {
  AddIncident,
  AddIncidentPayload,
  DeleteIncident,
  UpdateIncident,
  UpdateIncidentPayload,
} from './incidents'
import {
  ConfirmQueueIncident,
  OnChange,
  OnChangePayload,
  QueueIncident,
  QueueStatus,
} from './types'
import {
  confirmProcessedQueueIncident,
  dequeue,
  enqueue,
  getErrorQueueIncidents,
  peek,
  setError,
  updatePayload,
} from './utils'

export class EventIncidentsQueue {
  eventId: number
  sportsApiClient: SportsApiClient
  queue: QueueIncident[] = []
  queueStatus: QueueStatus = 'idle'

  onChangeCallbacks: OnChange[] = []

  confirmQueueIncident: ConfirmQueueIncident

  /**
   * Error which occured during processing last incident; if null, last incident was processed successfully
   */
  private currentError: unknown = null

  constructor(eventId: number, sportsApiClient: SportsApiClient) {
    this.eventId = eventId
    this.sportsApiClient = sportsApiClient
  }

  /**
   * Set confirmation function which acknowledges that incident was successfully processed by API,
   * or executes callback with error if not processed successfully.
   */
  public setConfirmQueueIncident(
    confirmQueueIncident: ConfirmQueueIncident,
  ): void {
    this.confirmQueueIncident = confirmQueueIncident
  }

  /**
   * Enqueue incident which creates Incident in API
   */
  public addIncident(payload: AddIncidentPayload): void {
    // TODO: remove this calculation when Team API will fix the issue
    updatePayload(payload)

    this.enqueueIncident(new AddIncident(this.eventId, payload))
  }

  /**
   * Enqueue incident which updates existing Incident in API
   */
  public updateIncident(
    payload: UpdateIncidentPayload,
    id: UpdateIncident['id'],
  ): void {
    // TODO: remove this calculation when Team API will fix the issue
    updatePayload(payload)

    this.enqueueIncident(new UpdateIncident(this.eventId, payload, id))
  }

  /**
   * Enqueue incident which marks existing Incident as deleted in API
   */
  public deleteIncident(id: DeleteIncident['id']): void {
    this.enqueueIncident(new DeleteIncident(this.eventId, id))
  }

  /**
   * Remove incident from queue to prevent processing
   */
  public removeQueueIncident(index: number): void {
    dequeue(this.queue, index)
    this.onChange()
  }

  /**
   * Add callback to be called on queue change
   */
  public subscribeOnChange(callback: OnChange): void {
    this.onChangeCallbacks.push(callback)

    this.runOnChange(callback, this.getOnChangePayload())
  }

  /**
   * Removed callback passed to `subscribeOnChange`
   */
  public unsubscribeOnChange(callback: OnChange): void {
    const index = this.onChangeCallbacks.indexOf(callback)

    if (index !== -1) {
      this.onChangeCallbacks.splice(index, 1)
    }
  }

  /**
   * Process all incidents in queue from beginning, including incidents with error
   */
  public rerunQueue(): Promise<void> {
    if (this.queueStatus === 'idle') {
      return this.startProcessing(true)
    } else {
      throw Error('Cannot rerun when queue is not idle')
    }
  }

  /**
   * Process single incident from queue, by incident index
   */
  public async startProcessingQueueIncident(index: number): Promise<void> {
    if (this.queueStatus === 'idle') {
      this.changeQueueStatus('processing')

      const queueIncident = peek(this.queue, index)

      try {
        await this.processQueueIncident(queueIncident)
        dequeue(this.queue, index)
      } catch (error) {
        setError(queueIncident, error)
        this.currentError = error
      } finally {
        this.changeQueueStatus('idle')
      }
    } else {
      throw Error('Cannot process when queue is not idle')
    }
  }

  private async processQueueIncident(
    queueIncident: QueueIncident,
  ): Promise<void> {
    queueIncident.setStatus('In Progress')
    queueIncident.error = null
    this.onChange()

    try {
      const uuid = await queueIncident.process(this.sportsApiClient)
      await confirmProcessedQueueIncident(uuid, this.confirmQueueIncident)
    } catch (error) {
      if (!isHttpError(error, 409)) {
        throw error
      }
    }
  }

  private runOnChange(callback: OnChange, payload: OnChangePayload): void {
    callback(payload)
  }

  private getOnChangePayload(): OnChangePayload {
    const currentError = this.currentError

    if (currentError) {
      this.currentError = null
    }

    return {
      queue: this.queue,
      status: this.queueStatus,
      errorQueueIncidents: getErrorQueueIncidents(this.queue),
      currentError,
    }
  }

  private onChange(): void {
    const payload = this.getOnChangePayload()

    for (const callback of this.onChangeCallbacks) {
      this.runOnChange(callback, payload)
    }
  }

  private enqueueIncident(incident: QueueIncident): void {
    enqueue(incident, this.queue)

    if (this.queueStatus === 'idle') {
      this.startProcessing(false)
    } else {
      this.onChange()
    }
  }

  private changeQueueStatus(value: QueueStatus): void {
    this.queueStatus = value
    this.onChange()
  }

  public async startProcessing(
    shoulProcessErrorQueueIncident: boolean,
  ): Promise<void> {
    this.changeQueueStatus('processing')

    let currentProcessedIndex = 0

    // while there are non-error incidents in the queue
    while (currentProcessedIndex < this.queue.length) {
      const queueIncident = peek(this.queue, currentProcessedIndex)

      if (
        queueIncident.getStatus() === 'Error' &&
        !shoulProcessErrorQueueIncident
      ) {
        currentProcessedIndex++
        continue
      }

      try {
        await this.processQueueIncident(queueIncident)
        dequeue(this.queue, currentProcessedIndex)
      } catch (error) {
        setError(queueIncident, error)
        this.currentError = error
        currentProcessedIndex++
      }

      if (currentProcessedIndex < this.queue.length) {
        this.onChange()
      }
    }

    this.changeQueueStatus('idle')
  }
}
