import {
  isInternetDisconnectedError,
  isWretchError,
  NetworkError,
} from '@collector/shared-utils'
import { ApiErrorResponse } from '@collector/sportsapi-client-legacy'

import type { HttpClient } from '../httpClient'
import { isSportsApiUrl } from './utils'

export class SportsApiError extends Error {
  status: number

  constructor(message: string, status: number, options?: ErrorOptions) {
    super(message, options)
    this.status = status
    this.name = 'SportsAPI Error'
  }
}

export function withErrorCatcher(httpClient: HttpClient): HttpClient {
  return httpClient.catcherFallback((error) => {
    if (isInternetDisconnectedError(error)) {
      throw new NetworkError('Check your internet connection and try again.', {
        cause: error,
      })
    }

    if (isWretchError(error)) {
      if (isSportsApiUrl(error.url)) {
        const message = error.json
          ? (error.json as ApiErrorResponse).api.error.message
          : error.text

        throw new SportsApiError(message || '', error.status, { cause: error })
      }

      const message = error.json ? error.json : error.text
      throw new NetworkError(message, { cause: error })
    }

    throw error
  })
}
