import {
  Event,
  Participant,
  SubParticipantPosition,
} from '@collector/sportsapi-client-legacy'

import { HttpClient } from '../../httpClient'

export type SubParticipantsSavePostSquad = {
  player_position?: number
  position?: SubParticipantPosition
  captain?: boolean
  id?: number
  shirt_nr?: string | number
  short_name?: string
}

/**
 * Endpoint for replacing the whole squad (subparticipants for participant in event) in single request.
 */
export async function post(
  httpClient: HttpClient,
  eventId: Event['id'],
  participantId: Participant['id'],
  squad: SubParticipantsSavePostSquad[],
): Promise<unknown> {
  return httpClient
    .url(`/events/${eventId}/sub-participants-save`)
    .post({ team_id: participantId, squad })
    .json()
}
