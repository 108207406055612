import { EventIncidentsQueue } from '@collector/desktop-feature-incidents-queue-core'
import Sentry, {
  createIssueTitleExtra,
} from '@collector/shared-plugin-sentry-vue'
import { NetworkError } from '@collector/shared-utils'

export function captureQueueIncidentErrors(
  eventIncidentsQueue: EventIncidentsQueue,
): void {
  eventIncidentsQueue.subscribeOnChange(({ currentError }) => {
    if (currentError) {
      // Queue error caused by NetworkError will be grouped in single issue on Sentry
      const extra =
        currentError instanceof NetworkError
          ? createIssueTitleExtra('Queue error: NetworkError')
          : createIssueTitleExtra('Queue error:', {
              withContextPageTitle: true,
            })

      Sentry.captureException(currentError, extra)
    }
  })
}
