import { Ref } from 'vue'

import { createProvideInject } from '@collector/shared-ui'
import { Event } from '@collector/sportsapi-client-legacy'

import { SyncedTimestamp } from '../utils'

export const [provideEvent, injectEvent] =
  createProvideInject<Ref<Event>>('event')

export const [provideEventTimestamp, injectEventTimestamp] =
  createProvideInject<Ref<SyncedTimestamp>>('eventTimestamp')
