<script setup lang="ts">
import { computed } from 'vue'

import { Icon } from '@collector/shared-ui'
import { getEventTime } from '@collector/shared-ui-domain'
import { useSportMaps, useTeamSides } from '@desktop/views/Relation/composables'
import { useBetIncidents } from '@desktop/views/Relation/Sports/composables'

import { DisplayedIncident } from './types'

// props
type Props = {
  status: 'pending' | 'success' | 'error'
  incident: DisplayedIncident
}

const props = defineProps<Props>()

// composable
const { getIncidentTeamSide } = useTeamSides()
const { getIncidentName } = useSportMaps()
const { isIncidentEmergencyOrBet } = useBetIncidents()

// data
const incidentTeamSide = computed(() => getIncidentTeamSide(props.incident))
const incidentImportant = computed(() =>
  isIncidentEmergencyOrBet(props.incident),
)
const incidentName = computed(() => getIncidentName(props.incident.incident_id))
</script>

<template>
  <div
    class="text-neutral-dark-0 shadow-simple-shadow @[10rem]/handy-info-bar:max-w-[20cqw] @[15rem]/handy-info-bar:max-w-[50cqw] @[24rem]/handy-info-bar:max-w-[70cqw] @[30rem]/handy-info-bar:max-w-[70cqw] flex items-center text-nowrap rounded border px-1.5 text-xs font-bold"
    :class="{
      'border-current-team-home-5 bg-current-team-home-9':
        incidentTeamSide === 'home',
      'border-current-team-away-5 bg-current-team-away-9':
        incidentTeamSide === 'away',
      'border-neutral-light-3 bg-neutral-light-10':
        incidentTeamSide === null && !incidentImportant,
      'border-error-5 bg-error-5/50': incidentImportant,
    }"
  >
    <div class="flex w-full">
      <div class="mr-0.5 min-w-10">
        {{
          getEventTime({
            minute: incident.minute,
            second: incident.second,
          })
        }}
      </div>
      <div
        class="@[10rem]/handy-info-bar:max-w-[10cqw] @[17rem]/handy-info-bar:max-w-[25cqw] @[24rem]/handy-info-bar:max-w-[50cqw] @[30rem]/handy-info-bar:max-w-[70cqw] w-full max-w-[40cqw] overflow-hidden text-ellipsis"
      >
        {{ incidentName }}
      </div>
    </div>
    <div class="flex w-5 justify-end">
      <Icon
        v-if="status === 'pending'"
        class="animate-spin"
        icon="rotate-right"
        size="lg"
      />
      <Icon
        v-if="status === 'success'"
        icon="check"
        size="lg"
      />
      <Icon
        v-if="status === 'error'"
        class="text-error-5"
        icon="warning"
        size="lg"
      />
    </div>
  </div>
</template>
