<script setup lang="ts">
import { useSeason } from '@desktop/views/Relation/composables'

import { Player } from './types'
import { getBirthdateText } from './utils'

interface Props {
  player: Player
}

defineProps<Props>()

const { isPlayerIdExpected } = useSeason()
</script>

<template>
  <span
    class="w-full"
    :class="{
      italic: !player.id && !isPlayerIdExpected(),
    }"
    :title="
      player.details ? getBirthdateText(player.details.birthdate) : undefined
    "
  >
    <slot />
    {{ player.short_name }}
  </span>
</template>
