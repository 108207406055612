import {
  AttackAttribute,
  DangerousAttackAttribute,
  FoulAttribute,
  FreeKickAttribute,
  InPossessionAttribute,
  MissedPenaltyAttribute,
  PenaltySavedByGoalkeeperAttribute,
  RedCardAttribute,
  ShotBlockedAttribute,
  ShotOffTargetAttribute,
  ShotOnTargetAttribute,
  SoccerEventStatusId,
  SoccerGoalAttribute,
  SoccerIncidentId,
  SoccerPersonStatId,
  VideoAssistantRefereeAttribute,
  YellowCardAttribute,
} from '@collector/sportsapi-client-legacy'
import { SubParticipantOption } from '@desktop/views/Relation/utils'

import { HistoryIncidentsConfig } from '../../../configuration'

function isSubParticipantOnBench(option: SubParticipantOption): boolean {
  return (
    option.metadata.stats[SoccerPersonStatId.OnBench] === 1 &&
    option.metadata.stats[SoccerPersonStatId.Appearance] === 0
  )
}

export const historyIncidentsConfig: HistoryIncidentsConfig<SoccerIncidentId> =
  {
    [SoccerIncidentId.SubstitutionIn]: {
      participant: {
        name: 'in',
        required: true,
        keepSelectedOption: true,
        filterSubParticipantsOptions: (options) => {
          return options.filter(isSubParticipantOnBench)
        },
      },
    },
    [SoccerIncidentId.SubstitutionOut]: {
      participant: {
        name: 'out',
        required: true,
        keepSelectedOption: true,
        filterSubParticipantsOptions: (options) => {
          return options.filter((option) => !isSubParticipantOnBench(option))
        },
      },
    },
    [SoccerIncidentId.Goal]: {
      participant: {
        name: 'scorer',
        shouldLoadOppositeSubParticipants: (attributeIds) =>
          attributeIds.includes(SoccerGoalAttribute.OwnGoal),
        required: true,
      },
      assistant: (incident) => {
        switch (incident.status_id) {
          case SoccerEventStatusId.PenaltyShootout:
            return undefined
          default:
            return {
              name: 'assistant',
              shouldLoadOppositeSubParticipants: (attributeIds) =>
                attributeIds.includes(SoccerGoalAttribute.OwnGoal),
            }
        }
      },
      attributeIds: (incident) => {
        switch (incident.status_id) {
          case SoccerEventStatusId.PenaltyShootout:
            return undefined
          default:
            return [
              SoccerGoalAttribute.PenaltyGoal,
              SoccerGoalAttribute.OwnGoal,
              SoccerGoalAttribute.LeftFoot,
              SoccerGoalAttribute.RightFoot,
              SoccerGoalAttribute.Head,
              SoccerGoalAttribute.OtherBodyPart,
              SoccerGoalAttribute.InsidePenaltyArea,
              SoccerGoalAttribute.OutsidePenaltyArea,
              SoccerGoalAttribute.UpperLeft,
              SoccerGoalAttribute.UpperMiddle,
              SoccerGoalAttribute.UpperRight,
              SoccerGoalAttribute.BottomLeft,
              SoccerGoalAttribute.BottomMiddle,
              SoccerGoalAttribute.BottomRight,
              SoccerGoalAttribute.Crossbar,
              SoccerGoalAttribute.Post,
              SoccerGoalAttribute.OneOnOne,
              SoccerGoalAttribute.Volley,
              SoccerGoalAttribute.Chip,
              SoccerGoalAttribute.BicycleKick,
              SoccerGoalAttribute.PowerShot,
            ]
        }
      },
    },
    [SoccerIncidentId.Penalty]: {
      participant: {
        name: 'penalty taker',
        required: true,
      },
      assistant: 'penalty winner',
    },
    [SoccerIncidentId.MissedPenalty]: {
      participant: {
        name: 'executor',
        required: true,
      },
      attributeIds: [
        MissedPenaltyAttribute.LeftFoot,
        MissedPenaltyAttribute.RightFoot,
        MissedPenaltyAttribute.ShotWoodwork,
      ],
      swappable: (incident) =>
        incident.status_id !== SoccerEventStatusId.PenaltyShootout,
    },
    [SoccerIncidentId.PenaltySavedByGoalkeeper]: {
      participant: {
        name: 'executor',
        required: true,
      },
      attributeIds: [
        PenaltySavedByGoalkeeperAttribute.LeftFoot,
        PenaltySavedByGoalkeeperAttribute.RightFoot,
        PenaltySavedByGoalkeeperAttribute.UpperLeft,
        PenaltySavedByGoalkeeperAttribute.UpperMiddle,
        PenaltySavedByGoalkeeperAttribute.UpperRight,
        PenaltySavedByGoalkeeperAttribute.BottomLeft,
        PenaltySavedByGoalkeeperAttribute.BottomMiddle,
        PenaltySavedByGoalkeeperAttribute.BottomRight,
      ],
    },
    [SoccerIncidentId.RedCard]: {
      participant: {
        name: 'given',
        required: true,
      },
      attributeIds: [
        RedCardAttribute.SecondYellowCard,
        RedCardAttribute.OffField,
      ],
    },
    [SoccerIncidentId.YellowCard]: {
      participant: {
        name: 'given',
        required: true,
      },
      attributeIds: [YellowCardAttribute.OffField],
    },
    [SoccerIncidentId.ShotOnTarget]: {
      participant: 'taken by',
      assistant: {
        name: 'goal line clearer',
        shouldLoadOppositeSubParticipants: true,
      },
      attributeIds: [
        ShotOnTargetAttribute.GoallineClearance,
        ShotOnTargetAttribute.LeftFoot,
        ShotOnTargetAttribute.RightFoot,
        ShotOnTargetAttribute.Head,
        ShotOnTargetAttribute.InsidePenaltyArea,
        ShotOnTargetAttribute.OutsidePenaltyArea,
        ShotOnTargetAttribute.UpperLeft,
        ShotOnTargetAttribute.UpperMiddle,
        ShotOnTargetAttribute.UpperRight,
        ShotOnTargetAttribute.BottomLeft,
        ShotOnTargetAttribute.BottomMiddle,
        ShotOnTargetAttribute.BottomRight,
        ShotOnTargetAttribute.OneOnOne,
        ShotOnTargetAttribute.Volley,
        ShotOnTargetAttribute.Chip,
        ShotOnTargetAttribute.BicycleKick,
        ShotOnTargetAttribute.PowerShot,
      ],
    },
    [SoccerIncidentId.ShotOffTarget]: {
      participant: 'taken by',
      attributeIds: [
        ShotOffTargetAttribute.LeftFoot,
        ShotOffTargetAttribute.RightFoot,
        ShotOffTargetAttribute.Head,
        ShotOffTargetAttribute.InsidePenaltyArea,
        ShotOffTargetAttribute.OutsidePenaltyArea,
        ShotOffTargetAttribute.ShotWoodwork,
        ShotOffTargetAttribute.Crossbar,
        ShotOffTargetAttribute.Post,
        ShotOffTargetAttribute.OneOnOne,
        ShotOffTargetAttribute.Volley,
        ShotOffTargetAttribute.Chip,
        ShotOffTargetAttribute.BicycleKick,
      ],
    },
    [SoccerIncidentId.ShotBlocked]: {
      participant: 'taken by',
      assistant: {
        name: 'blocked by',
        shouldLoadOppositeSubParticipants: true,
      },
      attributeIds: [
        ShotBlockedAttribute.LeftFoot,
        ShotBlockedAttribute.RightFoot,
        ShotBlockedAttribute.Head,
        ShotBlockedAttribute.InsidePenaltyArea,
        ShotBlockedAttribute.OutsidePenaltyArea,
        ShotBlockedAttribute.Volley,
        ShotBlockedAttribute.Chip,
        ShotBlockedAttribute.BicycleKick,
      ],
    },
    [SoccerIncidentId.Foul]: {
      participant: 'foul by',
      attributeIds: [FoulAttribute.CausesPenalty],
    },
    [SoccerIncidentId.FreeKick]: {
      attributeIds: [FreeKickAttribute.DangerousFreeKick],
    },
    [SoccerIncidentId.Offside]: {
      participant: 'caught',
    },
    [SoccerIncidentId.Injury]: { participant: 'player' },
    [SoccerIncidentId.PossibleVAR]: {
      attributeIds: [
        VideoAssistantRefereeAttribute.PossibleGoal,
        VideoAssistantRefereeAttribute.PossiblePenalty,
        VideoAssistantRefereeAttribute.PossibleRedCard,
      ],
      singleAttributes: true,
    },
    [SoccerIncidentId.AddedTime]: {
      displayInfo: 'minutes',
    },
    [SoccerIncidentId.InPossession]: {
      attributeIds: [InPossessionAttribute.KeyPass],
    },
    [SoccerIncidentId.Attack]: {
      attributeIds: [AttackAttribute.KeyPass],
    },
    [SoccerIncidentId.DangerousAttack]: {
      attributeIds: [DangerousAttackAttribute.KeyPass],
    },
    [SoccerIncidentId.Tackle]: {
      participant: 'player',
    },
    [SoccerIncidentId.Interception]: {
      participant: 'player',
    },
    [SoccerIncidentId.BallSkills]: {
      participant: 'player',
    },
    [SoccerIncidentId.LooseBall]: {
      participant: 'player',
    },
  }
