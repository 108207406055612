import Dexie from 'dexie'

let database: Dexie | null = null

export enum Tables {
  EVENT_SETTINGS = 'eventSettings',
  EVENT_INCIDENTS_QUEUE = 'eventIncidentsQueue',
  INCIDENTS_REQUIRE_POINTING_POSITION = 'incidentsRequirePointingPosition',
}

const databaseName = 'collector-desktop'

export function init(): void {
  database = new Dexie(databaseName)

  database.version(2).stores({
    eventSettings: 'eventId',
    eventIncidentsQueue: 'eventId',
    incidentsRequirePointingPosition: 'eventId',
  })
}

export function get(): Dexie {
  if (!database) {
    throw new Error(`${databaseName} database not initialized`)
  }

  return database
}
