import { computed, ComputedRef } from 'vue'

import { TeamSide } from '@collector/shared-ui-domain'

import { teamColors } from '../consts/teamColors'
import { useEventSettings } from './useEventSettings'

const root: HTMLElement = document.querySelector(':root')!

export function useSwitchTeamColors(): {
  teamColorsSwitched: ComputedRef<boolean>
  setTeamColors: (side: TeamSide, currentSideColor: TeamSide) => void
} {
  const { eventSettings } = useEventSettings()

  function setTeamColors(side: TeamSide, currentSideColor: TeamSide): void {
    for (let i = 0; i < 10; i++) {
      const color = teamColors[currentSideColor][i]
      root.style.setProperty(`--current-${side}-color-${i}`, color)
    }
  }

  return {
    setTeamColors,
    teamColorsSwitched: computed(
      () => eventSettings.value.teamColorsSwitched ?? false,
    ),
  }
}
