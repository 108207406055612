<script lang="ts" setup>
import { computed, ref, watch } from 'vue'

import { Checkbox, TextInput } from '@collector/shared-ui'
import { opChain } from '@collector/shared-utils'

import Header from './Header.vue'
import { Column, Data } from './types'

// props
interface Props {
  columns: Column[]
  data: Data[][]
  editedData: Data[][]
}

const props = defineProps<Props>()

// consts
const numberOfStickyColumns = 2

// state
const sortColumn = ref('short_name')
const sortDirection = ref<'asc' | 'desc'>('asc')
const originalData = ref<Data[][]>(
  props.data.map((row) => row.map((cell) => Object.assign({}, cell))),
)
const modifiedCells = ref<Record<string, Record<string, boolean>>>({})

watch(
  () => props.data,
  () => {
    originalData.value = props.data.map((row) =>
      row.map((cell) => Object.assign({}, cell)),
    )
    modifiedCells.value = {}
  },
)

const sortedData = computed(() => {
  const data = [...props.editedData]
  data.sort((a, b) => {
    const aValue = a.find((x) => x.name === sortColumn.value)?.value || ''
    const bValue = b.find((x) => x.name === sortColumn.value)?.value || ''

    if (aValue === bValue) {
      return 0
    }

    if (sortDirection.value === 'asc') {
      if (isNaN(Number(aValue)) && isNaN(Number(bValue))) {
        return aValue?.toString().localeCompare(bValue.toString())
      }

      return Number(aValue) > Number(bValue) ? 1 : -1
    } else {
      if (isNaN(Number(aValue)) && isNaN(Number(bValue))) {
        return -aValue.toString().localeCompare(bValue.toString())
      }

      return Number(aValue) > Number(bValue) ? -1 : 1
    }
  })

  return data
})

// methods
function isCellModified(rowKey: string, column: string): boolean {
  return modifiedCells.value[rowKey]?.[column] === true
}

function isRecordModified(rowKey: string): boolean {
  return modifiedCells.value[rowKey] !== undefined
}

function updateCell(
  rowKey: string,
  columnName: string,
  newValue: string | number | null,
): void {
  const originalRecord = originalData.value.find((x) => x[1].value === rowKey)
  const editedRecord = props.editedData.find((x) => x[1].value === rowKey)

  if (!originalRecord || !editedRecord) {
    return
  }

  const originalValue = originalRecord.find((x) => x.name === columnName)?.value

  const edited = editedRecord.find((x) => x.name === columnName)

  if (!edited) {
    return
  }

  edited.value = newValue ?? Number(newValue).toString()

  if (
    newValue !== originalValue &&
    Number(newValue) !== Number(originalValue)
  ) {
    if (!modifiedCells.value[rowKey]) {
      modifiedCells.value[rowKey] = {}
    }
    modifiedCells.value[rowKey][columnName] = true
  } else {
    if (modifiedCells.value[rowKey]) {
      modifiedCells.value[rowKey][columnName] = false

      if (
        Object.values(modifiedCells.value[rowKey]).every((v) => v === false)
      ) {
        delete modifiedCells.value[rowKey]
      }
    }
  }
}
</script>

<template>
  <table class="table-auto">
    <Header
      :columns
      :numberOfStickyColumns
      :sortColumn
      @sort="
        (column, direction) => {
          sortColumn = column
          sortDirection = direction
        }
      "
    />
    <tbody>
      <tr
        v-for="(dataRow, rowIndex) in sortedData"
        :key="`${dataRow[0].value}-${rowIndex}`"
        class="odd:bg-neutral-light-8 even:bg-neutral-light-10 hover:bg-neutral-light-5 max-h-10"
        data-testid="stats-table-row"
      >
        <td
          v-for="(column, colIndex) in columns.slice(0, numberOfStickyColumns)"
          :key="column.dataField"
          class="sticky left-0 m-auto whitespace-nowrap bg-inherit"
          :class="`left-${colIndex * 8} font-${
            isRecordModified(
              opChain(dataRow[1].value, (value) => value.toString()) || '',
            )
              ? 'bold'
              : 'thin'
          }`"
        >
          <div
            class="-my-1 flex h-10 items-center px-2 text-xs"
            :class="{
              'border-r-neutral-light-4 border-r-[1px]':
                colIndex === numberOfStickyColumns - 1,
            }"
          >
            {{ dataRow[colIndex].value }}
          </div>
        </td>
        <td
          v-for="(column, index) in columns.slice(numberOfStickyColumns)"
          :key="column.dataField"
          class="whitespace-nowrap"
        >
          <div class="flex justify-center">
            <TextInput
              v-if="
                dataRow[index + numberOfStickyColumns].dataType === 'integer'
              "
              :key="column.dataField"
              v-model="dataRow[index + numberOfStickyColumns].value"
              class="mx-2"
              full
              size="xs"
              numeric
              placeholder="-"
              :inputClass="`text-center text-neutral-dark-8 ${
                isCellModified(
                  opChain(dataRow[1].value, (value) => value.toString()) || '',
                  column.dataField,
                )
                  ? 'border-neutral-dark-4 font-bold'
                  : 'border-neutral-light-4'
              }  max-w-8`"
              :maxlength="3"
              @blur="
                (e) =>
                  updateCell(
                    opChain(dataRow[1].value, (value) => value.toString()) ||
                      '',
                    column.dataField,
                    e.value,
                  )
              "
            />
            <Checkbox
              v-if="
                dataRow[index + numberOfStickyColumns].dataType === 'binary'
              "
              :key="`${column.dataField}-${dataRow[index + numberOfStickyColumns].value}`"
              :modelValue="dataRow[index + numberOfStickyColumns].value === '1'"
              variant="neutral"
              @update:modelValue="
                (v) => {
                  updateCell(
                    opChain(dataRow[1].value, (value) => value.toString()) ||
                      '',
                    column.dataField,
                    v === true ? '1' : '0',
                  )
                }
              "
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
