import { jwtDecode } from 'jwt-decode'

import { AuthRefresh } from '@collector/sportsapi-client'

import { expireThresholdMinutes } from '../consts'
import { AuthTokens } from '../useAuth'
import { createAuthClient } from './client'

let timeoutId: ReturnType<typeof setTimeout> | null = null

export function setTokenRefreshTimeout(
  token: string,
  callback: () => void,
): void {
  const { exp } = jwtDecode(token)
  if (!exp) return
  if (timeoutId) clearTimeout(timeoutId)

  const thresholdMs = expireThresholdMinutes * 60 * 1000
  const timeout = Math.max(0, exp * 1000 - Date.now() - thresholdMs)

  timeoutId = setTimeout(callback, timeout)
}

export async function refreshTokens(tokens: AuthTokens): Promise<AuthTokens> {
  const refreshClient = createAuthClient(tokens.refreshToken)

  const access_token = tokens.accessToken
  const refreshRes = await AuthRefresh.post(refreshClient, { access_token })
  const data = refreshRes.api.data

  return { accessToken: data.token, refreshToken: data.refresh_token }
}
