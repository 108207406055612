export enum PenaltySavedByGoalkeeperAttribute {
  LeftFoot = 448,
  RightFoot = 449,
  UpperLeft = 450,
  UpperRight = 451,
  UpperMiddle = 452,
  BottomMiddle = 453,
  BottomLeft = 454,
  BottomRight = 455,
}
