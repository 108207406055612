<script setup lang="ts">
import { computed } from 'vue'

import {
  SoccerEventDetailId,
  SoccerEventStatusId,
  SoccerResultId,
} from '@collector/sportsapi-client-legacy'
import RelationView from '@desktop/views/Relation/components/RelationView/RelationView.vue'
import { useEvent } from '@desktop/views/Relation/composables'
import { useOnIncidentChangeTeamPossession } from '@desktop/views/Relation/Sports/init'
import {
  provideIncidentsRequirePointingPosition,
  provideSportConfiguration,
} from '@desktop/views/Relation/Sports/provide-inject'

import { provideIncidentsRequireConfirmation } from '../../provide-inject/incidentsRequireConfirmation'
import Statistics from '../Statistics/Statistics.vue'
import BetMarketButtons from './components/RelationView/Main/BetMarketButtons/BetMarketButtons.vue'
import PitchWithIncidents from './components/RelationView/Main/PitchWithIncidents/PitchWithIncidents.vue'
import Summary from './components/RelationView/Main/Summary/Summary.vue'
import {
  betIncidents,
  eventInfoEventDetails,
  eventStatuses,
  eventStatusIncidentsOnFieldConfig,
  getPeriodTime,
  handyBarInfoAlwaysVisibleIncidents,
  historyIncidentsConfig,
  importantStatsId,
  incidentIdsGroup,
  incidentsInFilter,
  incidentsQueuePayloadConfig,
  incidentsRequireConfirmation,
  incidentsRequirePointingPosition,
  manualAddIncidentConfig,
  pitchDisabledStatuses,
  pitchIncidentsConfig,
  pitchInteractionDisabledStatuses,
  pitchOverlaySupportedStatuses,
  pocketIncidentsConfig,
  relatedEventStatusConfig,
  statisticsConfig,
} from './configuration'
import { incidentsChangeTeamPossessionConfig } from './configuration/incidentsChangeTeamPossessionConfig'

// composables
const { event, eventDetailsMap } = useEvent()

// computed
// TODO: extract to init
const eventPeriodTime = computed(() => {
  const periodLength = Number(
    eventDetailsMap.value[SoccerEventDetailId.PeriodLength].value,
  )
  const extraPeriodLength = Number(
    eventDetailsMap.value[SoccerEventDetailId.ExtraPeriodLength].value,
  )

  return getPeriodTime(
    event.value.status_id as SoccerEventStatusId,
    periodLength,
    extraPeriodLength,
  )
})

// init
provideSportConfiguration({
  betIncidents,
  pitchInteractionDisabledStatuses,
  eventInfoEventDetails,
  eventPeriodTime,
  eventStatuses,
  eventStatusIncidentsOnFieldConfig,
  incidentsInFilter,
  incidentIdsGroup,
  firstHalfResultId: SoccerResultId.FirstHalf,
  handyBarInfoAlwaysVisibleIncidents,
  historyIncidentsConfig,
  importantStatsId,
  incidentsQueuePayloadConfig,
  manualAddIncidentConfig,
  pitchDisabledStatuses,
  pitchIncidentsConfig,
  pocketIncidentsConfig,
  relatedEventStatusConfig,
  pitchOverlaySupportedStatuses,
  statisticsConfig,
})

/** use only for sports that has incidents which requires confirmation */
provideIncidentsRequireConfirmation(incidentsRequireConfirmation)

/** use only for sports that has pitch */
provideIncidentsRequirePointingPosition(incidentsRequirePointingPosition)

/** use only for sports with possession */
useOnIncidentChangeTeamPossession(incidentsChangeTeamPossessionConfig)
</script>

<template>
  <RelationView>
    <template #statistics>
      <Statistics class="flex-1" />
    </template>

    <template #summary>
      <Summary />
    </template>
    <template #middle>
      <BetMarketButtons />
    </template>
    <template #bottom>
      <PitchWithIncidents />
    </template>
  </RelationView>
</template>
