import {
  BaseParticipant,
  SubParticipantPosition,
} from '@collector/sportsapi-client-legacy'

export const subParticipantPositions: SubParticipantPosition[] = [
  SubParticipantPosition.Goalkeeper,
  SubParticipantPosition.FirstLineup,
  SubParticipantPosition.Bench,
  SubParticipantPosition.Coach,
]

export function getSelectOptionExtendedText(
  participant: BaseParticipant,
): string {
  return `(${participant.area_name}) ${participant.details.birthdate}`
}
