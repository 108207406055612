import { computed, ComputedRef, Ref } from 'vue'

import { Participants } from '@collector/sportsapi-client'
import {
  StatsLevel,
  SubParticipantPosition,
} from '@collector/sportsapi-client-legacy'
import { useSeason } from '@desktop/views/Relation/composables'

import {
  CanAddSubParticipant,
  CanAddSubParticipantTitle,
  Player,
} from '../types'

type UseAddPosition = {
  canAddGoalKeeper: ComputedRef<boolean>
  canAddGoalKeeperTitle: ComputedRef<string>
  canAddFirstLineup: ComputedRef<boolean>
  canAddFirstLineupTitle: ComputedRef<string>
  canAddCoach: ComputedRef<boolean>
  canAddCoachTitle: ComputedRef<string>
  canAddSubParticipant: CanAddSubParticipant
  canAddSubParticipantTitle: CanAddSubParticipantTitle
}

export function useAddPosition({
  seasonSquadSubParticipants,
  goalkeeperSubParticipants,
  firstLineupSubParticipants,
  benchSubParticipants,
  coachSubParticipants,
  maxFirstLineupCount,
}: {
  seasonSquadSubParticipants: Ref<Participants.Squad.SeasonSquadParticipant[]>
  goalkeeperSubParticipants: ComputedRef<Player[]>
  firstLineupSubParticipants: ComputedRef<Player[]>
  benchSubParticipants: ComputedRef<Player[]>
  coachSubParticipants: ComputedRef<Player[]>
  maxFirstLineupCount: number
}): UseAddPosition {
  const { isSeasonStatsLevel } = useSeason()

  // computed
  const canAddGoalKeeper = computed(
    () => goalkeeperSubParticipants.value.length === 0,
  )

  const canAddGoalKeeperTitle = computed(() =>
    canAddGoalKeeper.value ? '' : 'Goalkeeper is already added to first lineup',
  )

  const canAddFirstLineup = computed(
    () => firstLineupSubParticipants.value.length < maxFirstLineupCount - 1,
  )

  const canAddFirstLineupTitle = computed(() =>
    canAddFirstLineup.value ? '' : 'First lineup already has 11 players',
  )

  const canAddCoach = computed(() => coachSubParticipants.value.length === 0)

  const canAddCoachTitle = computed(() =>
    canAddCoach.value ? '' : 'Coach is already added',
  )

  const subParticipantsShortNames = computed(() =>
    [
      ...goalkeeperSubParticipants.value,
      ...firstLineupSubParticipants.value,
      ...benchSubParticipants.value,
      ...coachSubParticipants.value,
    ].map((p) => p.short_name),
  )

  const subParticipantsIds = computed(() =>
    [
      ...goalkeeperSubParticipants.value,
      ...firstLineupSubParticipants.value,
      ...benchSubParticipants.value,
      ...coachSubParticipants.value,
    ]
      .map((p) => p.id)
      .filter((id) => id !== undefined),
  )

  // methods
  function canAddSubParticipantPosition(
    position: SubParticipantPosition,
  ): boolean {
    switch (position) {
      case SubParticipantPosition.Goalkeeper:
        return canAddGoalKeeper.value
      case SubParticipantPosition.FirstLineup:
        return canAddFirstLineup.value
      case SubParticipantPosition.Coach:
        return canAddCoach.value
      default:
        return true
    }
  }

  function canAddSubParticipantPositionTitle(
    position: SubParticipantPosition,
  ): string {
    switch (position) {
      case SubParticipantPosition.Goalkeeper:
        return canAddGoalKeeperTitle.value
      case SubParticipantPosition.FirstLineup:
        return canAddFirstLineupTitle.value
      case SubParticipantPosition.Coach:
        return canAddCoachTitle.value
      default:
        return ''
    }
  }

  function canAddSubParticipantName(shortName: string): boolean {
    return !subParticipantsShortNames.value.includes(shortName)
  }

  function canAddSubParticipantNameTitle(shortName: string): string {
    return `Player with name "${shortName}" is already added`
  }

  function canAddSubParticipantNameWithShirtNr(
    position: SubParticipantPosition,
    shirtNr: string,
  ): boolean {
    return (
      !!shirtNr ||
      position === SubParticipantPosition.Coach ||
      isSeasonStatsLevel(StatsLevel.Bronze) ||
      isSeasonStatsLevel(StatsLevel.Silver)
    )
  }

  function canAddSubParticipantId(id: number): boolean {
    return !subParticipantsIds.value.includes(id)
  }

  function canAddSubParticipantIdWithShirtNr(
    id: number,
    position: SubParticipantPosition,
    shirtNr: string,
  ): boolean {
    return (
      !!shirtNr ||
      position === SubParticipantPosition.Coach ||
      seasonSquadSubParticipants.value.some((p) => p.id === id)
    )
  }

  function canAddSubParticipantIdTitle(id: number): string {
    return `Player with id ${id} is already added`
  }

  function canAddSubParticipant(
    position: SubParticipantPosition,
    {
      shortName,
      id,
      shirtNr,
    }: { shortName?: string; id?: number; shirtNr: string },
  ): boolean {
    const canAddId = id
      ? canAddSubParticipantId(id) &&
        canAddSubParticipantIdWithShirtNr(id, position, shirtNr)
      : true

    const canAddShortName = shortName
      ? canAddSubParticipantName(shortName) &&
        canAddSubParticipantNameWithShirtNr(position, shirtNr)
      : true

    return canAddSubParticipantPosition(position) && canAddId && canAddShortName
  }

  function canAddSubParticipantTitle(
    position: SubParticipantPosition,
    {
      shortName,
      id,
      shirtNr,
    }: { shortName?: string; id?: number; shirtNr: string },
  ): string {
    const shirtNrRequired = `Shirt number is required`

    if (id) {
      if (!canAddSubParticipantId(id)) {
        return canAddSubParticipantIdTitle(id)
      }

      if (!canAddSubParticipantIdWithShirtNr(id, position, shirtNr)) {
        return shirtNrRequired
      }
    }

    if (shortName) {
      if (!canAddSubParticipantName(shortName)) {
        return canAddSubParticipantNameTitle(shortName)
      }

      if (!canAddSubParticipantNameWithShirtNr(position, shirtNr)) {
        return shirtNrRequired
      }
    }

    if (!canAddSubParticipantPosition(position)) {
      return canAddSubParticipantPositionTitle(position)
    }

    return ''
  }

  return {
    canAddGoalKeeper,
    canAddGoalKeeperTitle,
    canAddFirstLineup,
    canAddFirstLineupTitle,
    canAddCoach,
    canAddCoachTitle,
    canAddSubParticipant,
    canAddSubParticipantTitle,
  }
}
