<script setup lang="ts">
import { computed } from 'vue'

import { Button } from '@collector/shared-ui'
import { hasIncidentMasterAttribute } from '@collector/shared-ui-domain'
import { Incident } from '@collector/sportsapi-client'
import { Confirmation } from '@collector/sportsapi-client-legacy'
import Dropdown from '@desktop/components/Dropdown.vue'
import { useEventIncidentsQueue } from '@desktop/views/Relation/Sports/composables'

interface Props {
  incident: Incident
  groupedIncident?: Incident
  isDeleted: boolean
}

const props = defineProps<Props>()

// data
const eventIncidentsQueue = useEventIncidentsQueue()

// methods
function deleteIncident(): void {
  eventIncidentsQueue.deleteIncident(props.incident.id)

  if (props.groupedIncident) {
    eventIncidentsQueue.deleteIncident(props.groupedIncident.id)
  }
}

// computed
const shouldShowButton = computed(
  () =>
    !hasIncidentMasterAttribute(props.incident) &&
    props.incident.category !== 'emergency' &&
    props.incident.confirmation !== Confirmation.ToBeDone,
)
</script>

<template>
  <Dropdown placement="left">
    <template #trigger>
      <Button
        v-if="!isDeleted"
        class="bg-error-5 border-error-5 hover:bg-error-7 hover:border-error-7 text-neutral-10 cursor-pointer"
        :class="{ invisible: !shouldShowButton }"
        icon="xmark"
        size="xs"
        square
      />
    </template>
    <template #content="{ hide }">
      <div class="text-xs font-bold">
        <div class="text-neutral-dark-6 pb-2 text-center">Confirm delete?</div>
        <div class="flex gap-x-4">
          <span
            class="text-neutral-dark-0 cursor-pointer"
            @click="hide"
          >
            Cancel
          </span>
          <span
            class="text-error-5 cursor-pointer underline"
            @click="
              () => {
                deleteIncident()
                hide()
              }
            "
          >
            Confirm
          </span>
        </div>
      </div>
    </template>
  </Dropdown>
</template>
