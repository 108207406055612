<script setup lang="ts">
import { computed } from 'vue'

import { TeamSide } from '@collector/shared-ui-domain'
import { Participant } from '@collector/sportsapi-client-legacy'
import { useBlockades } from '@desktop/views/Relation/Sports/composables'
import { injectPitchIncidentsConfig } from '@desktop/views/Relation/Sports/provide-inject'

import { useSwitchTeamColors } from '../../composables/useSwitchColors'

interface Props {
  teamSide: TeamSide
  participant: Participant
}

defineProps<Props>()

// inject
const pitchIncidentsConfig = injectPitchIncidentsConfig()

// composables
const { isIncidentBlocked } = useBlockades()
const { teamColorsSwitched } = useSwitchTeamColors()

// computed
const isDisabled = computed(() =>
  [...pitchIncidentsConfig.pitchIncidents].every((incident) =>
    isIncidentBlocked(incident, pitchIncidentsConfig.blockedPitchIncidentsMap),
  ),
)
</script>

<template>
  <div
    class="flex h-6 items-center rounded px-2"
    :class="{
      'bg-current-team-home-5': teamSide === 'home',
      'bg-current-team-home-5/50': teamSide === 'home' && isDisabled,
      'bg-current-team-away-5': teamSide === 'away',
      'bg-current-team-away-5/50': teamSide === 'away' && isDisabled,
      'text-neutral-dark-0':
        teamSide === (teamColorsSwitched ? 'away' : 'home'),
      'text-neutral-dark-0/50':
        teamSide === (teamColorsSwitched ? 'away' : 'home') && isDisabled,
      'text-neutral-light-10':
        teamSide === (teamColorsSwitched ? 'home' : 'away'),
      'text-neutral-light-10/50':
        teamSide === (teamColorsSwitched ? 'home' : 'away') && isDisabled,
    }"
  >
    <div
      class="w-full overflow-hidden text-ellipsis text-nowrap text-center text-xs font-bold"
      dir="ltr"
    >
      {{ participant.short_name }}
    </div>
  </div>
</template>
