<script setup lang="ts">
import { computed } from 'vue'

import {
  isIncidentDeleted as _isIncidentDeleted,
  ParticipantName,
} from '@collector/shared-ui-domain'
import { Incident } from '@collector/sportsapi-client'
import {
  useParticipants,
  useSwappableIncidents,
  useTeamSides,
} from '@desktop/views/Relation/composables'

import DeleteIncidentButton from './DeleteIncidentButton.vue'
import IncidentConfirmation from './IncidentConfirmation.vue'
import IncidentContainer from './IncidentContainer.vue'
import IncidentDetails from './IncidentDetails/IncidentDetails.vue'
import { useIncidentSettings } from './IncidentDetails/useIncidentSettings'
import IncidentEventStatus from './IncidentEventStatus.vue'
import IncidentEventTime from './IncidentEventTime.vue'
import IncidentName from './IncidentName.vue'
import IncidentTime from './IncidentTime.vue'
import SwapIncidentsButton from './SwapIncidents/SwapIncidentsButton.vue'

interface Props {
  incident: Incident
  groupedIncident?: Incident
  incidentName: string
}

const props = defineProps<Props>()

// composables
const { getIncidentTeamSide } = useTeamSides()
const { getParticipantById } = useParticipants()
const { swappableIncidentsId } = useSwappableIncidents()
const { swappable } = useIncidentSettings(props.incident)

// data
const teamSide = getIncidentTeamSide(props.incident)
const participant =
  props.incident.participant_team_id === null
    ? null
    : getParticipantById(props.incident.participant_team_id)

// computed
const isIncidentDeleted = computed(() => _isIncidentDeleted(props.incident))

const isGroupedIncidentDeleted = computed(() =>
  props.groupedIncident ? _isIncidentDeleted(props.groupedIncident) : false,
)

const isDeleted = computed(
  () =>
    isIncidentDeleted.value &&
    (props.groupedIncident ? isGroupedIncidentDeleted.value : true),
)

const shouldShowSwapButton = computed(
  () =>
    swappable &&
    !isDeleted.value &&
    swappableIncidentsId.includes(props.incident.incident_id),
)
</script>

<template>
  <IncidentContainer
    class="mx-1.5 pb-2 pl-1 pr-1 pt-1"
    :incident
    :teamSide
    :isDeleted
  >
    <div class="mb-0.5 flex">
      <div class="flex w-full items-center">
        <IncidentTime
          class="mr-3 min-w-[4.25rem] pr-1 text-right"
          :incident
          :isDeleted
        />
        <ParticipantName
          v-if="participant"
          class="@lg:max-w-24 @xl:max-w-none truncate pr-2 font-bold"
          :participant
          :isDeleted
        />
        <IncidentName
          :incidentName
          :isDeleted
        />
        <IncidentConfirmation
          class="pl-4"
          :incident
          :isDeleted
        />
      </div>

      <div class="flex gap-x-1">
        <SwapIncidentsButton
          v-if="shouldShowSwapButton"
          :incident
        />
        <DeleteIncidentButton
          :isDeleted
          :incident
          :groupedIncident
          data-test-id="DeleteIncidentButton"
        />
        <div
          v-if="isDeleted"
          class="text-error-5/30 border-error-5/30 flex h-6 items-center rounded border px-2.5 text-sm font-bold"
          size="xs"
        >
          REMOVED
        </div>
      </div>
    </div>
    <div class="flex gap-x-4">
      <div class="flex flex-col justify-start">
        <IncidentEventStatus
          class="w-16 pb-1 text-right"
          :incident
          :isDeleted
        />
        <IncidentEventTime
          class="w-16"
          :incident
          :groupedIncident
          :isDeleted
        />
      </div>
      <div class="grid w-full grid-cols-3 items-end gap-x-2">
        <IncidentDetails
          :incident
          :disabled="isIncidentDeleted"
          :teamSide
        />
        <IncidentDetails
          v-if="groupedIncident"
          :incident="groupedIncident"
          :disabled="isGroupedIncidentDeleted"
          :teamSide
        />
      </div>
    </div>
  </IncidentContainer>
</template>
