import { Ref, ref } from 'vue'

export type NotificationType = 'success' | 'error'

const notifications: Ref<Notification[]> = ref([])
const isNotificationVisible = ref(false)

type Notification = {
  value: unknown
  time: number
  type: NotificationType
}

type UseNotifications = {
  notifications: Ref<Notification[]>
  isNotificationVisible: Ref<boolean>
  addErrorNotification(error: unknown): void
  addSuccessNotification(message: string): void
}

export function useNotifications(): UseNotifications {
  function addNotification(value: unknown, type: NotificationType): void {
    const notification = { value, time: new Date().getTime(), type }
    if (!import.meta.env.DEV) {
      // eslint-disable-next-line
      console.debug('Notification', notification)
    }
    notifications.value.push(notification)
  }

  function addSuccessNotification(message: string): void {
    addNotification(message, 'success')
  }

  function addErrorNotification(error: unknown): void {
    addNotification(error, 'error')
  }

  return {
    notifications,
    isNotificationVisible,
    addErrorNotification,
    addSuccessNotification,
  }
}
