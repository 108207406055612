import wretch from 'wretch'
import QueryStringAddon from 'wretch/addons/queryString'
import { retry } from 'wretch/middlewares/retry'

import type { HttpClient } from '../httpClient'
import { withErrorCatcher } from './error'

export function getAuthorizationHeader(token: string): string {
  return `Bearer ${token}`
}

export function withAuthorizationHeader(
  httpClient: HttpClient,
  token: string,
): HttpClient {
  return httpClient.auth(getAuthorizationHeader(token))
}

export function withRetryMiddleware(
  httpClient: HttpClient,
  maxAttempts: number,
): HttpClient {
  return httpClient.middlewares([retry({ delayTimer: 100, maxAttempts })])
}

export function withUnauthorizedCatcher(
  httpClient: HttpClient,
  getRefreshedTokens: () => Promise<{ accessToken: string }>,
): HttpClient {
  return httpClient.catcher(401, async (_error, request) => {
    const authTokens = await getRefreshedTokens()
    return request // Replay the original request with new token
      .auth(getAuthorizationHeader(authTokens.accessToken))
      .fetch()
      .unauthorized((err) => {
        throw err // if still 401, reject Promise to prevent infinite loop of replayed requests
      })
      .json()
  })
}

function withTimezoneQueryStringParam(httpClient: HttpClient): HttpClient {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return httpClient.query({ tz: timezone })
}

export function createHttpClient(baseUrl: string): HttpClient {
  const httpClient = withErrorCatcher(wretch(baseUrl).addon(QueryStringAddon))

  return withTimezoneQueryStringParam(httpClient)
}

export function createAuthClient(baseUrl: string, token: string): HttpClient {
  const httpClient = createHttpClient(baseUrl)
  const authClient = withAuthorizationHeader(httpClient, token)

  return authClient
}
