<script setup lang="ts">
import * as R from 'ramda'
import { computed, reactive } from 'vue'

import { Button, ButtonGroup, Modal, TextInput } from '@collector/shared-ui'
import { ParticipantLogo } from '@collector/shared-ui-domain'
import { Incident } from '@collector/sportsapi-client'
import { Confirmation, EventStatusId } from '@collector/sportsapi-client-legacy'
import {
  useParticipants,
  useSportMaps,
} from '@desktop/views/Relation/composables'
import { useEventIncidentsQueue } from '@desktop/views/Relation/Sports/composables'
import { injectIncidentsRequireConfirmation } from '@desktop/views/Relation/Sports/provide-inject/incidentsRequireConfirmation'
import { injectManualAddIncidentConfig } from '@desktop/views/Relation/Sports/provide-inject/manualAddIncidentConfig'

import ManualAddIncidentButton from './ManualAddIncidentButton.vue'
import ManualAddIncidentGroup from './ManualAddIncidentGroup.vue'

type Payload = {
  minute: number | ''
  second: number | ''
  participant_team_id: Incident['participant_team_id'] | null
  incident_id: Incident['incident_id'] | null
  status_id: Incident['status_id'] | null
}

type Emits = { close: [] }
const emit = defineEmits<Emits>()

// composables
const { homeParticipant, awayParticipant } = useParticipants()
const eventIncidentsQueue = useEventIncidentsQueue()
const { eventStatusToSportStatusMap } = useSportMaps()

// inject
const manualAddIncidentConfig = injectManualAddIncidentConfig()
const incidentsRequireConfirmation = injectIncidentsRequireConfirmation()

// data
const incidentGroups = [
  {
    title: 'Key incidents',
    incidents: manualAddIncidentConfig.keyIncidents,
  },
  {
    title: 'Other incidents',
    incidents: manualAddIncidentConfig.otherIncidents,
  },
]

// state
const payload = reactive<Payload>({
  minute: '',
  second: '',
  participant_team_id: null,
  incident_id: null,
  status_id: null,
})

// computed
const isSaveable = computed(() => {
  const isTimeValid =
    payload.minute !== '' &&
    payload.second !== '' &&
    (payload.minute === 0 ? payload.second > 0 : payload.second >= 0) &&
    payload.second <= 59

  return (
    R.values(payload).every(R.allPass([R.isNotNil, R.isNotEmpty])) &&
    isTimeValid
  )
})

// methods
function addIncidentToQueue(): void {
  eventIncidentsQueue.addIncident({
    minute: payload.minute as number,
    second: payload.second as number,
    participant_team_id: payload.participant_team_id!,
    incident_id: payload.incident_id!,
    status_id: payload.status_id!,
    confirmation: incidentsRequireConfirmation.includes(payload.incident_id!)
      ? Confirmation.ToBeDone
      : undefined,
  })
}

function getEventStatusName(eventStatusId: EventStatusId): string {
  return eventStatusToSportStatusMap[eventStatusId]?.name ?? ''
}
</script>

<template>
  <Modal
    title="Manual add incident"
    :confirmButton="{
      text: 'Add incident',
      confirm: addIncidentToQueue,
      disabled: !isSaveable,
    }"
    @close="emit('close')"
  >
    <div class="space-y-4">
      <ButtonGroup
        :options="[homeParticipant, awayParticipant]"
        width="full"
        withSeparator
      >
        <template #default="{ option, selected }">
          <Button
            :class="{
              'font-bold': selected,
              'bg-neutral-light-10 text-neutral-dark-2': !selected,
              'bg-current-team-home-5': option === homeParticipant && selected,
              'flex-row-reverse': option === awayParticipant,
              'bg-current-team-away-5': option === awayParticipant && selected,
              'rounded-r-none': option === homeParticipant,
              'rounded-l-none': option === awayParticipant,
            }"
            size="md"
            @click="payload.participant_team_id = option.id"
          >
            <ParticipantLogo :participant="option" />
            {{ option.short_name }}
          </Button>
        </template>
      </ButtonGroup>

      <template v-for="incidentGroup in incidentGroups">
        <ManualAddIncidentGroup
          v-if="incidentGroup.incidents"
          :key="incidentGroup.title"
          grid
        >
          <template #title>{{ incidentGroup.title }}</template>

          <ManualAddIncidentButton
            v-for="incidentId in incidentGroup.incidents"
            :key="incidentId"
            :incidentId
            :participantId="payload.participant_team_id"
            :selected="payload.incident_id === incidentId"
            @click="payload.incident_id = incidentId"
          />
        </ManualAddIncidentGroup>
      </template>

      <ManualAddIncidentGroup grid>
        <template #title>Event statuses</template>

        <Button
          v-for="eventStatusId in manualAddIncidentConfig.eventStatuses"
          :key="eventStatusId"
          class="border-neutral-3 !justify-start px-3 text-xs font-bold"
          :class="{
            'text-neutral-light-9 enabled:bg-neutral-3':
              payload.status_id === eventStatusId,
            'enabled:bg-neutral-3/15': payload.status_id !== eventStatusId,
          }"
          size="lg"
          width="full"
          :disabled="payload.participant_team_id === null"
          @click="payload.status_id = eventStatusId"
        >
          {{ getEventStatusName(eventStatusId) }}
        </Button>
      </ManualAddIncidentGroup>

      <ManualAddIncidentGroup>
        <template #title>Event time</template>

        <div class="flex items-center justify-center space-x-2">
          <TextInput
            v-model.number="payload.minute"
            numeric
            :maxlength="3"
            size="md"
            placeholder="0"
            inputClass="w-12 text-center"
          />
          <span>:</span>
          <TextInput
            v-model.number="payload.second"
            numeric
            :maxlength="2"
            size="md"
            placeholder="0"
            inputClass="w-12 text-center"
          />
        </div>
      </ManualAddIncidentGroup>
    </div>
  </Modal>
</template>
