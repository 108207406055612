<script setup lang="ts">
import { watch } from 'vue'
import { useRoute } from 'vue-router'

import { LoginForm } from '@collector/desktop-feature-login-form'
import {
  ModalContainer,
  startLoader,
  stopLoader,
  useModalStack,
} from '@collector/shared-ui'
import { useEventListener } from '@vueuse/core'

import Notification from './components/Notification.vue'
import { Env } from './envs'
import { useAuth } from './globalState/userAuth/useAuth'

// composables
const { initialized, loggedIn, initAuth, logIn, refreshAuth } = useAuth()
const route = useRoute()
useModalStack() // provide modal stack

// watchers
watch(initialized, (value) => value && stopLoader(), { immediate: true })

// init
initAuth()
useEventListener(window, 'online', refreshAuth)
</script>

<template>
  <div class="max-w-screen min-h-screen">
    <Notification />
    <template v-if="initialized">
      <RouterView
        v-if="loggedIn"
        :key="route.fullPath"
        v-slot="{ Component }"
      >
        <Suspense
          @pending="startLoader"
          @resolve="stopLoader"
        >
          <component :is="Component" />
        </Suspense>
      </RouterView>
      <LoginForm
        v-else
        :apiUrl="Env.VITE_SPORTS_API_URL"
        :appMode="Env.VITE_APP_MODE"
        @logIn="logIn"
      />
    </template>
  </div>

  <ModalContainer />
</template>
