import * as R from 'ramda'
import { Ref } from 'vue'

import { QueueRabbitClient } from '@collector/queue-gateway-shared-client'
import {
  RabbitIncidentMessage,
  SyncOptions,
} from '@collector/queue-gateway-shared-types'
import { Incident } from '@collector/sportsapi-client'

import { IncidentIdIncidentIndexMap } from './types'

function getMaxIncidentUt(incidents: Incident[]): number | null {
  return (
    incidents.reduce((acc, incident) => Math.max(acc, incident.ut), 0) || null
  )
}

function updateIncidents(
  incidentsUpdate: RabbitIncidentMessage[],
  incidents: Incident[],
  incidentIdIncidentIndexMap: IncidentIdIncidentIndexMap,
): void {
  for (const incidentUpdate of incidentsUpdate) {
    if (incidentUpdate.data.action === 'insert') {
      incidents.push(incidentUpdate.data)
      incidentIdIncidentIndexMap.set(
        incidentUpdate.data.id,
        incidents.length - 1,
      )
    }

    if (
      incidentUpdate.data.action === 'update' ||
      incidentUpdate.data.action === 'delete'
    ) {
      const incidentIndex = incidentIdIncidentIndexMap.get(
        incidentUpdate.data.id,
      )
      if (incidentIndex !== undefined) {
        const incident = incidents[incidentIndex]

        /**
         * Given existing reactive incident, merge properties values with new values from incidentUpdate,
         * but keep old values if they are not changed in incidentUpdate.
         *
         * For example, if prop value was empty object,
         * replacing it with new empty object would trigger unnecessary reactivity updates.
         */

        for (const _key in incident) {
          const key = _key as keyof Incident

          if (incidentUpdate.data[key] === undefined) {
            delete incident[key]
          } else if (!R.equals(incident[key], incidentUpdate.data[key])) {
            incident[key] = incidentUpdate.data[key] as never
          }
        }

        const missingKeys = R.difference(
          Object.keys(incidentUpdate.data),
          Object.keys(incident),
        )

        if (missingKeys.length) {
          const missingValues = R.pick(
            missingKeys as (keyof Incident)[],
            incidentUpdate.data,
          )

          Object.assign(incident, missingValues)
        }
      }
    }
  }
}

function createIncidentIdIncidentIndexMap(
  incidents: Incident[],
): IncidentIdIncidentIndexMap {
  const incidentIdIncidentIndexMap = new Map<number, number>()

  incidents.forEach((incident, index) => {
    incidentIdIncidentIndexMap.set(incident.id, index)
  })

  return incidentIdIncidentIndexMap
}

export function initIncidentIdIncidentIndexMap(incidents: Incident[]): {
  incidentIdIncidentIndexMap: IncidentIdIncidentIndexMap
} {
  const incidentIdIncidentIndexMap = createIncidentIdIncidentIndexMap(incidents)

  return { incidentIdIncidentIndexMap }
}

export function updateIncidentsOnConnect(
  queueRabbitClient: QueueRabbitClient,
  incidents: Incident[],
  incidentsUpdate: Ref<RabbitIncidentMessage[]>,
  incidentIdIncidentIndexMap: IncidentIdIncidentIndexMap,
  lastMessageId: Ref<number | null>,
): void {
  /**
   * - last item in incidentsUpdate is always item with max ut, but incidentsUpdate is empty before first update
   * - last item in incidents array is not always item with max ut (incidentsUpdate affects incidents across whole array)
   * - current date if no incident in history
   */
  const maxIncidentUt = incidentsUpdate.value.length
    ? incidentsUpdate.value[incidentsUpdate.value.length - 1].ut
    : (getMaxIncidentUt(incidents) ?? Math.floor(+new Date() / 1000))

  // Initial sync is done with last messages included (maxIncidentUt - 1) to extract order sequence (lastMessageId).
  const sync: SyncOptions | undefined =
    lastMessageId.value !== null
      ? { method: 'order', lastOrder: lastMessageId.value }
      : { method: 'timestamp', lastTimestamp: (maxIncidentUt - 1) * 1000 }

  queueRabbitClient.subIncident<RabbitIncidentMessage>({
    onMessageBatch: (messages) => {
      lastMessageId.value = messages[messages.length - 1].id

      // Process only new messages. Race condition may occur here.
      const newMessages =
        sync.method === 'timestamp'
          ? messages.filter((m) => m.ut > maxIncidentUt)
          : messages
      if (newMessages.length) {
        updateIncidents(newMessages, incidents, incidentIdIncidentIndexMap)
        incidentsUpdate.value = newMessages
      }
    },
    sync,
  })
}
