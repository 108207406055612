<script setup lang="ts">
import { ref } from 'vue'

import { Dropdown } from '@collector/shared-ui'

import Button from '../Button.vue'
import StatusesDropdown from './StatusesDropdown.vue'

// state
const dropdownOpen = ref(false)
</script>

<template>
  <Button
    icon="stopwatch"
    @click="dropdownOpen = true"
  >
    Statuses
  </Button>
  <Dropdown
    v-model="dropdownOpen"
    class="top-9 w-96"
    title="Statuses"
    withCloseIcon
  >
    <StatusesDropdown @close="dropdownOpen = false" />
  </Dropdown>
</template>
