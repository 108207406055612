import Sentry from '@collector/shared-plugin-sentry-vue'
import { isWretchError } from '@collector/shared-utils'
import { useNotifications } from '@desktop/globalState/notifications'

type HandleError = (error: unknown) => void

const { addErrorNotification } = useNotifications()

export function handleError(error: unknown, notification = true): void {
  if (!isWretchError(error)) {
    try {
      // avoid duplicates in Sentry by sending only non-HTTP errors
      // (HTTP errors are automatically sent by Sentry internal plugin)
      Sentry.captureException(error)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Sentry.captureException failed', err)
    }
  }

  // eslint-disable-next-line no-console
  console.error(error)

  if (notification) addErrorNotification(error)
}

export function getHandleError(): HandleError {
  let lastError: unknown

  return function (error: unknown) {
    if (lastError !== error) {
      lastError = error
      handleError(error)
    }
  }
}
