import { Events, Participants } from '@collector/sportsapi-client'
import {
  Event,
  Participant,
  SoccerLineupPositionId,
  SoccerPersonStatId,
  Stat,
  StatId,
  SubParticipant,
  SubParticipantPosition,
} from '@collector/sportsapi-client-legacy'
import { sportsApiClient } from '@desktop/globalState/sportsApiClient'

import { Player } from './types'

function getStat(player: Player, statId: StatId): Stat | null {
  return (
    ('stats' in player &&
      player.stats?.find(({ id }) => id === (statId as number))) ||
    null
  )
}

export function setStat(
  player: Player,
  id: StatId,
  value: number | string | null,
): void {
  const stat = getStat(player, id)
  if (stat) stat.value = value
  else if ('stats' in player) player.stats?.push({ id, value })
  else (player as SubParticipant).stats = [{ id, value }]
}

function getCapitanStat(player: Player): Stat | null {
  return getStat(player, SoccerPersonStatId.Captain)
}

export function isCaptain(player: Player): boolean {
  return getCapitanStat(player)?.value === 1
}

export function setCaptainStat(
  player: Player,
  value: number | string | null,
): void {
  return setStat(player, SoccerPersonStatId.Captain, value)
}

export function setPPositionStat(
  player: Player,
  value: number | string | null,
): void {
  return setStat(player, SoccerPersonStatId.PPosition, value)
}

export async function fetchParticipantSquadBySeasonId(
  participantId: number,
  seasonId: number,
): Promise<Participants.Squad.SeasonSquadParticipant[]> {
  const squad = await sportsApiClient.Participants.Squad.getBySeasonId(
    participantId,
    seasonId,
  )

  return squad.api.data.participants || []
}

export function getBirthdateText(birthdate: string): string {
  return birthdate || 'Birthdate is not set'
}

export function isPlayerSame(a: Player, b: Player): boolean {
  if ('lineup_id' in a && 'lineup_id' in b) return a.lineup_id === b.lineup_id
  else return a.id === b.id
}

export function findPlayer<TPlayer extends Player>(
  haystack: TPlayer[],
  player: Player,
): TPlayer | undefined {
  return haystack.find((v) => isPlayerSame(v, player))
}

export function toSortedBySurname<TParticipant extends Player>(
  subParticipants: TParticipant[],
): TParticipant[] {
  function getPartsInOrder(participant: TParticipant): string {
    return participant.short_name.split(' ').reverse().join(' ')
  }

  return [...subParticipants].sort((a, b) => {
    const sortStringA = getPartsInOrder(a)
    const sortStringB = getPartsInOrder(b)

    return sortStringA.localeCompare(sortStringB)
  })
}

export function toSortedByPosition(subParticipants: Player[]): Player[] {
  const positionOrder = {
    [SoccerLineupPositionId.Goalkeeper]: 0,
    [SoccerLineupPositionId.Defender]: 1,
    [SoccerLineupPositionId.Midfielder]: 2,
    [SoccerLineupPositionId.Attacker]: 3,
    [SoccerLineupPositionId.Unknown]: 4,
  }

  function getOrder(participant: Player): number {
    const positionId = participant.details?.position_id
    if (positionId) return positionOrder[positionId]
    else return 5
  }

  return [...subParticipants].sort((a, b) => getOrder(a) - getOrder(b))
}

export async function updateLineups(
  eventId: Event['id'],
  participantId: Participant['id'],
  subParticipants: Player[],
): Promise<void> {
  const squad: Events.SubParticipantsSave.SubParticipantsSavePostSquad[] =
    subParticipants.map((subParticipant) => {
      let playerPosition: number | undefined = undefined

      if (subParticipant.position === SubParticipantPosition.Goalkeeper) {
        playerPosition = 1
        // force goalkeeper to be at position 1
        // scenario:
        //
        // has_formations=soon or has_formations=yes
        //
        // 1. players in formation are set to positions, except goalkeeper (not in lineup yet)
        // 2. user adds goalkeeper to lineup and saves squad
        // 3. opening formation modal must show goalkeeper at position 1
      } else {
        const playerPositionStat = getStat(
          subParticipant,
          SoccerPersonStatId.PPosition,
        )

        if (playerPositionStat?.value) {
          playerPosition = Number(playerPositionStat.value)
        }
      }

      return {
        id: subParticipant.id || undefined,
        short_name: subParticipant.id ? undefined : subParticipant.short_name,
        shirt_nr:
          subParticipant.shirt_nr === null
            ? undefined
            : subParticipant.shirt_nr,
        position: subParticipant.position,
        player_position: playerPosition,
        // TODO: remove this line when Team API will allow to update reason using id instead of code
        position_reason_code: subParticipant.position_reason_code,
        position_reason_id: subParticipant.position_reason_id,
        captain: isCaptain(subParticipant) ? true : false,
      }
    })

  await sportsApiClient.Events.SubParticipantsSave.post(
    eventId,
    participantId,
    squad,
  )
}
